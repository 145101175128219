import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import { useTheme } from '@mui/material/styles';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import GradientBox from '../customs/GradientBox';
import GradientText from '../customs/GradientText';

import DigitalInventoryMainView from '../../assets/images/digital-inventory-main-view.png';

export default function Inventories() {

  const theme = useTheme();

  return (
    <Box id="catalogue">
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <GradientText variant="h3" gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(2), pb: theme.spacing(4), mx: 'auto', textAlign: 'center' }}>
            Inventarios Digitales
          </GradientText>
          <Typography variant="body1" gutterBottom sx={{ mt: theme.spacing(2), mb: theme.spacing(6), textAlign: 'center', fontSize: '20px' }}>
            Utilizando tecnología basada en Computer Vision y Machine Learning, recopilamos datos tanto de los objetos como de su entorno para crear una representación precisa y geolocalizada de la realidad física. Esta información se refleja en los Inventarios Digitales de Gemminis, proporcionando una visión completa y detallada de los activos urbanos.
          </Typography>
          <Card sx={{ my: '30px', borderRadius: '10px' }}>
            <CardMedia
              component='img'
              src={DigitalInventoryMainView}
              alt="accessibility digital inventory"
            />
          </Card>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Typography variant="body1" gutterBottom sx={{ mt: theme.spacing(2), mb: theme.spacing(6), textAlign: 'center', fontSize: '20px' }}>
            Editar objetos, crear elementos nuevos, reposicionarlos en el mapa y visualizarlos en imágenes reales que le permiten tomar decisiones informadas y precisas. Desde inversiones estratégicas hasta proyectos urbanos, nuestra plataforma potencia sus decisiones con información tangible, optimizando el futuro de tu ciudad.
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: theme.spacing(2), mb: theme.spacing(6), textAlign: 'center', fontSize: '20px' }}>
            Con el módulo de accesibilidad puede visualizar y analizar al detalle la accesibilidad de las aceras. Con información detallada que abarca desde el tipo de pavimento y la presencia de señalización podotáctil hasta la amplitud, pendiente, y rebajes en pasos de peatones. Le proporcionamos todos los datos necesarios para para diseñar una ciudad más inclusiva, donde la accesibilidad no es solo un concepto, sino una realidad tangible para enriquecer la vida de todos sus habitantes.
          </Typography>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6), pt: theme.spacing(10) }}>
          <GradientText variant="h3" gutterBottom fontWeight='medium' sx={{ pb: '20px' }}>
            ¿Listo para mejorar sus decisiones? Comience a crear su Inventario Digital personalizado ahora mismo.
          </GradientText>
          <Button
            color="primary"
            href='/empecemos/'
            variant="contained"
            size="large"
            aria-label="Start now"
            sx={{ fontSize: 'large', my: theme.spacing(4) }}
            endIcon={<ArrowForwardRoundedIcon />}
          >
            Empecemos
          </Button>
          <Typography variant="h5" color='grey.800' gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(10) }}>
            ¿Desea saber más? Puede consultar todos los activos detectables de cada módulo en nuestro catálogo.
          </Typography>
          <Button
            color="primary"
            href='/catalogo/'
            variant="contained"
            size="large"
            aria-label="Catalogue"
            sx={{ fontSize: 'large', my: theme.spacing(4) }}
            endIcon={<ArrowForwardRoundedIcon />}
          >
            Catálogo
          </Button>
        </Box>
      </GradientBox>
    </Box>
  );
}