import React, { useState, useEffect, useRef } from 'react';

import { useMap } from 'react-leaflet/hooks'
import { useMapEvents } from 'react-leaflet/hooks'

import L from "leaflet";

import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

export default function MyZoomControls() {

  const [zoomLevel, setZoomLevel] = useState(12);
  
  const map = useMap();

  const zoomInDisabled = zoomLevel === 21;
  const zoomOutDisabled = zoomLevel === 3;

  // do this because of bug on leaflet clicks
  const ref = useRef(undefined);
  useEffect(() => {
    if(ref.current) L.DomEvent.disableClickPropagation(ref.current);
  });

  useMapEvents({
    zoomend() {
      setZoomLevel(map.getZoom());
    },
  })

  return (
    <div ref={ref} className={'leaflet-bottom leaflet-left'}>
      <div className="leaflet-control">
        <ButtonGroup
          variant="contained"
          orientation="vertical"
          size="small"
          color="neutral"
          sx={{ '.MuiButtonGroup-grouped': { minWidth: '34px', maxWidth: '34px' }}}
        >
          <Tooltip
            title={zoomInDisabled ? "No es posible ampliar más" : "Ampliar"}
            placement='right'
            arrow
            enterDelay={500}
          >
            <Button
              size="small"
              color="neutral"
              onClick={() => {
                map.zoomIn();
                if (zoomLevel !== 21) setZoomLevel(zoomLevel + 1);
              }}
              sx={{
                minWidth: '34px',
                maxWidth: '34px',
                height: '34px',
                opacity: zoomInDisabled ? 0.4 : 'auto',
                '&:hover ': {
                  backgroundColor: zoomInDisabled ? 'neutral.main' : 'auto',
                },
              }}
            >
              <AddRoundedIcon sx={{fontSize: 22 }} />
            </Button>
          </Tooltip>
          <Tooltip
            title={zoomOutDisabled ? "No es posible alejar más" : "Alejar"}
            placement='right'
            arrow
            enterDelay={500}
          >
            <Button
              size="small"
              color="neutral"
              onClick={() => {
                map.zoomOut();
                if (zoomLevel !== 3) setZoomLevel(zoomLevel - 1);
              }}
              sx={{
                minWidth: '34px',
                maxWidth: '34px',
                height: '34px',
                opacity: zoomOutDisabled ? 0.4 : 'auto',
                '&:hover ': {
                  backgroundColor: zoomOutDisabled ? 'neutral.main' : 'auto',
                },
              }}
            >
              <RemoveRoundedIcon sx={{ fontSize: 22 }} />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </div>
    </div>
  )
}