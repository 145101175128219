import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper'
import ToggleButton from '@mui/material/ToggleButton'
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import GradientBox from '../customs/GradientBox';
import GradientText from '../customs/GradientText';
import NoBordersToggle from '../customs/NoBordersToggle';

import { inventoryCatalogue, stakeoutsCatalogue, portsCatalogue, accessibilityCatalogue } from '../../constants/catalogues';

export default function Catalogue() {

  const theme = useTheme();

  const [alignment, setAlignment] = useState('inventory');

  const inventoryRef = useRef(null);
  const stakeoutsRef = useRef(null);
  const portsRef = useRef(null);
  const accessibilityRef = useRef(null);

  const handleAlignment = useCallback((event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
    else newAlignment = alignment;

    switch (newAlignment) {
      case 'inventory':
        inventoryRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'stakeouts':
        stakeoutsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'ports':
        portsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'accessibility':
        accessibilityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      default:
        inventoryRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
    }
  }, [alignment]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sectionParam = searchParams.get('section');

  useEffect(() => {
    if (sectionParam) handleAlignment(null, sectionParam);
  }, [sectionParam, handleAlignment]);

  return (
    <Box id="catalogue">
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Typography variant="h4" color="grey.800" gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(2), mb: theme.spacing(4), textAlign: 'center' }}>
            Cada módulo de Gemminis ofrece todo un catálogo de elementos detectables
          </Typography>
          <Paper sx={{ px: { xs: theme.spacing(1), md: 0 } }}>
            <NoBordersToggle
              size="large"
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
              fullWidth
              color="primary"
              orientation={useMediaQuery(theme.breakpoints.up('md')) ? 'horizontal' : 'vertical'}
            >
              <ToggleButton value="inventory" aria-label="inventory" sx={{ fontSize: '16px', fontWeight: 'bold', px: theme.spacing(0.5), m: { md: theme.spacing(1) }, my: theme.spacing(1) }}>
                Inventariado
              </ToggleButton>
              <ToggleButton value="accessibility" aria-label="accessibility" sx={{ fontSize: '16px', fontWeight: 'bold', px: theme.spacing(0.5), m: { md: theme.spacing(1) }, my: theme.spacing(1) }}>
                Accesibilidad
              </ToggleButton>
              <ToggleButton value="stakeouts" aria-label="stakeouts" sx={{ fontSize: '16px', fontWeight: 'bold', px: theme.spacing(0.5), m: { md: theme.spacing(1) }, my: theme.spacing(1) }}>
                Replanteo de carreteras
              </ToggleButton>
              <ToggleButton value="ports" aria-label="ports" sx={{ fontSize: '16px', fontWeight: 'bold', px: theme.spacing(0.5), m: { md: theme.spacing(1) }, my: theme.spacing(1) }}>
                Puertos
              </ToggleButton>
            </NoBordersToggle>
          </Paper>
        </Box>
      </GradientBox>
      <GradientBox ref={inventoryRef} sx={{ scrollMarginTop: theme.spacing(6) }}>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <GradientText variant="h4" gutterBottom fontWeight='medium'>
            Inventariado
          </GradientText>
          <Typography variant="h5" color='grey.800' gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(3), mb: theme.spacing(4) }}>
            Obtenga información de todo tipo de objetos urbanos, dentro y fuera de las ciudades.
          </Typography>
          <Grid container spacing={4} sx={{ mb: theme.spacing(3) }}>
            {inventoryCatalogue.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <CardMedia
                    sx={{ height: '253px' }}
                    image={card.image}
                  />
                  <Typography gutterBottom variant="body1" component="div" sx={{ m: theme.spacing(2), fontSize: '18px' }}>
                    {card.name}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </GradientBox>
      <GradientBox ref={accessibilityRef} sx={{ scrollMarginTop: theme.spacing(6) }}>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <GradientText variant="h4" gutterBottom fontWeight='medium'>
            Accesibilidad
          </GradientText>
          <Typography variant="h5" color='grey.800' gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(3), mb: theme.spacing(4) }}>
            Todas las características de accesibilidad sobre las aceras.
          </Typography>
          <Grid container spacing={4} sx={{ mb: theme.spacing(3) }}>
            {accessibilityCatalogue.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <CardMedia
                    sx={{ height: '253px' }}
                    image={card.image}
                  />
                  <Typography gutterBottom variant="body1" component="div" sx={{ m: theme.spacing(2), fontSize: '18px' }}>
                    {card.name}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </GradientBox>
      <GradientBox ref={stakeoutsRef} sx={{ scrollMarginTop: theme.spacing(6) }}>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <GradientText variant="h4" gutterBottom fontWeight='medium'>
            Replanteo de carreteras
          </GradientText>
          <Typography variant="h5" color='grey.800' gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(3), mb: theme.spacing(4) }}>
            Las carreteras al detalle y con geolcalización precisa.
          </Typography>
          <Grid container spacing={4} sx={{ mb: theme.spacing(3) }}>
            {stakeoutsCatalogue.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <CardMedia
                    sx={{ height: '253px' }}
                    image={card.image}
                  />
                  <Typography gutterBottom variant="body1" component="div" sx={{ m: theme.spacing(2), fontSize: '18px' }}>
                    {card.name}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </GradientBox>
      <GradientBox ref={portsRef} sx={{ scrollMarginTop: theme.spacing(6) }}>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <GradientText variant="h4" gutterBottom fontWeight='medium' >
            Puertos
          </GradientText>
          <Typography variant="h5" color='grey.800' gutterBottom fontWeight='medium' sx={{ my: theme.spacing(3) }}>
            Diseñado a medida para para detectar elementos específicos en puertos.
          </Typography>
          <Typography variant="h6" color='grey.800' gutterBottom fontWeight='regular' sx={{ mb: theme.spacing(2) }}>
            También incluye todos los activos de inventariado.
          </Typography>
          <Grid container spacing={4} sx={{ mb: theme.spacing(3) }}>
            {portsCatalogue.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <CardMedia
                    sx={{ height: '253px' }}
                    image={card.image}
                  />
                  <Typography gutterBottom variant="body1" component="div" sx={{ m: theme.spacing(2), fontSize: '18px' }}>
                    {card.name}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6), pt: theme.spacing(14) }}>
          <GradientText variant="h3" gutterBottom fontWeight='medium' sx={{ pb: '20px' }}>
            ¿Listo para acceder a toda esta información? Comience a crear su Inventario Digital personalizado ahora mismo.
          </GradientText>
          <Button
            color="primary"
            href='/empecemos/'
            variant="contained"
            size="large"
            aria-label="Start now"
            sx={{ fontSize: 'large', my: theme.spacing(4) }}
            endIcon={<ArrowForwardRoundedIcon />}
          >
            Empecemos
          </Button>
        </Box>
      </GradientBox>
    </Box>
  );
}