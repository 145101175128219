import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import SignpostRoundedIcon from '@mui/icons-material/SignpostSharp';
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded';

export default function ToggleObjectsAccessibility(props) {

  const { selectedMode, onSelectedModeChange } = props;

  return (
    <ToggleButtonGroup
      value={selectedMode}
      size="medium"
      exclusive
      aria-label="Seleccionar datos"
      sx={{ mt: '10px', mr: '10px' }}
    >
      <ToggleButton value="objects" aria-label="Objetos"
        onClick={() => onSelectedModeChange("objects")}
        sx={{
          width: '54px',
          backgroundColor: "neutral.main",
          ":hover": {
            color: "neutral.contrastText",
            backgroundColor: "primary.main"
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            color: "neutral.contrastText",
            backgroundColor: "primary.main"
          }
        }}
      >
        <Tooltip
          title="Mostrar objetos"
          arrow
          enterDelay={500}
          componentsProps={{ tooltip: { sx: { top: '10px', }, } }}
        >
          <SignpostRoundedIcon sx={{ fontSize: 28 }} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="accessibility" aria-label="Accessibilidad"
        onClick={() => onSelectedModeChange("accessibility")}
        sx={{
          width: '54px',
          backgroundColor: "neutral.main",
          ":hover": {
            color: "neutral.contrastText",
            backgroundColor: "primary.main"
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            color: "neutral.contrastText",
            backgroundColor: "primary.main"
          }
        }}
      >
        <Tooltip
          title="Mostrar accesibilidad"
          arrow
          enterDelay={500}
          componentsProps={{ tooltip: { sx: { top: '10px', }, } }}
        >
          <AccessibleRoundedIcon sx={{ fontSize: 28 }} />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}