import { useEffect, useCallback } from 'react';

import { useMap } from 'react-leaflet/hooks';

export default function MapMover(props) {
  
  const { mapPosition, onMapPositionChange, data } = props;

  const map = useMap();

  const updateMapView = useCallback((latlng) => {
    let distance = map.getCenter().distanceTo(latlng);
    if (distance > 10 && distance < 200000) {
      map.flyTo(latlng);
    } else {
      map.setView(latlng);
    }
  }, [map]);

  useEffect(() => {
    updateMapView(mapPosition);
    onMapPositionChange(mapPosition);
  }, [mapPosition, updateMapView, onMapPositionChange]);

  useEffect(() => {
    if (data.length > 0) {
      let mapBounds = map.getBounds();
      let noMarkersInBounds = true;

      for (const object of data) {
        if (mapBounds.contains([object.latitude, object.longitude])) {
          noMarkersInBounds = false;
          break;
        }
      }

      if (noMarkersInBounds) {
        updateMapView([data[0].latitude, data[0].longitude]);
        onMapPositionChange([data[0].latitude, data[0].longitude]);
      }
    }
  }, [data, map, updateMapView, onMapPositionChange]);

  return null;
}