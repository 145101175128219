import React, { forwardRef } from 'react';

import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

const StyledGradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to bottom, ${theme.palette.neutral.light}, #ffffff)`,
}));

const GradientBox = forwardRef(({ children, ...props }, ref) => {
  return <StyledGradientBox ref={ref} {...props}>{children}</StyledGradientBox>;
});

export default GradientBox;