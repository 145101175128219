import React, { useState, useEffect } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

class FiltersValues {
  constructor() {
    this.cities = [];
    this.roads = [];
    this.types = [];
    this.subTypes = [];
    this.reviewed = [];
    this.dates = [];
  }
}

export default function ObjectsFilters(props) {

  const { data, onFilteredDataChange, updatedObject, deletedObject } = props;

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const [uniqueValues, setUniqueValues] = useState(new FiltersValues());
  const [displayValues, setDisplayValues] = useState(new FiltersValues());
  const [selectedValues, setSelectedValues] = useState(new FiltersValues());

  const [anyValueSelected, setAnyValueSelected] = useState({
    cities: false,
    roads: false,
    types: false,
    subTypes: false,
    reviewed: false,
    dates: false,
  });

  const [firstSelectedFilter, setFirstSelectedFilter] = useState(undefined);
  const [checkboxState, setCheckboxState] = useState([]);

  const [anchorElements, setAnchorElemenets] = useState({
    cities: null,
    roads: null,
    types: null,
    subTypes: null,
    reviewed: null,
    dates: null,
  });

  const [prevUpdatedObject, setPrevUpdatedObject] = useState({});
  const [prevDeletedObject, setPrevDeletedObject] = useState({});

  const isWide = useMediaQuery('(min-width:1200px)');

  useEffect(() => {
    if (prevUpdatedObject !== updatedObject) {
      updateObjectFromFilters();
      setPrevUpdatedObject(updatedObject);
    } else if (prevDeletedObject !== deletedObject) {
      deleteObjectFromFilters();
      setPrevDeletedObject(deletedObject);
    } else {
      calculateUniqueValues(checkboxState);
      calculateDisplayValues(selectedValues, false);
      updateFilteredData(selectedValues);
    }
  }, [data, updatedObject, deletedObject]);

  const handleOpenFilterMenu = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };

  const filters = ["cities", "roads", "types", "subTypes", "reviewed", "dates"];

  const handleFilterBtnClick = (event) => {
    for (const filter of filters) {
      if (event.currentTarget.id.includes(filter)) {
        const newAnchorElements = { ...anchorElements, [filter]: event.currentTarget };
        setAnchorElemenets(newAnchorElements);
        break;
      }
    }
  };

  const handleFilterBtnClose = (event) => {
    for (const filter of filters) {
      if (event.target.parentElement.id.includes(filter)) {
        const newAnchorElements = { ...anchorElements, [filter]: null };
        setAnchorElemenets(newAnchorElements);
        break;
      }
    }
  };

  const handleCheckboxChange = (event) => {
    let id = event.target.id;
    let index = checkboxState.findIndex((e) => e.id === id);
    const newCheckboxState = [...checkboxState];
    if (index === -1) {
      newCheckboxState.push({ id, checked: true });
    } else {
      let checked = newCheckboxState[index].checked;
      newCheckboxState[index] = { id, checked: !checked };
    }
    setCheckboxState(newCheckboxState);
  }

  function handleResetClicked() {

    const newCheckboxState = checkboxState.map((e) => ({ ...e, checked: false }));
    setCheckboxState(newCheckboxState);

    const newAnyValueSelected = anyValueSelected;
    filters.forEach(filter => newAnyValueSelected[filter] = false);
    setAnyValueSelected(newAnyValueSelected);

    setDisplayValues(uniqueValues);

    const newSelectedValues = new FiltersValues();
    setSelectedValues(newSelectedValues);
    updateFilteredData(newSelectedValues);
  }

  const handleCheckAllClicked = (event) => {

    for (const filter of filters) {
      if (event.target.id.includes(filter)) {

        const newSelectedValues = selectedValues;
        const indexToUpdate = [];

        displayValues[filter].forEach(value => {
          indexToUpdate.push(uniqueValues[filter].indexOf(value));
          let index = newSelectedValues[filter].indexOf(value);
          if (index === -1) {
            newSelectedValues[filter].push(value);
            if (firstSelectedFilter === undefined) setFirstSelectedFilter(filter);
          }
        });

        const newCheckboxState = checkboxState.map(item => {
          if (item.id.includes(filter) && indexToUpdate.some(index => item.id.includes(index))) {
            return { ...item, checked: true };
          }
          return item;
        });
        setCheckboxState(newCheckboxState);

        const newAnyValueSelected = { ...anyValueSelected, [filter]: true };
        setAnyValueSelected(newAnyValueSelected);

        calculateDisplayValues(newSelectedValues, false);
        setSelectedValues(newSelectedValues);
        updateFilteredData(newSelectedValues);

        break;
      }
    }
  }

  const handleUncheckAllClicked = (event) => {

    for (const filter of filters) {
      if (event.target.id.includes(filter)) {

        const newCheckboxState = checkboxState.map(item => {
          if (item.id.includes(filter)) {
            return { ...item, checked: false };
          }
          return item;
        });
        setCheckboxState(newCheckboxState);

        const newAnyValueSelected = { ...anyValueSelected, [filter]: false };
        setAnyValueSelected(newAnyValueSelected);

        const newSelectedValues = selectedValues;
        newSelectedValues[filter] = []
        if (firstSelectedFilter === filter) setFirstSelectedFilter(undefined);

        calculateDisplayValues(newSelectedValues, false);
        setSelectedValues(newSelectedValues);
        updateFilteredData(newSelectedValues);

        break;
      }
    }
  }

  return (
    isWide ?
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ml: '2px',
          mt: '2px',
        }}
      >
        {filters.map((filter) => {
          const open = Boolean(anchorElements[filter]);

          let title;
          switch (filter) {
            case "cities":
              title = "Ciudad";
              break;
            case "roads":
              title = "Calle";
              break;
            case "types":
              title = "Categoria";
              break;
            case "subTypes":
              title = "Subcategoria";
              break;
            case "reviewed":
              title = "Revisado";
              break;
            case "dates":
              title = "Fecha";
              break;
            default:
              title = "Fecha";
              break;
          }

          return (
            <Box key={filter}>
              <Button
                id={"btn-dropdown-filter-" + filter}
                aria-controls={open ? 'menu-filter-' + filter : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                color="neutral"
                sx={{ margin: '8px', fontWeight: 'regular' }}
                onClick={handleFilterBtnClick}
                endIcon={open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
              >
                {title}
              </Button>
              <Menu
                id={"menu-filter-" + filter}
                MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
                anchorEl={anchorElements[filter]}
                open={open}
                onClose={handleFilterBtnClose}
              >
                <Button
                  id={"btn-check-all-" + filter}
                  size="small"
                  sx={{ marginLeft: '6px', fontSize: '12px', fontWeight: 'regular' }}
                  onClick={handleCheckAllClicked}
                >
                  Marcar todos
                </Button>
                <Button
                  id={"btn-uncheck-all-" + filter}
                  size="small"
                  sx={{ marginRight: '6px', fontSize: '12px', fontWeight: 'regular' }}
                  onClick={handleUncheckAllClicked}
                >
                  Desmarcar todos
                </Button>
                {displayValues[filter].map((value) => {
                  if (value === undefined || value === null || value === '' ) return null;

                  let index = uniqueValues[filter].indexOf(value);
                  let id = "checkbox-" + filter + "-" + index;
                  let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                  let text = value;
                  if (filter === "reviewed") text = value ? "Sí" : "No";
                  if (filter === "dates") text = new Date(value).toLocaleDateString('es-ES');
                  return (
                    <Box key={index}>
                      <FormGroup sx={{ marginLeft: '10px' }}>
                        <FormControlLabel
                          control={<Checkbox
                            id={id}
                            checked={checked}
                            onChange={handleCheckboxChange}
                            onClick={() => updateSelectedValues(filter, value)}
                          />}
                          label={text}
                        />
                      </FormGroup>
                    </Box>
                  );
                })}
              </Menu>
            </Box>
          );
        })}
        <Fab
          color="tertiary"
          variant="extended"
          sx={{ height: '36px', margin: '8px' }}
          onClick={handleResetClicked}
        >
          Restablecer filtros
        </Fab>
      </Box>
      :
      <Box>
        <Tooltip title="Filtros" arrow enterDelay={500}>
          <Fab
            color="tertiary"
            size="medium"
            aria-controls="menu-filter"
            aria-haspopup="true"
            onClick={handleOpenFilterMenu}
            sx={{ ml: '18px', mt: '12px' }}
          >
            <FilterAltRoundedIcon sx={{ fontSize: 28 }} />
          </Fab>
        </Tooltip>
        <Drawer
          variant="temporary"
          anchor="left"
          open={Boolean(anchorElFilter)}
          onClose={handleCloseFilterMenu}
          PaperProps={{
            sx: {
              width: 1,
            }
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '12px' }}>
            <Tooltip title="Cerrar" arrow enterDelay={500}>
              <IconButton
                size="medium"
                aria-controls="filter-appbar"
                aria-haspopup="true"
                onClick={handleCloseFilterMenu}
                color="inherit"
                sx={{
                  justifyContent: 'left',
                  width: '46px',
                  ml: '14px',
                  mb: '2px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <CloseRoundedIcon sx={{ fontSize: 28 }} />
              </IconButton>
            </Tooltip>
            <Fab
              color="primary"
              variant="extended"
              sx={{ height: '36px', position: 'absolute', right: '14px' }}
              onClick={handleResetClicked}
            >
              Restablecer filtros
            </Fab>
          </Box>
          <Box>
            {filters.map((filter) => {

              let title;
              switch (filter) {
                case "cities":
                  title = "Ciudad";
                  break;
                case "roads":
                  title = "Calle";
                  break;
                case "types":
                  title = "Categoria";
                  break;
                case "subTypes":
                  title = "Subcategoria";
                  break;
                case "reviewed":
                  title = "Revisado";
                  break;
                case "dates":
                  title = "Fecha";
                  break;
                default:
                  title = "Fecha";
                  break;
              }


              return (
                <Accordion
                  key={filter}
                  disableGutters
                  sx={{
                    pl: '12px',
                    boxShadow: 'none',
                    '&.MuiPaper-root:before': {
                      opacity: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRoundedIcon sx={{ fontSize: 36, color: 'secondary.contrastText' }} />}
                    aria-controls={filter + "-content"}
                    id={filter + "-header"}
                  >
                    <Typography variant='h5'>{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Button
                      id={"btn-check-all-" + filter}
                      variant='outlined'
                      sx={{ fontWeight: 'regular', mr: '8px', mb: '6px' }}
                      onClick={handleCheckAllClicked}
                    >
                      Marcar todos
                    </Button>
                    <Button
                      id={"btn-uncheck-all-" + filter}
                      variant='outlined'
                      sx={{ fontWeight: 'regular', mb: '6px' }}
                      onClick={handleUncheckAllClicked}
                    >
                      Desmarcar todos
                    </Button>
                    <Box sx={{ ml: '-10px' }}>
                      {displayValues[filter].map((value) => {
                        if (value === undefined || value === null || value === '' ) return null;

                        let index = uniqueValues[filter].indexOf(value);
                        let id = "checkbox-" + filter + "-" + index;
                        let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                        let text = value;
                        if (filter === "reviewed") text = value ? "Sí" : "No";
                        if (filter === "dates") text = new Date(value).toLocaleDateString('es-ES');
                        return (
                          <Box key={index}>
                            <FormGroup sx={{ marginLeft: '10px' }}>
                              <FormControlLabel
                                control={<Checkbox
                                  id={id}
                                  checked={checked}
                                  onChange={handleCheckboxChange}
                                  onClick={() => updateSelectedValues(filter, value)}
                                />}
                                label={text}
                              />
                            </FormGroup>
                          </Box>
                        );
                      })}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Drawer>
      </Box>
  );

  function calculateUniqueValues(updatedCheckboxState) {
    const newUniqueValues = new FiltersValues();
    const newCheckboxState = [];

    data.forEach(value => {
      if (!newUniqueValues.cities.includes(value.city)) newUniqueValues.cities.push(value.city);
      if (!newUniqueValues.roads.includes(value.road)) newUniqueValues.roads.push(value.road);
      if (!newUniqueValues.types.includes(value.type)) newUniqueValues.types.push(value.type);
      if (!newUniqueValues.subTypes.includes(value.subtype)) newUniqueValues.subTypes.push(value.subtype);
      if (!newUniqueValues.reviewed.includes(value.reviewed)) newUniqueValues.reviewed.push(value.reviewed);
      if (!newUniqueValues.dates.includes(value.date)) newUniqueValues.dates.push(value.date);
    });

    setUniqueValues(newUniqueValues);

    for (let filter in newUniqueValues) {
      newUniqueValues[filter].forEach(function (value) {
        let index = newUniqueValues[filter].indexOf(value);
        let id = "checkbox-" + filter + "-" + index;
        let checked = updatedCheckboxState.find((e) => e.id === id)?.checked || false;
        newCheckboxState.push({ id, checked });
      });
    }

    setCheckboxState(newCheckboxState);
  }

  function updateSelectedValues(updatedFilter, updatedValue) {

    const newSelectedValues = selectedValues;
    let index;

    if (anyValueSelected[updatedFilter]) {
      index = newSelectedValues[updatedFilter].indexOf(updatedValue);
      if (index === -1) {
        newSelectedValues[updatedFilter].push(updatedValue);
      } else {
        newSelectedValues[updatedFilter].splice(index, 1);
        if (newSelectedValues[updatedFilter].length === 0) {
          const newAnyValueSelected = { ...anyValueSelected, [updatedFilter]: false };
          setAnyValueSelected(newAnyValueSelected);
          if (firstSelectedFilter === updatedFilter) setFirstSelectedFilter(undefined);
        }
      }
    } else {
      const newAnyValueSelected = { ...anyValueSelected, [updatedFilter]: true };
      setAnyValueSelected(newAnyValueSelected);
      if (firstSelectedFilter === undefined) setFirstSelectedFilter(updatedFilter);
      newSelectedValues[updatedFilter].push(updatedValue);
    }

    calculateDisplayValues(newSelectedValues, false);
    updateFilteredData(newSelectedValues);
    setSelectedValues(newSelectedValues);
  }

  function updateFilteredData(newSelectedValues) {

    let filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    let filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    let filteredDataTypes = filterData("types", "type", filteredDataRoads, newSelectedValues);
    let filteredDataSubtypes = filterData("subTypes", "subtype", filteredDataTypes, newSelectedValues);
    let filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataSubtypes, newSelectedValues);
    let filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    const sortedData = filteredDataDates.sort((a, b) => a.city.localeCompare(b.city));
    onFilteredDataChange(sortedData);
  }

  function filterData(filter, objectType, dataToFilter, newSelectedValues) {
    let filteredData = [];
    if (newSelectedValues[filter].length === 0) {
      filteredData = dataToFilter;
    } else {
      newSelectedValues[filter].forEach(value =>
        filteredData = filteredData.concat(dataToFilter.filter(obj => obj[objectType] === value))
      );
    }

    return filteredData;
  }

  function calculateDisplayValues(newSelectedValues, updatingOrDeletingObject) {

    let newDisplayValues = new FiltersValues();

    let filteredDataCities;
    let filteredDataRoads;
    let filteredDataTypes;
    let filteredDataSubtypes;
    let filteredDataReviewed;
    let filteredDataDates;

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataTypes = filterData("types", "type", filteredDataRoads, newSelectedValues);
    filteredDataSubtypes = filterData("subTypes", "subtype", filteredDataTypes, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataSubtypes, newSelectedValues);

    filteredDataReviewed.forEach(value => {
      if (!newDisplayValues.dates.includes(value.date)) newDisplayValues.dates.push(value.date);
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataTypes = filterData("types", "type", filteredDataRoads, newSelectedValues);
    filteredDataSubtypes = filterData("subTypes", "subtype", filteredDataTypes, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataSubtypes, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.reviewed.includes(value.reviewed)) newDisplayValues.reviewed.push(value.reviewed);
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataTypes = filterData("types", "type", filteredDataRoads, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataTypes, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.subTypes.includes(value.subtype)) newDisplayValues.subTypes.push(value.subtype);
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataSubtypes = filterData("subTypes", "subtype", filteredDataRoads, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataSubtypes, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.types.includes(value.type)) newDisplayValues.types.push(value.type);
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataTypes = filterData("types", "type", filteredDataCities, newSelectedValues);
    filteredDataSubtypes = filterData("subTypes", "subtype", filteredDataTypes, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataSubtypes, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.roads.includes(value.road)) newDisplayValues.roads.push(value.road);
    });

    filteredDataRoads = filterData("roads", "road", data, newSelectedValues);
    filteredDataTypes = filterData("types", "type", filteredDataRoads, newSelectedValues);
    filteredDataSubtypes = filterData("subTypes", "subtype", filteredDataTypes, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataSubtypes, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.cities.includes(value.city)) newDisplayValues.cities.push(value.city);
    });


    if (!updatingOrDeletingObject) {
      switch (firstSelectedFilter) {
        case "cities":
          newDisplayValues.cities = displayValues.cities;
          break;
        case "roads":
          newDisplayValues.roads = displayValues.roads;
          break;
        case "types":
          newDisplayValues.types = displayValues.types;
          break;
        case "subTypes":
          newDisplayValues.subTypes = displayValues.subTypes;
          break;
        case "reviewed":
          newDisplayValues.reviewed = displayValues.reviewed;
          break;
        case "dates":
          newDisplayValues.dates = displayValues.dates;
          break;
        /*case "undefined":
          console.log("undefined: " + firstSelectedFilter);
          newDisplayValues = uniqueValues;
          break;
        default:
          console.log(uniqueValues) // because its the first iteration and its null
          //newDisplayValues = uniqueValues; 
          console.log(firstSelectedFilter);
          break;*/
      }
    }

    newDisplayValues.cities.sort();
    newDisplayValues.roads.sort();
    newDisplayValues.types.sort();
    newDisplayValues.subTypes.sort();
    newDisplayValues.reviewed.sort();
    newDisplayValues.dates.sort();

    setDisplayValues(newDisplayValues);
  }

  function updateObjectFromFilters() {
    const newSelectedValues = selectedValues;
    const newCheckboxState = [...checkboxState];

    let indexOfValue;
    let index;
    let id;

    filters.forEach(filter => {
      let objectType;
      switch (filter) {
        case "cities":
          objectType = "city";
          break;
        case "roads":
          objectType = "road";
          break;
        case "types":
          objectType = "type";
          break;
        case "subTypes":
          objectType = "subtype";
          break;
        case "reviewed":
          objectType = "reviewed";
          break;
        case "dates":
          objectType = "date";
          break;
        default:
          objectType = "date";
          break;
      }

      if (anyValueSelected[filter] && updatedObject.object[objectType] !== updatedObject.newValues[objectType]) {
        indexOfValue = selectedValues[filter].indexOf(updatedObject.object[objectType]);
        if (indexOfValue !== -1 && data.filter(obj => obj[objectType] === updatedObject.object[objectType]).length === 0) {
          uniqueValues[filter].forEach((value, index) => {
            if (value === updatedObject.object[objectType]) id = "checkbox-" + filter + "-" + index;
          })

          index = checkboxState.findIndex((e) => e.id === id);
          newCheckboxState[index] = { id, checked: false };
          setCheckboxState(newCheckboxState);

          newSelectedValues[filter].splice(indexOfValue, 1);
          if (newSelectedValues[filter].length === 0) {
            const newAnyValueSelected = { ...anyValueSelected, [filter]: false };
            setAnyValueSelected(newAnyValueSelected);
            if (firstSelectedFilter === filter) setFirstSelectedFilter(undefined);
          }
        }
      }
    });

    calculateUniqueValues(newCheckboxState);
    updateFilteredData(newSelectedValues);
    setSelectedValues(newSelectedValues);
    calculateDisplayValues(newSelectedValues, true);
  }

  function deleteObjectFromFilters() {
    const newSelectedValues = selectedValues;
    const newCheckboxState = [...checkboxState];

    let indexOfValue;
    let index;
    let id;

    filters.forEach(filter => {
      let objectType;
      switch (filter) {
        case "cities":
          objectType = "city";
          break;
        case "roads":
          objectType = "road";
          break;
        case "types":
          objectType = "type";
          break;
        case "subTypes":
          objectType = "subtype";
          break;
        case "reviewed":
          objectType = "reviewed";
          break;
        case "dates":
          objectType = "date";
          break;
        default:
          objectType = "date";
          break;
      }

      if (anyValueSelected[filter]) {
        indexOfValue = selectedValues[filter].indexOf(deletedObject[objectType]);
        if (indexOfValue !== -1 && data.filter(obj => obj[objectType] === deletedObject[objectType]).length === 0) {
          uniqueValues[filter].forEach((value, index) => {
            if (value === deletedObject[objectType]) id = "checkbox-" + filter + "-" + index;
          })

          index = checkboxState.findIndex((e) => e.id === id);
          newCheckboxState[index] = { id, checked: false };
          setCheckboxState(newCheckboxState);

          newSelectedValues[filter].splice(indexOfValue, 1);
          if (newSelectedValues[filter].length === 0) {
            const newAnyValueSelected = { ...anyValueSelected, [filter]: false };
            setAnyValueSelected(newAnyValueSelected);
            if (firstSelectedFilter === filter) setFirstSelectedFilter(undefined);
          }
        }
      }
    });

    calculateUniqueValues(newCheckboxState);
    updateFilteredData(newSelectedValues);
    setSelectedValues(newSelectedValues);
    calculateDisplayValues(newSelectedValues, true);
  }
}