import React, { useEffect, useState, useRef, Fragment } from 'react';

import { useMap } from 'react-leaflet/hooks'
import { Circle } from 'react-leaflet/Circle'
import { CircleMarker } from 'react-leaflet/CircleMarker'

import L from "leaflet";

import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';

import DialogLocationNotAllowed from '../dialogs/DialogLocationNotAllowed';

export default function Location() {

  const [userLocation, setUserLocation] = useState([0, 0]);
  const [locationAccuracy, setLocationAccuracy] = useState(10);
  const [dialogLocationOpen, setDialogLocationOpen] = useState(false);

  const circleRef = useRef(undefined);

  const map = useMap();

  // do this because of bug on leaflet clicks
  const ref = useRef(undefined);
  useEffect(() => {
    if(ref.current) L.DomEvent.disableClickPropagation(ref.current);
  });

  useEffect(() => {
    const watchID = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);
        if(position.coords.accuracy) setLocationAccuracy(position.coords.accuracy);
      },
      (error) => {
        console.error(error);
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
    );

    return () => {
      navigator.geolocation.clearWatch(watchID);
    };
  }, []);

  var enlargeAccuracyCircle = true;

  if(circleRef.current) {
    const centerPoint = map.latLngToContainerPoint(userLocation);
    const borderPoint = map.latLngToContainerPoint(circleRef.current.getBounds()._northEast);
    const radiusInPixels = centerPoint.distanceTo(borderPoint);

    if(radiusInPixels > 11) {
      enlargeAccuracyCircle = false;
    }
  }

  function handleLocationClicked() {
    if (userLocation[0] !== 0 || userLocation[1] !== 0) {
      if (map.getCenter().distanceTo(userLocation) < 200000) map.flyTo(userLocation);
      else map.setView(userLocation);
    } else {
      navigator.permissions.query({name:'geolocation'}).then(permission => {
        if (permission.state === 'granted') {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setUserLocation([latitude, longitude]);
              if(position.coords.accuracy) setLocationAccuracy(position.coords.accuracy);
            },
            (error) => {
              console.error(error);
            },
            { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
          );
        } else if(permission.state === 'denied') {
          setDialogLocationOpen(true);
        }
      });
    }
  }

  function handleCloseDialogLocation() {
    setDialogLocationOpen(false);
  }

  return (
    <Fragment>
      <div ref={ref} className={'leaflet-bottom leaflet-left'} style={{ marginBottom: '74px'}}>
        <div className="leaflet-control">
          <Tooltip title="Ubicación actual" placement="right" arrow enterDelay={500}>
            <Button
              id="btn-location"
              variant='contained'
              color="neutral"
              size="small"
              aria-label="Crear objeto"
              onClick={handleLocationClicked}
              sx={{ minWidth: '34px', maxWidth: '34px', height: '34px' }}
            >
              <MyLocationRoundedIcon sx={{ fontSize: 20 }} />
            </Button>
          </Tooltip>
        </div>
      </div>
      {(userLocation[0] !== 0 || userLocation[1] !== 0) && (
        <Fragment>
          <Circle
            ref={circleRef}
            center={userLocation}
            radius={locationAccuracy}
            pathOptions={{ stroke: false, color: '#1976f2', fillOpacity: 0.25 }}
          />
          {enlargeAccuracyCircle ?
              <Fragment>
                <CircleMarker
                  center={userLocation}
                  radius={10}
                  pathOptions={{ stroke: false, color: '#1976f2', fillOpacity: 0.25 }}
                />
                <CircleMarker
                  center={userLocation}
                  radius={5.5}
                  pathOptions={{ weight: 0, color: '#ffffff', fillOpacity: 1 }}
                />
                <CircleMarker
                  center={userLocation}
                  radius={5}
                  pathOptions={{ weight: 0, color: '#1976f2', fillOpacity: 1 }}
                />
              </Fragment>
            :
              <Fragment>
                <CircleMarker
                  center={userLocation}
                  radius={5.5}
                  pathOptions={{ weight: 0, color: '#ffffff', fillOpacity: 1 }}
                />
                <CircleMarker
                  center={userLocation}
                  radius={5}
                  pathOptions={{ weight: 0, color: '#1976f2', fillOpacity: 1 }}
                />
              </Fragment>
          }
        </Fragment>
      )}
      <DialogLocationNotAllowed
        dialogLocationOpen={dialogLocationOpen}
        handleCloseDialogLocation={handleCloseDialogLocation}
      />
    </Fragment>
  );
}