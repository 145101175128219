import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import AccessibilityTableHeader from './AccessibilityTableHeader';
import AccessibilityTableRow from './AccessibilityTableRow';
import SingleAccessibilityTableRow from './SingleAccessibilityTableRow';
import TablePaginationActions from './TablePaginationActions';

export default function AccessibilityTable(props) {

  const { editModeOn, filteredAccessibility, selectedAccessibility, onSelectedAccessibilityChange,
    popupOpenAccessibilityPointId, onClickedAccessibilityPointIdChange, handleEditAccessibilityPointClicked,
    handleDeleteAccessibilityPointClicked, handleEditSelectedAccessibilityClicked, handleDeleteSelectedAccessibilityClicked,
    openImages, onMapPositionChange, mapFilterSelectionOn, selectMapFilterData } = props;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('timestamp');
  const [page, setPage] = useState(0);

  // Avoid a layout jump when reaching the last page with empty rows.
  const rowsPerPage = 25
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredAccessibility.length) : 0;

  useEffect(() => {
    if (filteredAccessibility.length === 0 || filteredAccessibility.length/rowsPerPage < page) {
      setPage(0);
    }
  }, [filteredAccessibility, page]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedAccessibility = filteredAccessibility;
      onSelectedAccessibilityChange(newSelectedAccessibility);
      return;
    }
    onSelectedAccessibilityChange([]);
  };

  const handleSelectClick = (accessibilityPoint) => {
    const selectedIndex = selectedAccessibility.findIndex((e) => e.id === accessibilityPoint.id);
    let newSelectedAccessibility = [];

    if (selectedIndex === -1) {
      newSelectedAccessibility = newSelectedAccessibility.concat(selectedAccessibility, accessibilityPoint);
    } else if (selectedIndex === 0) {
      newSelectedAccessibility = newSelectedAccessibility.concat(selectedAccessibility.slice(1));
    } else if (selectedIndex === selectedAccessibility.length - 1) {
      newSelectedAccessibility = newSelectedAccessibility.concat(selectedAccessibility.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedAccessibility = newSelectedAccessibility.concat(
        selectedAccessibility.slice(0, selectedIndex),
        selectedAccessibility.slice(selectedIndex + 1),
      );
    }

    onSelectedAccessibilityChange(newSelectedAccessibility);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const isSelected = (id) => selectedAccessibility.findIndex((e) => e.id === id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="Objetos"
        >
          <AccessibilityTableHeader
            editModeOn={editModeOn}
            filteredAccessibility={filteredAccessibility}
            selectedAccessibility={selectedAccessibility}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            mapFilterSelectionOn={mapFilterSelectionOn}
            handleEditSelectedAccessibilityClicked={handleEditSelectedAccessibilityClicked}
            handleDeleteSelectedAccessibilityClicked={handleDeleteSelectedAccessibilityClicked}
          />
          <TableBody>
            {(popupOpenAccessibilityPointId !== 0 && filteredAccessibility.filter((e) => e.id === popupOpenAccessibilityPointId)[0]) ?
              <SingleAccessibilityTableRow
                key={filteredAccessibility.filter((e) => e.id === popupOpenAccessibilityPointId)[0].id}
                editModeOn={editModeOn}
                row={filteredAccessibility.filter((e) => e.id === popupOpenAccessibilityPointId)[0]}
                onClickedAccessibilityPointIdChange={onClickedAccessibilityPointIdChange}
                isItemSelected={isSelected(filteredAccessibility.filter((e) => e.id === popupOpenAccessibilityPointId)[0].id)}
                handleSelectClick={handleSelectClick}
                handleEditAccessibilityPointClicked={handleEditAccessibilityPointClicked}
                handleDeleteAccessibilityPointClicked={handleDeleteAccessibilityPointClicked}
                openImages={openImages}
                onMapPositionChange={onMapPositionChange}
              />
              :
              filteredAccessibility.slice().sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <AccessibilityTableRow
                      key={row.id}
                      editModeOn={editModeOn}
                      row={row}
                      isItemSelected={isSelected(row.id)}
                      handleSelectClick={handleSelectClick}
                      handleEditAccessibilityPointClicked={handleEditAccessibilityPointClicked}
                      handleDeleteAccessibilityPointClicked={handleDeleteAccessibilityPointClicked}
                      openImages={openImages}
                      onMapPositionChange={onMapPositionChange}
                      mapFilterSelectionOn={mapFilterSelectionOn}
                      selectMapFilterData={selectMapFilterData}
                    />
                  );
                })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        id="table-pagination"
        component="div"
        count={filteredAccessibility.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        sx={{
          '.MuiTablePagination-displayedRows': {
            fontWeight: 'medium',
            fontSize: 'medium',
          }
        }}
      />
    </Box>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
