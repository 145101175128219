import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import ObjectsTableHeader from './ObjectsTableHeader';
import ObjectsTableRow from './ObjectsTableRow';
import SingleObjectTableRow from './SingleObjectTableRow';
import TablePaginationActions from './TablePaginationActions';

export default function ObjectsTable(props) {

  const { editModeOn, filteredObjects, selectedObjects, onSelectedObjectsChange, hoveringObjectId, onHoveringObjectIdChange,
    popupOpenObjectId, onPopupOpenObjectIdChange, handleEditObjectClicked, handleDeleteObjectClicked,
    handleEditSelectedObjectsClicked, handleDeleteSelectedObjectsClicked, openImages, onMapPositionChange,
    mapFilterSelectionOn, selectMapFilterData } = props;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('city');
  const [page, setPage] = useState(0);

  // Avoid a layout jump when reaching the last page with empty rows.
  const rowsPerPage = 25
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredObjects.length) : 0;

  useEffect(() => {
    if (filteredObjects.length === 0 || filteredObjects.length/rowsPerPage < page) {
      setPage(0);
    }
  }, [filteredObjects, page]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedObjects = filteredObjects;
      onSelectedObjectsChange(newSelectedObjects);
      return;
    }
    onSelectedObjectsChange([]);
  };

  const handleSelectClick = (object) => {
    const selectedIndex = selectedObjects.findIndex((e) => e.id === object.id);
    let newSelectedObjects = [];

    if (selectedIndex === -1) {
      newSelectedObjects = newSelectedObjects.concat(selectedObjects, object);
    } else if (selectedIndex === 0) {
      newSelectedObjects = newSelectedObjects.concat(selectedObjects.slice(1));
    } else if (selectedIndex === selectedObjects.length - 1) {
      newSelectedObjects = newSelectedObjects.concat(selectedObjects.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedObjects = newSelectedObjects.concat(
        selectedObjects.slice(0, selectedIndex),
        selectedObjects.slice(selectedIndex + 1),
      );
    }

    onSelectedObjectsChange(newSelectedObjects);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const isSelected = (id) => selectedObjects.findIndex((e) => e.id === id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="Objetos"
        >
          <ObjectsTableHeader
            editModeOn={editModeOn}
            filteredObjects={filteredObjects}
            selectedObjects={selectedObjects}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            mapFilterSelectionOn={mapFilterSelectionOn}
            handleEditSelectedObjectsClicked={handleEditSelectedObjectsClicked}
            handleDeleteSelectedObjectsClicked={handleDeleteSelectedObjectsClicked}
          />
          <TableBody>
            {(popupOpenObjectId !== 0 && filteredObjects.filter((e) => e.id === popupOpenObjectId)[0]) ?
              <SingleObjectTableRow
                key={filteredObjects.filter((e) => e.id === popupOpenObjectId)[0].id}
                editModeOn={editModeOn}
                row={filteredObjects.filter((e) => e.id === popupOpenObjectId)[0]}
                isItemSelected={isSelected(filteredObjects.filter((e) => e.id === popupOpenObjectId)[0].id)}
                handleSelectClick={handleSelectClick}
                handleEditObjectClicked={handleEditObjectClicked}
                handleDeleteObjectClicked={handleDeleteObjectClicked}
                openImages={openImages}
                onMapPositionChange={onMapPositionChange}
                onPopupOpenObjectIdChange={onPopupOpenObjectIdChange}
              />
              :
              filteredObjects.slice().sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <ObjectsTableRow
                      key={row.id}
                      editModeOn={editModeOn}
                      row={row}
                      hovering={hoveringObjectId === row.id}
                      onHoveringObjectIdChange={onHoveringObjectIdChange}
                      isItemSelected={isSelected(row.id)}
                      handleSelectClick={handleSelectClick}
                      handleEditObjectClicked={handleEditObjectClicked}
                      handleDeleteObjectClicked={handleDeleteObjectClicked}
                      openImages={openImages}
                      onMapPositionChange={onMapPositionChange}
                      mapFilterSelectionOn={mapFilterSelectionOn}
                      selectMapFilterData={selectMapFilterData}
                    />
                  );
                })
            }
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        id="table-pagination"
        component="div"
        count={filteredObjects.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={handleChangePage}
        ActionsComponent={TablePaginationActions}
        sx={{
          '.MuiTablePagination-displayedRows': {
            fontWeight: 'medium',
            fontSize: 'medium',
          }
        }}
      />
    </Box>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
