export const types = [
  'Aparca bicis',
  'Banco',
  'Camara CCTV',
  'Contenedor - Papelera',
  'Farola',
  'Hidrante',
  'Hornacina',
  'Imbornal',
  'Marca vial - Paso de cebra',
  'Semaforo',
  'Señal de trafico',
  'Tapa (pozos y arquetas)',
  'Torre',
];

export const subtypes = [
  {
    type: 'Tapa (pozos y arquetas)',
    subtypes: ['Arqueta', 'Pozo', 'No especificado']
  },
  {
    type: 'Contenedor - Papelera',
    subtypes: ['Contenedor', 'Obra', 'Papelera', 'No especificado']
  },
  {
    type: 'Señal de trafico',
    subtypes: ['Ceda el paso', 'Entrada prohibida', 'Estacionamiento', 'Fin Limitaciones', 'Obligatoriedad', 'Paso de cebra', 'Peligro', 'Prohibicion', 'Stop', 'Velocidad', 'Otros', 'No especificado']
  }
]