import React, { useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useTheme } from '@mui/material/styles';

import SignpostRoundedIcon from '@mui/icons-material/SignpostSharp';
import DirectionsBoatRoundedIcon from '@mui/icons-material/DirectionsBoatRounded';
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded';
import AddRoadRoundedIcon from '@mui/icons-material/AddRoadRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

import Carousel from 'react-material-ui-carousel';

import GradientBox from '../customs/GradientBox';
import GradientText from '../customs/GradientText';
import GradientBorderImage from '../customs/GradientBorderImage';
import BigIconChip from '../customs/BigIconChip';

import HomePageCover1 from '../../assets/images/homepage-cover-1.jpg';
import HomePageCover2 from '../../assets/images/homepage-cover-2.jpg';
import HomePageCover3 from '../../assets/images/homepage-cover-3.jpg';
import HomePageCover4 from '../../assets/images/homepage-cover-4.jpg';
import DigitalInventoryMainView from '../../assets/images/digital-inventory-main-view.png';
import DigitalInventoryAccView from '../../assets/images/digital-inventory-acc-view.png';
import DigitalInventoryObjectView from '../../assets/images/digital-inventory-object-view.png';

import { sendEmail } from '../../utils/api';

const coverImages = [HomePageCover1, HomePageCover2, HomePageCover3, HomePageCover4];

export default function Home() {

  const coverImageHeight = useMediaQuery('(min-width:1600px)') ? '480px' : '420px';

  const theme = useTheme();

  const [email, setEmail] = useState('');

  function updateEmail(event) {
    setEmail(event.target.value);
  }

  function handleSendClicked() {
    sendEmail("El siguiente correo desea ser contactado: \n\n" + email);
    setEmail("");
  }

  return (
    <Box id="home">
      <Box position="relative">
        <Carousel
          stopAutoPlayOnHover={false}
          animation='slide'
          duration={700}
          height={coverImageHeight}
          indicators={false}
          navButtonsAlwaysInvisible={true}
          sx={{ zIndex: 1 }}
        >
          {coverImages.map((image, index) =>
            <GradientBorderImage key={index}>
              <img
                src={image}
                alt="La imagen no ha podido ser descargada"
                style={{
                  width: '100%',
                  height: coverImageHeight,
                  objectFit: 'cover',
                }}
              />
            </GradientBorderImage>
          )}
        </Carousel>
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height={coverImageHeight}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ zIndex: 2 }}
        >
          <Typography
            variant="h3"
            color="white"
            fontWeight='bold'
            sx={{
              textShadow: '0px 0px 20px #000000',
              p: '20px',
              textAlign: 'center',
            }}
          >
            CREATING DIGITAL INVENTORIES FOR CITIES
          </Typography>
        </Box>
      </Box>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Box sx={{ mb: '50px' }}>
            <GradientText variant="h4" gutterBottom fontWeight='medium' sx={{ mx: 'auto', textAlign: 'center' }}>
              Inventarios Digitales
            </GradientText>
            <Typography variant="body1" color="grey.800" gutterBottom sx={{ fontSize: '18px', textAlign: 'center' }}>
              Utilizamos inteligencia artifical para crear inventarios digitales de activos urbanos de forma automática. <br />
              A partir de la detección de objetos con un algoritmo de deep learning somos capaces de crear réplicas virtuales de ciudades enteras, mostrando al detalle la posición y características de cada farola, señal, papelera, y demás objetos.
            </Typography>
          </Box>
          <Card sx={{ my: '30px', borderRadius: '10px' }}>
            <CardMedia
              component='img'
              src={DigitalInventoryMainView}
              alt="digital inventory"
            />
          </Card>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Grid container spacing={4} sx={{ my: 'auto' }}>
            <Grid item xs={12} md={6} >
              <GradientText variant="h4" gutterBottom fontWeight='medium'>
                Módulos
              </GradientText>
              <Typography variant="h5" color="grey.800" gutterBottom fontWeight='medium'>
                Detección de objetos en ciudades, y mucho más.
              </Typography>
              <Typography variant="body1" color="grey.800" gutterBottom sx={{ fontSize: '18px' }}>
                Gemminis ofrece un listado de módulos que sigue creciendo cada día, incluyendo inventariado, replanteo de carreteras, puertos, y accesibilidad. Con ellos llevará el mantenimiento de activos al siguiente nivel.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mx: 'auto', mb: theme.spacing(2) }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-start' },
                  flexWrap: 'wrap',
                  columnGap: { xs: '20px', sm: '90px' },
                  rowGap: '10px',
                  maxWidth: '460px',
                  mx: 'auto'
                }}
              >
                <BigIconChip
                  onClick={() => (window.location.href = '/catalogo/?section=inventory')}
                  icon={<SignpostRoundedIcon />}
                  tooltip="Inventariado"
                />
                <BigIconChip
                  onClick={() => (window.location.href = '/catalogo/?section=accessibility')}
                  icon={<AccessibleRoundedIcon />}
                  tooltip="Accesibilidad"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  flexWrap: 'wrap',
                  columnGap: { xs: '20px', sm: '90px' },
                  rowGap: '10px',
                  maxWidth: '460px',
                  mx: 'auto',
                  mt: { xs: '10px', sm: '-20px', md: '10px', lg: '-20px' }
                }}
              >
                <BigIconChip
                  onClick={() => (window.location.href = '/catalogo/?section=stakeouts')}
                  icon={<AddRoadRoundedIcon />}
                  tooltip="Replanteo"
                />
                <BigIconChip
                  onClick={() => (window.location.href = '/catalogo/?section=ports')}
                  icon={<DirectionsBoatRoundedIcon />}
                  tooltip="Puertos"
                />
              </Box>
            </Grid>
          </Grid>
          <Card sx={{ my: '30px', borderRadius: '10px' }}>
            <CardMedia
              component='img'
              src={DigitalInventoryAccView}
              alt="accessibility digital inventory"
            />
          </Card>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Grid container spacing={4} sx={{ mb: '20px' }}>
            <Grid item xs={12} md={6} sx={{ mb: '20px' }}>
              <GradientText variant="h4" gutterBottom fontWeight='medium'>
                Visualizador
              </GradientText>
              <Typography variant="body1" color="grey.800" gutterBottom sx={{ fontSize: '18px', mb: theme.spacing(2), pr: theme.spacing(4) }}>
                Nuestro visualizador de datos está diseñado para mantener una gestión óptima de activos. Desde la planificación de una inversión urbanística hasta la verificación del cumplimiento de los requisitos legales, el Inventario Digital de Gemminis le facilitará todo tipo de tareas.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mx: 'auto', mb: '20px', mt: { md: '50px' } }}>
              <Typography variant="h5" color="textPrimary" gutterBottom >
                Pruebe la demo y descúbralo usted mismo.
              </Typography>
              <Button
                color="primary"
                href='/demo/'
                variant="contained"
                size="large"
                aria-label="Demo"
                sx={{ fontSize: 'large', mt: theme.spacing(2) }}
                endIcon={<ArrowForwardRoundedIcon />}
              >
                DEMO
              </Button>
            </Grid>
          </Grid>
          <Card sx={{ my: '30px', borderRadius: '10px' }}>
            <CardMedia
              component='img'
              src={DigitalInventoryObjectView}
              alt="object digital inventory"
            />
          </Card>
        </Box>
      </GradientBox>
{/*       <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Grid container spacing={4} sx={{ mb: '20px' }}>
            <Grid item xs={12} lg={6} sx={{ mb: '20px' }}>
              <GradientText variant="h4" gutterBottom fontWeight='medium'>
                Planes
              </GradientText>
              <Typography variant="body1" color="grey.800" gutterBottom sx={{ fontSize: '18px', mb: theme.spacing(2), mr: theme.spacing(4) }}>
                Administración pública o privada. Grandes compañías con activos a su cargo u oficinas técnicas relacionadas con el sector. En Gemminis tenemos un plan que se adapta a sus necesidades.
              </Typography>
              <Button
                color="primary"
                href='/planes/'
                variant="contained"
                size="large"
                aria-label="Plans"
                sx={{ fontSize: 'large', mt: theme.spacing(2) }}
                endIcon={<ArrowForwardRoundedIcon />}
              >
                Planes
              </Button>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ mx: 'auto', mb: '20px' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} sx={{ mx: 'auto', mb: '20px', minWidth: '260px' }}>
                  <Card sx={{ p: theme.spacing(3), textAlign: 'center', minWidth: '200px', borderRadius: theme.shape.borderRadius }}>
                    <Typography variant="body2">
                      Desde
                    </Typography>
                    <Typography variant="h1" color="primary" sx={{ my: '10px' }}>
                      50€
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Por km interurbano analizado <br />
                      <br />
                      Incluimos todos los módulos de inventario de Gemminis
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mx: 'auto', mb: '20px', minWidth: '260px' }}>
                  <Card sx={{ p: theme.spacing(3), textAlign: 'center', minWidth: '200px', borderRadius: theme.shape.borderRadius }}>
                    <Typography variant="body2">
                      Desde
                    </Typography>
                    <Typography variant="h1" color="primary" sx={{ my: '10px' }}>
                      150€
                    </Typography>
                    <Typography variant="body2" color="textPrimary" >
                      Por km urbano analizado <br />
                      <br />
                      Incluimos todos los módulos de inventario de Gemminis
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </GradientBox> */}
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6), pt: theme.spacing(20) }}>
          <GradientText variant="h3" gutterBottom fontWeight='medium' sx={{ pb: '20px' }}>
            Eficiencia potenciada por Inteligencia Artificial. No espere más y comience a simplificar la gestión de activos.
          </GradientText>
          <Button
            color="primary"
            href='/empecemos/'
            variant="contained"
            size="large"
            aria-label="Start now"
            sx={{ fontSize: 'large', my: theme.spacing(4) }}
            endIcon={<ArrowForwardRoundedIcon />}
          >
            Empecemos
          </Button>
          <Grid container spacing={4} sx={{ mb: '20px', mt: theme.spacing(20) }}>
            <Grid item xs={12} md={6} sx={{ my: 'auto' }}>
              <Typography variant="body1" color="grey.800" gutterBottom sx={{ fontSize: '18px', mt: '8px' }}>
                ¿Interesado? ¿Dudas? Estaremos encantados de contactarle.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mx: 'auto', my: 'auto' }}>
              <Card sx={{ p: theme.spacing(1.5), borderRadius: '10px', display: 'flex' }}>
                <TextField
                  id="contact-email"
                  label="Correo"
                  variant="outlined"
                  value={email}
                  onChange={updateEmail}
                  sx={{ flexGrow: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  aria-label="Send mail"
                  onClick={handleSendClicked}
                  sx={{ height: '54px', ml: '10px', display: { xs: 'none', sm: 'inherit' } }}
                  endIcon={<SendRoundedIcon sx={{ pl: '2px' }} />}
                >
                  Enviar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  aria-label="Send mail"
                  onClick={handleSendClicked}
                  sx={{ height: '54px', width: '50px', ml: '10px', display: { sm: 'none' } }}
                >
                  <SendRoundedIcon />
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </GradientBox>
    </Box>
  );
}