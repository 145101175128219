import React, { useState, useEffect, useRef } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

class FiltersValues {
  constructor() {
    this.cities = [];
    this.roads = [];
    this.pavements = [];
    this.slopes = [];
    this.avgWidthSidewalks = [];
    this.accessibility = {
      values: [],
      notAccessibleReasons: {
        widthReasons: [],
        heightReasons: [],
        crosswalkReasons: [],
      }
    };
    this.reviewed = [];
    this.dates = [];
  }
}

export default function AccessibilityFilters(props) {

  const { data, onFilteredDataChange, updatedAccessibility, deletedAccessibility } = props;

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const [uniqueValues, setUniqueValues] = useState(new FiltersValues());
  const [displayValues, setDisplayValues] = useState(new FiltersValues());
  const [selectedValues, setSelectedValues] = useState(new FiltersValues());

  const [anyValueSelected, setAnyValueSelected] = useState({
    cities: false,
    roads: false,
    pavements: false,
    slopes: false,
    avgWidthSidewalks: false,
    accessibility: false,
    reviewed: false,
    dates: false
  });

  const [firstSelectedFilter, setFirstSelectedFilter] = useState(undefined);
  const [checkboxState, setCheckboxState] = useState([]);

  const [anchorElements, setAnchorElemenets] = useState({
    cities: null,
    roads: null,
    pavements: null,
    slopes: null,
    avgWidthSidewalks: null,
    accessibility: null,
    reviewed: false,
    dates: null
  });

  const [prevUpdatedAccessibilityPoint, setPrevUpdatedAccessibilityPoint] = useState({});
  const [prevDeletedAccessibilityPoint, setPrevDeletedAccessibilityPoint] = useState({});

  const notAccessibleCheckboxId = useRef('');

  const isWide = useMediaQuery('(min-width:1430px)');

  useEffect(() => {
    if (prevUpdatedAccessibilityPoint !== updatedAccessibility) {
      updateAccessibilityPointFromFilters();
      setPrevUpdatedAccessibilityPoint(updatedAccessibility);
    } else if (prevDeletedAccessibilityPoint !== deletedAccessibility) {
      deleteAccessibilityPointFromFilters();
      setPrevDeletedAccessibilityPoint(deletedAccessibility);
    } else {
      calculateUniqueValues(checkboxState);
      calculateDisplayValues(selectedValues, false);
      updateFilteredData(selectedValues);
    }
  }, [data, updatedAccessibility, deletedAccessibility]);

  const handleOpenFilterMenu = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };

  const filters = ["cities", "roads", "pavements", "slopes",
    "avgWidthSidewalks", "accessibility", "reviewed", "dates"];

  const handleFilterBtnClick = (event) => {
    for (const filter of filters) {
      if (event.currentTarget.id.includes(filter)) {
        const newAnchorElements = { ...anchorElements, [filter]: event.currentTarget };
        setAnchorElemenets(newAnchorElements);
        break;
      }
    }
  };

  const handleFilterBtnClose = (event) => {
    for (const filter of filters) {
      if (event.target.parentElement.id.includes(filter)) {
        const newAnchorElements = { ...anchorElements, [filter]: null };
        setAnchorElemenets(newAnchorElements);
        break;
      }
    }
  };

  const handleCheckboxChange = (event) => {
    let id = event.target.id;
    let index = checkboxState.findIndex((e) => e.id === id);
    const newCheckboxState = [...checkboxState];
    if (index === -1) {
      newCheckboxState.push({ id, checked: true });

      if (id.includes("accessibility-reasons")) {
        let indexNotAccessible = checkboxState.findIndex((e) => e.id.includes(notAccessibleCheckboxId.current));
        if (indexNotAccessible === -1) {
          newCheckboxState.push({ id: notAccessibleCheckboxId.current, checked: true });
        } else {
          let notAccessibleChecked = newCheckboxState[indexNotAccessible].checked;
          if (!notAccessibleChecked) {
            newCheckboxState[indexNotAccessible] = { id: notAccessibleCheckboxId.current, checked: true };
          }
        }
      }
    } else {
      let checked = newCheckboxState[index].checked;
      newCheckboxState[index] = { id, checked: !checked };

      if (id.includes(notAccessibleCheckboxId.current) && checked) {
        newCheckboxState.forEach((e) => {
          if (e.id.includes("accessibility-reasons")) {
            e.checked = false;
          }
        });
      }

      if (id.includes("accessibility-reasons")) {
        let indexNotAccessible = checkboxState.findIndex((e) => e.id.includes(notAccessibleCheckboxId.current));
        let notAccessibleChecked = newCheckboxState[indexNotAccessible].checked;
        let anyReasonChecked = newCheckboxState.some((e) => e.id.includes("accessibility-reasons") && e.checked);

        if (!checked && !notAccessibleChecked) {
          newCheckboxState[indexNotAccessible] = { id: notAccessibleCheckboxId.current, checked: true };
        }

        if (checked && notAccessibleChecked && !anyReasonChecked) {
          newCheckboxState[indexNotAccessible] = { id: notAccessibleCheckboxId.current, checked: false };
        }
      }
    }

    setCheckboxState(newCheckboxState);
  }

  function handleResetClicked() {

    const newCheckboxState = checkboxState.map((e) => ({ ...e, checked: false }));
    setCheckboxState(newCheckboxState);

    const newAnyValueSelected = anyValueSelected;
    filters.forEach(filter => newAnyValueSelected[filter] = false);
    setAnyValueSelected(newAnyValueSelected);

    setDisplayValues(uniqueValues);

    const newSelectedValues = new FiltersValues();
    setSelectedValues(newSelectedValues);
    updateFilteredData(newSelectedValues);
  }

  const handleCheckAllClicked = (event) => {

    for (const filter of filters) {
      if (event.target.id.includes(filter)) {

        const newSelectedValues = selectedValues;
        const indexToUpdate = [];

        if (filter === "accessibility") {

          displayValues.accessibility.values.forEach(value => {
            indexToUpdate.push(uniqueValues.accessibility.values.indexOf(value));
            let index = newSelectedValues.accessibility.values.indexOf(value);
            if (index === -1) {
              newSelectedValues.accessibility.values.push(value);
              if (firstSelectedFilter === undefined) setFirstSelectedFilter(filter);
            }
          });

          displayValues.accessibility.notAccessibleReasons.widthReasons.forEach(value => {
            indexToUpdate.push(uniqueValues.accessibility.notAccessibleReasons.widthReasons.indexOf(value));
            let index = newSelectedValues.accessibility.notAccessibleReasons.widthReasons.indexOf(value);
            if (index === -1) {
              newSelectedValues.accessibility.notAccessibleReasons.widthReasons.push(value);
              if (firstSelectedFilter === undefined) setFirstSelectedFilter(filter);
            }
          });

          displayValues.accessibility.notAccessibleReasons.heightReasons.forEach(value => {
            indexToUpdate.push(uniqueValues.accessibility.notAccessibleReasons.heightReasons.indexOf(value));
            let index = newSelectedValues.accessibility.notAccessibleReasons.heightReasons.indexOf(value);
            if (index === -1) {
              newSelectedValues.accessibility.notAccessibleReasons.heightReasons.push(value);
              if (firstSelectedFilter === undefined) setFirstSelectedFilter(filter);
            }
          });

          displayValues.accessibility.notAccessibleReasons.crosswalkReasons.forEach(value => {
            indexToUpdate.push(uniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.indexOf(value));
            let index = newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons.indexOf(value);
            if (index === -1) {
              newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons.push(value);
              if (firstSelectedFilter === undefined) setFirstSelectedFilter(filter);
            }
          });

        } else {
          displayValues[filter].forEach(value => {
            indexToUpdate.push(uniqueValues[filter].indexOf(value));
            let index = newSelectedValues[filter].indexOf(value);
            if (index === -1) {
              newSelectedValues[filter].push(value);
              if (firstSelectedFilter === undefined) setFirstSelectedFilter(filter);
            }
          });
        }

        const newCheckboxState = checkboxState.map(item => {
          if (item.id.includes(filter) && indexToUpdate.some(index => item.id.includes(index))) {
            return { ...item, checked: true };
          }
          return item;
        });
        setCheckboxState(newCheckboxState);

        const newAnyValueSelected = { ...anyValueSelected, [filter]: true };
        setAnyValueSelected(newAnyValueSelected);

        calculateDisplayValues(newSelectedValues, false);
        setSelectedValues(newSelectedValues);
        updateFilteredData(newSelectedValues);

        break;
      }
    }
  }

  const handleUncheckAllClicked = (event) => {

    for (const filter of filters) {
      if (event.target.id.includes(filter)) {

        const newCheckboxState = checkboxState.map(item => {
          if (item.id.includes(filter)) {
            return { ...item, checked: false };
          }
          return item;
        });
        setCheckboxState(newCheckboxState);

        const newAnyValueSelected = { ...anyValueSelected, [filter]: false };
        setAnyValueSelected(newAnyValueSelected);

        const newSelectedValues = selectedValues;
        if (filter === "accessibility") {
          newSelectedValues.accessibility.values = [];
          newSelectedValues.accessibility.notAccessibleReasons.widthReasons = [];
          newSelectedValues.accessibility.notAccessibleReasons.heightReasons = [];
          newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons = [];
        } else {
          newSelectedValues[filter] = []
        }
        
        if (firstSelectedFilter === filter) setFirstSelectedFilter(undefined);

        calculateDisplayValues(newSelectedValues, false);
        setSelectedValues(newSelectedValues);
        updateFilteredData(newSelectedValues);

        break;
      }
    }
  }

  return (
    isWide ?
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ml: '2px',
          mt: '2px',
        }}
      >
        {filters.map((filter) => {
          const open = Boolean(anchorElements[filter]);

          let title;
          switch (filter) {
            case "cities":
              title = "Ciudad";
              break;
            case "roads":
              title = "Calle";
              break;
            case "pavements":
              title = "Pavimento";
              break;
            case "slopes":
              title = "Pendiente";
              break;
            case "avgWidthSidewalks":
              title = "Anchura";
              break;
            case "accessibility":
              title = "Accesibilidad";
              break;
            case "reviewed":
              title = "Revisado";
              break;
            case "dates":
              title = "Fecha";
              break;
            default:
              title = "Fecha";
              break;
          }

          return (
            <Box key={filter}>
              <Button
                id={"btn-dropdown-filter-" + filter}
                aria-controls={open ? 'menu-filter-' + filter : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                color="neutral"
                sx={{ margin: '8px', fontWeight: 'regular' }}
                onClick={handleFilterBtnClick}
                endIcon={open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
              >
                {title}
              </Button>
              <Menu
                id={"menu-filter-" + filter}
                MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
                anchorEl={anchorElements[filter]}
                open={open}
                onClose={handleFilterBtnClose}
              >
                <Button
                  id={"btn-check-all-" + filter}
                  size="small"
                  sx={{ marginLeft: '6px', fontSize: '12px', fontWeight: 'regular' }}
                  onClick={handleCheckAllClicked}
                >
                  Marcar todos
                </Button>
                <Button
                  id={"btn-uncheck-all-" + filter}
                  size="small"
                  sx={{ marginRight: '6px', fontSize: '12px', fontWeight: 'regular' }}
                  onClick={handleUncheckAllClicked}
                >
                  Desmarcar todos
                </Button>
                {filter === "accessibility" ?
                  displayValues.accessibility.values.map((value) => {
                    if (value === undefined || value === null || value === '') return null;

                    let index = uniqueValues.accessibility.values.indexOf(value);
                    let id = "checkbox-accessibility-" + (value === "Acera no accesible" ? "not-" : "") + index;
                    if (value === "Acera no accesible") notAccessibleCheckboxId.current = id;
                    let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                    return (
                      <Box key={index}>
                        <FormGroup sx={{ marginLeft: '10px' }}>
                          <FormControlLabel
                            control={<Checkbox
                              id={id}
                              checked={checked}
                              onChange={handleCheckboxChange}
                              onClick={() => updateSelectedValues(filter, value)}
                            />}
                            label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{value}</div>}
                          />
                        </FormGroup>
                        {value === "Acera no accesible" && (
                          <Box sx={{ ml: '40px' }}>
                            {displayValues.accessibility.notAccessibleReasons.widthReasons.length > 0 && (
                              <Box>
                                <Typography sx={{ my: '2px', fontWeight: 'medium' }}>Anchura de acera insuficiente</Typography>
                                {displayValues.accessibility.notAccessibleReasons.widthReasons.map((value) => {
                                  if (value === undefined || value === null || value === '') return null;
            
                                  let index = uniqueValues.accessibility.notAccessibleReasons.widthReasons.indexOf(value);
                                  let id = "checkbox-accessibility-reasons-widths-" + index;
                                  let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                                  return (
                                    <Box key={index}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={<Checkbox
                                            id={id}
                                            checked={checked}
                                            onChange={handleCheckboxChange}
                                            onClick={() => updateSelectedValues(filter, value)}
                                          />}
                                          label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{value}</div>}
                                        />
                                      </FormGroup>
                                    </Box>
                                  );
                                })}
                              </Box>
                            )}
                            {displayValues.accessibility.notAccessibleReasons.heightReasons.length > 0 && (
                              <Box>
                                <Typography sx={{ my: '2px', fontWeight: 'medium' }}>Altura de obstáculos insuficiente</Typography>
                                  {displayValues.accessibility.notAccessibleReasons.heightReasons.map((value) => {
                                    if (value === undefined || value === null || value === '') return null;
              
                                    let index = uniqueValues.accessibility.notAccessibleReasons.heightReasons.indexOf(value);
                                    let id = "checkbox-accessibility-reasons-heights-" + index;
                                    let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                                    return (
                                      <Box key={index}>
                                        <FormGroup>
                                          <FormControlLabel
                                            control={<Checkbox
                                              id={id}
                                              checked={checked}
                                              onChange={handleCheckboxChange}
                                              onClick={() => updateSelectedValues(filter, value)}
                                            />}
                                            label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{value}</div>}
                                          />
                                        </FormGroup>
                                      </Box>
                                    );
                                  })}
                              </Box>
                            )}
                            {displayValues.accessibility.notAccessibleReasons.crosswalkReasons.length > 0 && (
                              <Box>
                                <Typography sx={{ my: '2px', fontWeight: 'medium' }}>Paso de cebra no adaptado</Typography>
                                {displayValues.accessibility.notAccessibleReasons.crosswalkReasons.map((value) => {
                                  if (value === undefined || value === null || value === '') return null;
            
                                  let index = uniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.indexOf(value);
                                  let id = "checkbox-accessibility-reasons-crosswalks-" + index;
                                  let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                                  return (
                                    <Box key={index}>
                                      <FormGroup>
                                        <FormControlLabel
                                          control={<Checkbox
                                            id={id}
                                            checked={checked}
                                            onChange={handleCheckboxChange}
                                            onClick={() => updateSelectedValues(filter, value)}
                                          />}
                                          label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{value}</div>}
                                        />
                                      </FormGroup>
                                    </Box>
                                  );
                                })}
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    );
                  })
                  :
                  displayValues[filter].map((value) => {
                    if (value === undefined || value === null || value === '') return null;

                    let index = uniqueValues[filter].indexOf(value);
                    let id = "checkbox-" + filter + "-" + index;
                    let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                    let text = value;
                    if (filter === "reviewed") text = value ? "Sí" : "No";
                    if (filter === "dates") text = new Date(value).toLocaleDateString('es-ES');
                    return (
                      <Box key={index}>
                        <FormGroup sx={{ marginLeft: '10px' }}>
                          <FormControlLabel
                            control={<Checkbox
                              id={id}
                              checked={checked}
                              onChange={handleCheckboxChange}
                              onClick={() => updateSelectedValues(filter, value)}
                            />}
                            label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{text}</div>}
                          />
                        </FormGroup>
                      </Box>
                    );
                  })
                }
              </Menu>
            </Box>
          );
        })}
        <Fab
          color="tertiary"
          variant="extended"
          sx={{ height: '36px', margin: '8px' }}
          onClick={handleResetClicked}
        >
          Restablecer filtros
        </Fab>
      </Box>
      :
      <Box>
        <Tooltip title="Filtros" arrow enterDelay={500}>
          <Fab
            color="tertiary"
            size="medium"
            aria-controls="menu-filter"
            aria-haspopup="true"
            onClick={handleOpenFilterMenu}
            sx={{ ml: '18px', mt: '12px' }}
          >
            <FilterAltRoundedIcon sx={{ fontSize: 28 }} />
          </Fab>
        </Tooltip>
        <Drawer
          variant="temporary"
          anchor="left"
          open={Boolean(anchorElFilter)}
          onClose={handleCloseFilterMenu}
          PaperProps={{
            sx: {
              width: 1,
            }
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '12px' }}>
            <Tooltip title="Cerrar" arrow enterDelay={500}>
              <IconButton
                size="medium"
                aria-controls="filter-appbar"
                aria-haspopup="true"
                onClick={handleCloseFilterMenu}
                color="inherit"
                sx={{
                  justifyContent: 'left',
                  width: '46px',
                  ml: '14px',
                  mb: '2px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <CloseRoundedIcon sx={{ fontSize: 28 }} />
              </IconButton>
            </Tooltip>
            <Fab
              color="primary"
              variant="extended"
              sx={{ height: '36px', position: 'absolute', right: '14px' }}
              onClick={handleResetClicked}
            >
              Restablecer filtros
            </Fab>
          </Box>
          <Box>
            {filters.map((filter) => {

              let title;
              switch (filter) {
                case "cities":
                  title = "Ciudad";
                  break;
                case "roads":
                  title = "Calle";
                  break;
                case "pavements":
                  title = "Pavimento";
                  break;
                case "slopes":
                  title = "Pendiente";
                  break;
                case "avgWidthSidewalks":
                  title = "Anchura acera";
                  break;
                case "accessibility":
                  title = "Accesibilidad";
                  break;
                case "reviewed":
                  title = "Revisado";
                  break;
                case "dates":
                  title = "Fecha";
                  break;
                default:
                  title = "Fecha";
                  break;
              }

              return (
                <Accordion
                  key={filter}
                  disableGutters
                  sx={{
                    pl: '12px',
                    boxShadow: 'none',
                    '&.MuiPaper-root:before': {
                      opacity: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRoundedIcon sx={{ fontSize: 36, color: 'secondary.contrastText' }} />}
                    aria-controls={filter + "-content"}
                    id={filter + "-header"}
                  >
                    <Typography variant='h5'>{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Button
                      id={"btn-check-all-" + filter}
                      variant='outlined'
                      sx={{ fontWeight: 'regular', mr: '8px', mb: '6px' }}
                      onClick={handleCheckAllClicked}
                    >
                      Marcar todos
                    </Button>
                    <Button
                      id={"btn-uncheck-all-" + filter}
                      variant='outlined'
                      sx={{ fontWeight: 'regular', mb: '6px' }}
                      onClick={handleUncheckAllClicked}
                    >
                      Desmarcar todos
                    </Button>
                    <Box sx={{ ml: '-10px' }}>
                      {filter === "accessibility" ?
                        <Box>
                          {displayValues.accessibility.values.map((value) => {
                            if (value === undefined || value === null || value === '') return null;

                            let index = uniqueValues.accessibility.values.indexOf(value);
                            let id = "checkbox-accessibility-" + (value === "Acera no accesible" ? "not-" : "") + index;
                            if (value === "Acera no accesible") notAccessibleCheckboxId.current = id;
                            let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                            return (
                              <Box key={index}>
                                <FormGroup sx={{ marginLeft: '10px' }}>
                                  <FormControlLabel
                                    control={<Checkbox
                                      id={id}
                                      checked={checked}
                                      onChange={handleCheckboxChange}
                                      onClick={() => updateSelectedValues(filter, value)}
                                    />}
                                    label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{value}</div>}
                                  />
                                </FormGroup>
                                {value === "Acera no accesible" && (
                                  <Box sx={{ ml: '40px' }}>
                                    {displayValues.accessibility.notAccessibleReasons.widthReasons.length > 0 && (
                                      <Box>
                                        <Typography sx={{ my: '2px', fontWeight: 'medium' }}>Anchura de acera insuficiente</Typography>
                                        {displayValues.accessibility.notAccessibleReasons.widthReasons.map((value) => {
                                          if (value === undefined || value === null || value === '') return null;
                    
                                          let index = uniqueValues.accessibility.notAccessibleReasons.widthReasons.indexOf(value);
                                          let id = "checkbox-accessibility-reasons-widths-" + index;
                                          let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                                          return (
                                            <Box key={index}>
                                              <FormGroup>
                                                <FormControlLabel
                                                  control={<Checkbox
                                                    id={id}
                                                    checked={checked}
                                                    onChange={handleCheckboxChange}
                                                    onClick={() => updateSelectedValues(filter, value)}
                                                  />}
                                                  label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{value}</div>}
                                                />
                                              </FormGroup>
                                            </Box>
                                          );
                                        })}
                                      </Box>
                                    )}
                                    {displayValues.accessibility.notAccessibleReasons.heightReasons.length > 0 && (
                                      <Box>
                                        <Typography sx={{ my: '2px', fontWeight: 'medium' }}>Altura de obstáculos insuficiente</Typography>
                                          {displayValues.accessibility.notAccessibleReasons.heightReasons.map((value) => {
                                            if (value === undefined || value === null || value === '') return null;
                      
                                            let index = uniqueValues.accessibility.notAccessibleReasons.heightReasons.indexOf(value);
                                            let id = "checkbox-accessibility-reasons-heights-" + index;
                                            let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                                            return (
                                              <Box key={index}>
                                                <FormGroup>
                                                  <FormControlLabel
                                                    control={<Checkbox
                                                      id={id}
                                                      checked={checked}
                                                      onChange={handleCheckboxChange}
                                                      onClick={() => updateSelectedValues(filter, value)}
                                                    />}
                                                    label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{value}</div>}
                                                  />
                                                </FormGroup>
                                              </Box>
                                            );
                                          })}
                                      </Box>
                                    )}
                                    {displayValues.accessibility.notAccessibleReasons.crosswalkReasons.length > 0 && (
                                      <Box>
                                        <Typography sx={{ my: '2px', fontWeight: 'medium' }}>Paso de cebra no adaptado</Typography>
                                        {displayValues.accessibility.notAccessibleReasons.crosswalkReasons.map((value) => {
                                          if (value === undefined || value === null || value === '') return null;
                    
                                          let index = uniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.indexOf(value);
                                          let id = "checkbox-accessibility-reasons-crosswalks-" + index;
                                          let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                                          return (
                                            <Box key={index}>
                                              <FormGroup>
                                                <FormControlLabel
                                                  control={<Checkbox
                                                    id={id}
                                                    checked={checked}
                                                    onChange={handleCheckboxChange}
                                                    onClick={() => updateSelectedValues(filter, value)}
                                                  />}
                                                  label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{value}</div>}
                                                />
                                              </FormGroup>
                                            </Box>
                                          );
                                        })}
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                        :
                        displayValues[filter].map((value) => {
                          if (value === undefined || value === null || value === '') return null;

                          let index = uniqueValues[filter].indexOf(value);
                          let id = "checkbox-" + filter + "-" + index;
                          let checked = checkboxState.find((e) => e.id === id)?.checked || false;
                          let text = value;
                          if (filter === "reviewed") text = value ? "Sí" : "No";
                          if (filter === "dates") text = new Date(value).toLocaleDateString('es-ES');
                          if (filter === "accessibility") text = value.replaceAll("; ", "\n");
                          return (
                            <Box key={index}>
                              <FormGroup sx={{ marginLeft: '10px' }}>
                                <FormControlLabel
                                  control={<Checkbox
                                    id={id}
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                    onClick={() => updateSelectedValues(filter, value)}
                                  />}
                                  label={<div style={{ whiteSpace: 'pre-line', paddingTop: '4px', paddingBottom: '4px' }}>{text}</div>}
                                />
                              </FormGroup>
                            </Box>
                          );
                        })
                      }
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Drawer>
      </Box>
  );

  function calculateUniqueValues(updatedCheckboxState) {
    const newUniqueValues = new FiltersValues();
    const newCheckboxState = [];

    data.forEach(value => {
      if (!newUniqueValues.cities.includes(value.city)) newUniqueValues.cities.push(value.city);
      if (!newUniqueValues.roads.includes(value.road)) newUniqueValues.roads.push(value.road);
      if (!newUniqueValues.pavements.includes(value.pavement)) newUniqueValues.pavements.push(value.pavement);
      if (!newUniqueValues.slopes.includes(getFilterSlope(value.slope))) newUniqueValues.slopes.push(getFilterSlope(value.slope));
      if (!newUniqueValues.avgWidthSidewalks.includes(getFilterWidth(value.avg_width_sidewalk))) {
        newUniqueValues.avgWidthSidewalks.push(getFilterWidth(value.avg_width_sidewalk));
      }
      if (!newUniqueValues.accessibility.values.includes(getFilterAccessibility(value.has_sidewalk, value.accessible))) {
        newUniqueValues.accessibility.values.push(getFilterAccessibility(value.has_sidewalk, value.accessible));
      }

      let reasonsToAdd = getFilterWidthReasons(value.not_accessible_reason.width);

      reasonsToAdd.forEach(reason => {
        if (!newUniqueValues.accessibility.notAccessibleReasons.widthReasons.includes(reason)) {
          newUniqueValues.accessibility.notAccessibleReasons.widthReasons.push(reason);
        }
      });

      reasonsToAdd = getFilterHeightReasons(value.not_accessible_reason.height);

      reasonsToAdd.forEach(reason => {
        if (!newUniqueValues.accessibility.notAccessibleReasons.heightReasons.includes(reason)) {
          newUniqueValues.accessibility.notAccessibleReasons.heightReasons.push(reason);
        }
      });

      reasonsToAdd = getFilterCrosswalkReasons(value.not_accessible_reason.crosswalk);

      reasonsToAdd.forEach(reason => {
        if (!newUniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.includes(reason)) {
          newUniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.push(reason);
        }
      });

      if (!newUniqueValues.reviewed.includes(value.reviewed)) newUniqueValues.reviewed.push(value.reviewed);
      if (!newUniqueValues.dates.includes(value.date)) newUniqueValues.dates.push(value.date);
    });

    setUniqueValues(newUniqueValues);

    for (let filter in newUniqueValues) {
      if (filter === "accessibility") {
        newUniqueValues.accessibility.values.forEach(function (value) {
          let index = newUniqueValues.accessibility.values.indexOf(value);
          let id = "checkbox-accessibility-" + (value === 'Acera no accesible' ? 'not-' : '') + index;
          let checked = updatedCheckboxState.find((e) => e.id === id)?.checked || false;
          newCheckboxState.push({ id, checked });
        });
        newUniqueValues.accessibility.notAccessibleReasons.widthReasons.forEach(function (value) {
          let index = newUniqueValues.accessibility.notAccessibleReasons.widthReasons.indexOf(value);
          let id = "checkbox-accessibility-reasons-widths-" + index;
          let checked = updatedCheckboxState.find((e) => e.id === id)?.checked || false;
          newCheckboxState.push({ id, checked });
        });
        newUniqueValues.accessibility.notAccessibleReasons.heightReasons.forEach(function (value) {
          let index = newUniqueValues.accessibility.notAccessibleReasons.heightReasons.indexOf(value);
          let id = "checkbox-accessibility-reasons-heights-" + index;
          let checked = updatedCheckboxState.find((e) => e.id === id)?.checked || false;
          newCheckboxState.push({ id, checked });
        });
        newUniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.forEach(function (value) {
          let index = newUniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.indexOf(value);
          let id = "checkbox-accessibility-reasons-crosswalks-" + index;
          let checked = updatedCheckboxState.find((e) => e.id === id)?.checked || false;
          newCheckboxState.push({ id, checked });
        });
      } else {
        newUniqueValues[filter].forEach(function (value) {
          let index = newUniqueValues[filter].indexOf(value);
          let id = "checkbox-" + filter + "-" + index;
          let checked = updatedCheckboxState.find((e) => e.id === id)?.checked || false;
          newCheckboxState.push({ id, checked });
        });
      }
    }

    setCheckboxState(newCheckboxState);
  }

  function updateSelectedValues(updatedFilter, updatedValue) {

    const newSelectedValues = selectedValues;
    let index;

    if (anyValueSelected[updatedFilter]) {
      if (updatedFilter === "accessibility") {

        if(uniqueValues.accessibility.notAccessibleReasons.widthReasons.includes(updatedValue)) {
          index = newSelectedValues.accessibility.notAccessibleReasons.widthReasons.indexOf(updatedValue);
          if (index === -1) {
            newSelectedValues.accessibility.notAccessibleReasons.widthReasons.push(updatedValue);
          } else {
            newSelectedValues.accessibility.notAccessibleReasons.widthReasons.splice(index, 1);
          }
        }

        if(uniqueValues.accessibility.notAccessibleReasons.heightReasons.includes(updatedValue)) {
          index = newSelectedValues.accessibility.notAccessibleReasons.heightReasons.indexOf(updatedValue);
          if (index === -1) {
            newSelectedValues.accessibility.notAccessibleReasons.heightReasons.push(updatedValue);
          } else {
            newSelectedValues.accessibility.notAccessibleReasons.heightReasons.splice(index, 1);
          }
        }

        if(uniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.includes(updatedValue)) {
          index = newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons.indexOf(updatedValue);
          if (index === -1) {
            newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons.push(updatedValue);
          } else {
            newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons.splice(index, 1);
          }
        }

        if(uniqueValues.accessibility.values.includes(updatedValue)) {
          index = newSelectedValues.accessibility.values.indexOf(updatedValue);
          if (index === -1) {
            newSelectedValues.accessibility.values.push(updatedValue);
          } else {
            newSelectedValues.accessibility.values.splice(index, 1);
            if (updatedValue === 'Acera no accesible') {
              newSelectedValues.accessibility.notAccessibleReasons.widthReasons = [];
              newSelectedValues.accessibility.notAccessibleReasons.heightReasons = [];
              newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons = [];
            }
          }
        } else {
          index = newSelectedValues.accessibility.values.indexOf('Acera no accesible');
          if (index === -1) {
            newSelectedValues.accessibility.values.push('Acera no accesible');
          } else if (
            newSelectedValues.accessibility.notAccessibleReasons.widthReasons.length === 0 &&
            newSelectedValues.accessibility.notAccessibleReasons.heightReasons.length === 0 &&
            newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons.length === 0
          ){
            newSelectedValues.accessibility.values.splice(index, 1);
          }
        }

        if (newSelectedValues.accessibility.values.length === 0 &&
          newSelectedValues.accessibility.notAccessibleReasons.widthReasons.length === 0 &&
          newSelectedValues.accessibility.notAccessibleReasons.heightReasons.length === 0 &&
          newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons.length === 0
        ) {
          const newAnyValueSelected = { ...anyValueSelected, [updatedFilter]: false };
          setAnyValueSelected(newAnyValueSelected);
          if (firstSelectedFilter === updatedFilter) setFirstSelectedFilter(undefined);
        }

      } else {
        index = newSelectedValues[updatedFilter].indexOf(updatedValue);
        if (index === -1) {
          newSelectedValues[updatedFilter].push(updatedValue);
        } else {
          newSelectedValues[updatedFilter].splice(index, 1);
          if (newSelectedValues[updatedFilter].length === 0) {
            const newAnyValueSelected = { ...anyValueSelected, [updatedFilter]: false };
            setAnyValueSelected(newAnyValueSelected);
            if (firstSelectedFilter === updatedFilter) setFirstSelectedFilter(undefined);
          }
        }
      }
    } else {
      const newAnyValueSelected = { ...anyValueSelected, [updatedFilter]: true };
      setAnyValueSelected(newAnyValueSelected);
      if (firstSelectedFilter === undefined) setFirstSelectedFilter(updatedFilter);
      if (updatedFilter === "accessibility") {
        if(uniqueValues.accessibility.values.includes(updatedValue)) {
          newSelectedValues.accessibility.values.push(updatedValue);
        } else {
          newSelectedValues.accessibility.values.push('Acera no accesible');
        }

        if(uniqueValues.accessibility.notAccessibleReasons.widthReasons.includes(updatedValue)) {
          newSelectedValues.accessibility.notAccessibleReasons.widthReasons.push(updatedValue);
        }
        if(uniqueValues.accessibility.notAccessibleReasons.heightReasons.includes(updatedValue)) {
          newSelectedValues.accessibility.notAccessibleReasons.heightReasons.push(updatedValue);
        }
        if(uniqueValues.accessibility.notAccessibleReasons.crosswalkReasons.includes(updatedValue)) {
          newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons.push(updatedValue);
        }
      } else {
        newSelectedValues[updatedFilter].push(updatedValue);
      }
    }

    calculateDisplayValues(newSelectedValues, false);
    updateFilteredData(newSelectedValues);
    setSelectedValues(newSelectedValues);
  }

  function updateFilteredData(newSelectedValues) {

    let filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    let filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    let filteredDataPavements = filterData("pavements", "pavement", filteredDataRoads, newSelectedValues);
    let filteredDataSlopes = filterData("slopes", "slope", filteredDataPavements, newSelectedValues);
    let filteredDataWidths = filterData("avgWidthSidewalks", "avg_width_sidewalk", filteredDataSlopes, newSelectedValues);
    let filteredDataAccessibility = filterData("accessibility", "accessibility", filteredDataWidths, newSelectedValues);
    let filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataAccessibility, newSelectedValues);
    let filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    const sortedData = filteredDataDates.sort((a, b) => a.creation_date - b.creation_date);
    onFilteredDataChange(sortedData);
  }

  function filterData(filter, objectType, dataToFilter, newSelectedValues) {
    let filteredData = [];

    if (filter === "accessibility") {
      let notAccessibleData = [];
      const accessibleValues = newSelectedValues.accessibility.values.filter(value => value !== 'Acera no accesible');

      if (accessibleValues.length !== 0) {
        accessibleValues.forEach(value =>
          filteredData = filteredData.concat(dataToFilter.filter(obj => getFilterAccessibility(obj.has_sidewalk, obj.accessible) === value))
        );
      }
    
      if (newSelectedValues.accessibility.values.includes('Acera no accesible')) {
        notAccessibleData = dataToFilter.filter(obj => getFilterAccessibility(obj.has_sidewalk, obj.accessible) === 'Acera no accesible');

        const widthReasons = newSelectedValues.accessibility.notAccessibleReasons.widthReasons;
        const heightReasons = newSelectedValues.accessibility.notAccessibleReasons.heightReasons;
        const crosswalkReasons = newSelectedValues.accessibility.notAccessibleReasons.crosswalkReasons;
      
        if (widthReasons.length !== 0 || heightReasons.length !== 0 || crosswalkReasons.length !== 0) {
          notAccessibleData = notAccessibleData.filter(obj =>
            widthReasons.some(reason => getFilterWidthReasons(obj.not_accessible_reason.width).includes(reason)) ||
            heightReasons.some(reason => getFilterHeightReasons(obj.not_accessible_reason.height).includes(reason)) ||
            crosswalkReasons.some(reason => getFilterCrosswalkReasons(obj.not_accessible_reason.crosswalk).includes(reason))
          );
        }

        filteredData = filteredData.concat(notAccessibleData);
      }

      if (filteredData.length === 0) filteredData = dataToFilter;

    } else {
      if (newSelectedValues[filter].length === 0) {
        filteredData = dataToFilter;
      } else {
        if (objectType === "avg_width_sidewalk") {
          newSelectedValues[filter].forEach(value =>
            filteredData = filteredData.concat(dataToFilter.filter(obj => getFilterWidth(obj[objectType]) === value))
          );
        } else if (objectType === "slope") {
            newSelectedValues[filter].forEach(value =>
              filteredData = filteredData.concat(dataToFilter.filter(obj => getFilterSlope(obj[objectType]) === value))
            );
        } else {
          newSelectedValues[filter].forEach(value =>
            filteredData = filteredData.concat(dataToFilter.filter(obj => obj[objectType] === value))
          );
        }
      }
    }

    return filteredData;
  }

  function calculateDisplayValues(newSelectedValues, updatingOrDeletingObject) {

    let newDisplayValues = new FiltersValues();

    let filteredDataCities;
    let filteredDataRoads;
    let filteredDataPavements;
    let filteredDataSlopes;
    let filteredDataWidths;
    let filteredDataAccessibility;
    let filteredDataReviewed;
    let filteredDataDates;

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataPavements = filterData("pavements", "pavement", filteredDataRoads, newSelectedValues);
    filteredDataSlopes = filterData("slopes", "slope", filteredDataPavements, newSelectedValues);
    filteredDataWidths = filterData("avgWidthSidewalks", "avg_width_sidewalk", filteredDataSlopes, newSelectedValues);
    filteredDataAccessibility = filterData("accessibility", "accessibility", filteredDataWidths, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataAccessibility, newSelectedValues);

    filteredDataReviewed.forEach(value => {
      if (!newDisplayValues.dates.includes(value.date)) newDisplayValues.dates.push(value.date);
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataPavements = filterData("pavements", "pavement", filteredDataRoads, newSelectedValues);
    filteredDataSlopes = filterData("slopes", "slope", filteredDataPavements, newSelectedValues);
    filteredDataWidths = filterData("avgWidthSidewalks", "avg_width_sidewalk", filteredDataSlopes, newSelectedValues);
    filteredDataAccessibility = filterData("accessibility", "accessibility", filteredDataWidths, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataAccessibility, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.reviewed.includes(value.reviewed)) newDisplayValues.reviewed.push(value.reviewed);
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataPavements = filterData("pavements", "pavement", filteredDataRoads, newSelectedValues);
    filteredDataSlopes = filterData("slopes", "slope", filteredDataPavements, newSelectedValues);
    filteredDataWidths = filterData("avgWidthSidewalks", "avg_width_sidewalk", filteredDataSlopes, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataWidths, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.accessibility.values.includes(getFilterAccessibility(value.has_sidewalk, value.accessible))) {
        newDisplayValues.accessibility.values.push(getFilterAccessibility(value.has_sidewalk, value.accessible));
      }

      let reasonsToAdd = getFilterWidthReasons(value.not_accessible_reason.width);

      reasonsToAdd.forEach(reason => {
        if (!newDisplayValues.accessibility.notAccessibleReasons.widthReasons.includes(reason)) {
          newDisplayValues.accessibility.notAccessibleReasons.widthReasons.push(reason);
        }
      });

      reasonsToAdd = getFilterHeightReasons(value.not_accessible_reason.height);

      reasonsToAdd.forEach(reason => {
        if (!newDisplayValues.accessibility.notAccessibleReasons.heightReasons.includes(reason)) {
          newDisplayValues.accessibility.notAccessibleReasons.heightReasons.push(reason);
        }
      });

      reasonsToAdd = getFilterCrosswalkReasons(value.not_accessible_reason.crosswalk);

      reasonsToAdd.forEach(reason => {
        if (!newDisplayValues.accessibility.notAccessibleReasons.crosswalkReasons.includes(reason)) {
          newDisplayValues.accessibility.notAccessibleReasons.crosswalkReasons.push(reason);
        }
      });
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataPavements = filterData("pavements", "pavement", filteredDataRoads, newSelectedValues);
    filteredDataSlopes = filterData("slopes", "slope", filteredDataPavements, newSelectedValues);
    filteredDataAccessibility = filterData("accessibility", "accessibility", filteredDataSlopes, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataAccessibility, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.avgWidthSidewalks.includes(getFilterWidth(value.avg_width_sidewalk))) newDisplayValues.avgWidthSidewalks.push(getFilterWidth(value.avg_width_sidewalk));
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataPavements = filterData("pavements", "pavement", filteredDataRoads, newSelectedValues);
    filteredDataWidths = filterData("avgWidthSidewalks", "avg_width_sidewalk", filteredDataPavements, newSelectedValues);
    filteredDataAccessibility = filterData("accessibility", "accessibility", filteredDataWidths, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataAccessibility, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.slopes.includes(getFilterSlope(value.slope))) newDisplayValues.slopes.push(getFilterSlope(value.slope));
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataRoads = filterData("roads", "road", filteredDataCities, newSelectedValues);
    filteredDataSlopes = filterData("slopes", "slope", filteredDataRoads, newSelectedValues);
    filteredDataWidths = filterData("avgWidthSidewalks", "avg_width_sidewalk", filteredDataSlopes, newSelectedValues);
    filteredDataAccessibility = filterData("accessibility", "accessibility", filteredDataWidths, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataAccessibility, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.pavements.includes(value.pavement)) newDisplayValues.pavements.push(value.pavement);
    });

    filteredDataCities = filterData("cities", "city", data, newSelectedValues);
    filteredDataPavements = filterData("pavements", "pavement", filteredDataCities, newSelectedValues);
    filteredDataSlopes = filterData("slopes", "slope", filteredDataPavements, newSelectedValues);
    filteredDataWidths = filterData("avgWidthSidewalks", "avg_width_sidewalk", filteredDataSlopes, newSelectedValues);
    filteredDataAccessibility = filterData("accessibility", "accessibility", filteredDataWidths, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataAccessibility, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.roads.includes(value.road)) newDisplayValues.roads.push(value.road);
    });

    filteredDataRoads = filterData("roads", "road", data, newSelectedValues);
    filteredDataPavements = filterData("pavements", "pavement", filteredDataRoads, newSelectedValues);
    filteredDataSlopes = filterData("slopes", "slope", filteredDataPavements, newSelectedValues);
    filteredDataWidths = filterData("avgWidthSidewalks", "avg_width_sidewalk", filteredDataSlopes, newSelectedValues);
    filteredDataAccessibility = filterData("accessibility", "accessibility", filteredDataWidths, newSelectedValues);
    filteredDataReviewed = filterData("reviewed", "reviewed", filteredDataAccessibility, newSelectedValues);
    filteredDataDates = filterData("dates", "date", filteredDataReviewed, newSelectedValues);

    filteredDataDates.forEach(value => {
      if (!newDisplayValues.cities.includes(value.city)) newDisplayValues.cities.push(value.city);
    });


    if (!updatingOrDeletingObject) {
      switch (firstSelectedFilter) {
        case "cities":
          newDisplayValues.cities = displayValues.cities;
          break;
        case "roads":
          newDisplayValues.roads = displayValues.roads;
          break;
        case "pavements":
          newDisplayValues.pavements = displayValues.pavements;
          break;
        case "slopes":
          newDisplayValues.slopes = displayValues.slopes;
          break;
        case "avgWidthSidewalks":
          newDisplayValues.avgWidthSidewalks = displayValues.avgWidthSidewalks;
          break;
        case "accessibility":
          newDisplayValues.accessibility = displayValues.accessibility;
          break;
        case "reviewed":
          newDisplayValues.reviewed = displayValues.reviewed;
          break;
        case "dates":
          newDisplayValues.dates = displayValues.dates;
          break;
        /*case "undefined":
          console.log("undefined: " + firstSelectedFilter);
          newDisplayValues = uniqueValues;
          break;
        default:
          console.log(uniqueValues) // because its the first iteration and its null
          //newDisplayValues = uniqueValues; 
          console.log(firstSelectedFilter);
          break;*/
      }
    }

    newDisplayValues.cities.sort();
    newDisplayValues.roads.sort();
    newDisplayValues.pavements.sort();

    let sortOrder = {
      '< 2%': 0,
      '2% - 5%': 1,
      '5% - 10%': 2,
      '10% - 15%': 3,
      '> 15%': 4,
    };
    newDisplayValues.slopes.sort((a, b) => {
      return sortOrder[a] - sortOrder[b];
    });

    sortOrder = {
      '< 1.8 m': 0,
      '1.8 m - 2.5 m': 1,
      '> 2.5 m': 2,
    };
    newDisplayValues.avgWidthSidewalks.sort((a, b) => {
      return sortOrder[a] - sortOrder[b];
    });

    newDisplayValues.accessibility.values.sort();
    newDisplayValues.accessibility.notAccessibleReasons.widthReasons.sort();
    newDisplayValues.accessibility.notAccessibleReasons.heightReasons.sort();
    newDisplayValues.accessibility.notAccessibleReasons.crosswalkReasons.sort();

    newDisplayValues.reviewed.sort();
    newDisplayValues.dates.sort();

    setDisplayValues(newDisplayValues);
  }

  function updateAccessibilityPointFromFilters() {
    const newSelectedValues = selectedValues;
    const newCheckboxState = [...checkboxState];

    let indexOfValue;
    let index;
    let id;

    filters.forEach(filter => {
      let accessibilityType;
      switch (filter) {
        case "cities":
          accessibilityType = "city";
          break;
        case "roads":
          accessibilityType = "road";
          break;
        case "pavements":
          accessibilityType = "pavement";
          break;
        case "slopes":
          accessibilityType = "slope";
          break;
        case "avgWidthSidewalks":
          accessibilityType = "avg_width_sidewalk";
          break;
        case "accessibility":
          accessibilityType = "accessibility";
          break;
        case "reviewed":
          accessibilityType = "reviewed";
          break;
        case "dates":
          accessibilityType = "date";
          break;
        default:
          accessibilityType = "date";
          break;
      }

      if (anyValueSelected[filter] && updatedAccessibility.accessibilityPoint[accessibilityType] !== updatedAccessibility.newValues[accessibilityType]) {
        indexOfValue = selectedValues[filter].indexOf(updatedAccessibility.accessibilityPoint[accessibilityType]);

        let someFilteredDataLength;
        if (accessibilityType === "avg_width_sidewalk") {
          someFilteredDataLength = data.filter(obj =>
            getFilterWidth(obj[accessibilityType]) === getFilterWidth(updatedAccessibility.accessibilityPoint[accessibilityType])).length;
        } else if (accessibilityType === "slope") {
          someFilteredDataLength = data.filter(obj =>
            getFilterSlope(obj[accessibilityType]) === getFilterSlope(updatedAccessibility.accessibilityPoint[accessibilityType])).length;
        } else if (accessibilityType === "accessibility") {
          someFilteredDataLength = data.filter(obj =>
            getFilterAccessibility(obj.has_sidewalk, obj.accessibe) === getFilterAccessibility(updatedAccessibility.accessibilityPoint.has_sidewalk, updatedAccessibility.accessibilityPoint.accessibe)).length;
        } else {
          someFilteredDataLength = data.filter(obj =>
            obj[accessibilityType] === updatedAccessibility.accessibilityPoint[accessibilityType]).length;
        }

        if (indexOfValue !== -1 && someFilteredDataLength === 0) {
          uniqueValues[filter].forEach((value, index) => {
            if (value === updatedAccessibility.accessibilityPoint[accessibilityType]) id = "checkbox-" + filter + "-" + index;
          })

          index = checkboxState.findIndex((e) => e.id === id);
          newCheckboxState[index] = { id, checked: false };
          setCheckboxState(newCheckboxState);

          newSelectedValues[filter].splice(indexOfValue, 1);
          if (newSelectedValues[filter].length === 0) {
            const newAnyValueSelected = { ...anyValueSelected, [filter]: false };
            setAnyValueSelected(newAnyValueSelected);
            if (firstSelectedFilter === filter) setFirstSelectedFilter(undefined);
          }
        }
      }
    });

    calculateUniqueValues(newCheckboxState);
    updateFilteredData(newSelectedValues);
    setSelectedValues(newSelectedValues);
    calculateDisplayValues(newSelectedValues, true);
  }

  function deleteAccessibilityPointFromFilters() {
    const newSelectedValues = selectedValues;
    const newCheckboxState = [...checkboxState];

    let indexOfValue;
    let index;
    let id;

    filters.forEach(filter => {
      let accessibilityType;
      switch (filter) {
        case "cities":
          accessibilityType = "city";
          break;
        case "roads":
          accessibilityType = "road";
          break;
        case "pavements":
          accessibilityType = "pavement";
          break;
        case "slopes":
          accessibilityType = "slope";
          break;
        case "avgWidthSidewalks":
          accessibilityType = "avg_width_sidewalk";
          break;
        case "accessibility":
          accessibilityType = "accessibility";
          break;
        case "reviewed":
          accessibilityType = "reviewed";
          break;
        case "dates":
          accessibilityType = "date";
          break;
        default:
          accessibilityType = "date";
          break;
      }

      if (anyValueSelected[filter]) {
        indexOfValue = selectedValues[filter].indexOf(deletedAccessibility[accessibilityType]);

        let someFilteredDataLength;
        if (accessibilityType === "avg_width_sidewalk") {
          someFilteredDataLength = data.filter(obj =>
            getFilterWidth(obj[accessibilityType]) === getFilterWidth(deletedAccessibility[accessibilityType])).length;
        } else if (accessibilityType === "slope") {
          someFilteredDataLength = data.filter(obj =>
            getFilterSlope(obj[accessibilityType])=== getFilterSlope(deletedAccessibility[accessibilityType])).length;
        } else if (accessibilityType === "accessibility") {
          someFilteredDataLength = data.filter(obj =>
            getFilterAccessibility(obj.has_sidewalk, obj.accessibe) === getFilterAccessibility(deletedAccessibility.has_sidewalk, updatedAccessibility.accessibe)).length;
        } else {
          someFilteredDataLength = data.filter(obj =>
            obj[accessibilityType] === deletedAccessibility[accessibilityType]).length;
        }

        if (indexOfValue !== -1 && someFilteredDataLength === 0) {
          uniqueValues[filter].forEach((value, index) => {
            if (value === deletedAccessibility[accessibilityType]) id = "checkbox-" + filter + "-" + index;
          })

          index = checkboxState.findIndex((e) => e.id === id);
          newCheckboxState[index] = { id, checked: false };
          setCheckboxState(newCheckboxState);

          newSelectedValues[filter].splice(indexOfValue, 1);
          if (newSelectedValues[filter].length === 0) {
            const newAnyValueSelected = { ...anyValueSelected, [filter]: false };
            setAnyValueSelected(newAnyValueSelected);
            if (firstSelectedFilter === filter) setFirstSelectedFilter(undefined);
          }
        }
      }
    });

    calculateUniqueValues(newCheckboxState);
    updateFilteredData(newSelectedValues);
    setSelectedValues(newSelectedValues);
    calculateDisplayValues(newSelectedValues, true);
  }
}

function getFilterSlope(slope) {
  if (slope < 2) {
    return '< 2%';
  } else if (slope >= 2 && slope < 5) {
    return '2% - 5%';
  } else if (slope >= 5 && slope < 10) {
    return '5% - 10%';
  } else if (slope >= 10 && slope <= 15) {
    return '10% - 15%';
  } else {
    return '> 15%';
  }
}

function getFilterWidth(width) {
  if (width < 1.8) {
    return '< 1.8 m';
  } else if (width >= 1.8 && width <= 2.5) {
    return '1.8 m - 2.5 m';
  } else {
    return '> 2.5 m';
  }
}

function getFilterAccessibility(hasSidewalk, isAccessible) {
  if (!hasSidewalk) return 'No hay acera';
  if (isAccessible) return 'Acera accesible';
  return 'Acera no accesible';
}

function getFilterWidthReasons(widthReasons) {
  const reasons = [];

  if (widthReasons.width) reasons.push('Anchura general insuficiente');
  if (widthReasons.fence) reasons.push('Por valla');
  if (widthReasons.barrier) reasons.push('Por barrera');
  if (widthReasons.vegetation) reasons.push('Por vegetación');
  if (widthReasons.billboard) reasons.push('Por cartel publicitario');
  if (widthReasons.hydrant) reasons.push('Por hidrante');
  if (widthReasons.junction_box) reasons.push('Por caja eléctrica');
  if (widthReasons.phone) reasons.push('Por cabina telefónica');
  if (widthReasons.light) reasons.push('Por farola');
  if (widthReasons.pole) reasons.push('Por poste');
  if (widthReasons.utility_pole) reasons.push('Por poste de servicios');

  return reasons;
}

function getFilterHeightReasons(heightReasons) {
  const reasons = [];

  if (heightReasons.sign) reasons.push('Por señal');
  if (heightReasons.traffic_light) reasons.push('Por semáforo');

  return reasons;
}

function getFilterCrosswalkReasons(crosswalkReasons) {
  const reasons = [];

  if (crosswalkReasons.lowered) reasons.push('Sin rebaje');
  if (crosswalkReasons.duropanot) reasons.push('Sin duropanot');

  return reasons;
}
