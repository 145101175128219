import React, { useState, Fragment } from 'react';

import L from 'leaflet';

import { Polyline } from 'react-leaflet/Polyline';

//import AccessibilityMarkerPopup from './AccessibilityMarkerPopup';

export default function AccessibilityMarkers(props) {

  const { filteredAccessibility, popupOpenAccessibilityPointId, onPopupOpenAccessibilityPointIdChange,
    openImages, imagesOpenId, mapFilterSelectionOn, selectMapFilterData } = props;

  const [hoveringId, setHoveringId] = useState(0);

  const lines = [];

  function createSegmentLines(segment, prevSegmentLastPosition) {
    const color = getColor(currentSegment[0].result);

    segment.forEach((value, index) => {
      let positions;
      if (index === 0) {
        let position = L.latLng([value.latitude, value.longitude]);
        let distance = position.distanceTo(prevSegmentLastPosition);
        if (distance < 0.1) {
          positions = [position, prevSegmentLastPosition];
        } else {
          positions = [position, [value.latitude - 0.000001, value.longitude - 0.000001]];
        }
      } else {
        positions = [[value.latitude, value.longitude], [segment[index - 1].latitude, segment[index - 1].longitude]];
      }

      let weight = 6;
      if (popupOpenAccessibilityPointId === value.id || hoveringId === value.id || imagesOpenId === value.id) {
        weight = 15;
      }

      lines.push(
        <Polyline
          key={`line-${value.id}`}
          pathOptions={{ weight: weight, color, smoothFactor: 1 }}
          positions={positions}
          eventHandlers={{
            click: (e) => {
              openImages(value.id, value.dbCity, false, value.result, value.road);
              onPopupOpenAccessibilityPointIdChange(value.id);
              if (mapFilterSelectionOn) selectMapFilterData(value);
              e.view.L.DomEvent.stopPropagation(e); // do this because of bug on leaflet clicks

            },
            mouseover: () => { setHoveringId(value.id) },
            mouseout: () => { setHoveringId(0) }
          }}
        >
        </Polyline>
      );
    });
  }

  var currentSegment = [];
  var prevResult = 'No hay acera';
  var prevTimestamp = 0;
  var timeDiff;
  var prevPosition = L.latLng([0, 0]);
  var distance;
  var prevSegmentLastPosition = L.latLng([0, 0]);

  filteredAccessibility.forEach((value) => {
    timeDiff = (value.creation_date - prevTimestamp);

    let position = L.latLng([value.latitude, value.longitude]);
    distance = position.distanceTo(prevPosition);

    if (prevResult !== value.result || timeDiff < 0 || timeDiff > 3000 || distance > 0.1) {
      if (currentSegment.length > 0) {
        createSegmentLines(currentSegment, prevSegmentLastPosition);
        currentSegment = [];
        prevSegmentLastPosition = prevPosition;
      }
    }

    currentSegment.push(value);

    prevResult = value.result;
    prevTimestamp = value.creation_date;
    prevPosition = position;
  });

  if (currentSegment.length > 0) {
    createSegmentLines(currentSegment, prevSegmentLastPosition);
  }


  return (
    <Fragment>
      {lines}
    </Fragment>
  );
}

function getColor(result) {
  switch (result) {
    case 'Acera accesible':
      return 'green';
    case 'No hay acera':
      return 'grey';
    default:
      return 'red';
  }
}
