import React, { useState, useEffect, useCallback, Fragment } from 'react';

import { useAuth0 } from "@auth0/auth0-react";

import useMediaQuery from '@mui/material/useMediaQuery';

import ObjectsFilters from './filters/ObjectsFilters';
import AccessibilityFilters from './filters/AccessibilityFilters';
import ToggleObjectsAccessibility from './ToggleObjectsAccessibility';
import Map from './map/Map';
import ImagesCard from './ImagesCard';
import ObjectsTable from './table/ObjectsTable';
import AccessibilityTable from './table/AccessibilityTable';

import DialogEditObject from './dialogs/DialogEditObject';
import DialogDeleteObject from './dialogs/DialogDeleteObject';
import DialogEditSelectedObjects from './dialogs/DialogEditSelectedObjects';
import DialogDeleteSelectedObjects from './dialogs/DialogDeleteSelectedObjects';
//import DialogEditAccessibilitySegment from './dialogs/DialogEditAccessibilitySegment';
import DialogEditAccessibilityPoint from './dialogs/DialogEditAccessibilityPoint';
import DialogDeleteAccessibilityPoint from './dialogs/DialogDeleteAccessibilityPoint';
import DialogEditSelectedAccessibility from './dialogs/DialogEditSelectedAccessibility';
import DialogDeleteSelectedAccessibility from './dialogs/DialogDeleteSelectedAccessibility';

import {
  getObjectsFromDB,
  getAccessibilityFromDB,
  insertObjectFromDB,
  updateObjectFromDB,
  updateAccessibilityPointFromDB,
  updateObjectsFromDB,
  updateAccessibilityFromDB,
  deleteObjectFromDB,
  deleteAccessibilityPointFromDB,
  deleteObjectsFromDB,
  deleteAccessibilityFromDB,
  getImagesFromDB,
} from '../../utils/api';

export default function DigitalInventory() {

  const editModeOn = useMediaQuery('(min-width:600px)');

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [selectedMode, setSelectedMode] = useState('objects');

  const [objects, setObjects] = useState([]);
  const [filteredObjects, setFilteredObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [hoveringObjectId, setHoveringObjectId] = useState(0);
  const [clickedObjectId, setClickedObjectId] = useState(0);
  const [updatedObject, setUpdatedObject] = useState(undefined); // check the effects on filters and states as well
  const [deletedObject, setDeletedObject] = useState(undefined);

  const [accessibility, setAccessibility] = useState([]);
  const [filteredAccessibility, setFilteredAccessibility] = useState([]);
  const [selectedAccessibilityPoint, setSelectedAccessibilityPoint] = useState({});
  //const [selectedAccessibilitySegment, setSelectedAccessibilitySegment] = useState([{}]);
  const [selectedAccessibility, setSelectedAccessibility] = useState([]);
  const [clickedAccessibilityPointId, setClickedAccessibilityPointId] = useState(0);
  const [updatedAccessibility, setUpdatedAccessibility] = useState(undefined); // check the effects on filters and states as well
  const [deletedAccessibility, setDeletedAccessibility] = useState(undefined);

  const [mapPosition, setMapPosition] = useState([40.42, -3.7]);

  const [imagesExpanded, setImagesExpanded] = useState(true);
  const [imagesExpandedOnce, setImagesExpandedOnce] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesOpenId, setImagesOpenId] = useState(0);

  const [clickedObjectType, setClickedObjectType] = useState(undefined);
  const [clickedObjectRoad, setClickedObjectRoad] = useState(undefined);

  const [mapFilterSelectionOn, setMapFilterSelectionOn] = useState(false);
  const [mapFilteredData, setMapFilteredData] = useState([]);

  const [dialogEditObjectOpen, setDialogEditObjectOpen] = useState(false);
  const [dialogDeleteObjectOpen, setDialogDeleteObjectOpen] = useState(false);
  const [dialogEditSelectedObjecsOpen, setDialogEditSelectedObjectsOpen] = useState(false);
  const [dialogDeleteSelectedObjecsOpen, setDialogDeleteSelectedObjectsOpen] = useState(false);

  //const [dialogEditAccessibilitySegmentOpen, setDialogEditAccessibilitySegmentOpen] = useState(false);
  const [dialogEditAccessibilityPointOpen, setDialogEditAccessibilityPointOpen] = useState(false);
  const [dialogDeleteAccessibilityPointOpen, setDialogDeleteAccessibilityPointOpen] = useState(false);
  const [dialogEditSelectedAccessibilityOpen, setDialogEditSelectedAccessibilityOpen] = useState(false);
  const [dialogDeleteSelectedAccessibilityOpen, setDialogDeleteSelectedAccessibilityOpen] = useState(false);

  const [dialogEditObjectOpenCalled, setDialogEditObjectOpenCalled] = useState(false);
  const [dialogDeleteObjectOpenCalled, setDialogDeleteObjectOpenCalled] = useState(false);
  //const [dialogEditAccessibilitySegmentOpenCalled, setDialogEditAccessibilitySegmentOpenCalled] = useState(false);
  const [dialogEditAccessibilityPointOpenCalled, setDialogEditAccessibilityPointOpenCalled] = useState(false);
  const [dialogDeleteAccessibilityPointOpenCalled, setDialogDeleteAccessibilityPointOpenCalled] = useState(false);
  const [dialogEditSelectedObjecsOpenCalled, setDialogEditSelectedObjectsOpenCalled] = useState(false);
  const [dialogDeleteSelectedObjecsOpenCalled, setDialogDeleteSelectedObjectsOpenCalled] = useState(false);
  const [dialogEditSelectedAccessibilityOpenCalled, setDialogEditSelectedAccessibilityOpenCalled] = useState(false);
  const [dialogDeleteSelectedAccessibilityOpenCalled, setDialogDeleteSelectedAccessibilityOpenCalled] = useState(false);

  useEffect(() => {
    if (selectedObject) {
      if (dialogEditObjectOpenCalled) {
        setDialogEditObjectOpen(true);
        setDialogEditObjectOpenCalled(false);
      }
      if (dialogDeleteObjectOpenCalled) {
        setDialogDeleteObjectOpen(true);
        setDialogDeleteObjectOpenCalled(false);
      }
    }
  }, [selectedObject, dialogEditObjectOpenCalled, dialogDeleteObjectOpenCalled]);

  /*useEffect(() => {
    if (selectedAccessibilitySegment.length !== 0 && dialogEditAccessibilitySegmentOpenCalled) {
      setDialogEditAccessibilitySegmentOpen(true);
      setDialogEditAccessibilitySegmentOpenCalled(false);
    }
  }, [selectedAccessibilitySegment, dialogEditAccessibilitySegmentOpenCalled]);*/

  useEffect(() => {
    if (selectedAccessibilityPoint) {
      if (dialogEditAccessibilityPointOpenCalled) {
        setDialogEditAccessibilityPointOpen(true);
        setDialogEditAccessibilityPointOpenCalled(false);
      }
      if (dialogDeleteAccessibilityPointOpenCalled) {
        setDialogDeleteAccessibilityPointOpen(true);
        setDialogDeleteAccessibilityPointOpenCalled(false);
      }
    }
  }, [selectedAccessibilityPoint, dialogEditAccessibilityPointOpenCalled, dialogDeleteAccessibilityPointOpenCalled]);

  useEffect(() => {
    if (selectedObjects.length > 0) {
      if (dialogEditSelectedObjecsOpenCalled) {
        setDialogEditSelectedObjectsOpen(true);
        setDialogEditSelectedObjectsOpenCalled(false);
      }
      if (dialogDeleteSelectedObjecsOpenCalled) {
        setDialogDeleteSelectedObjectsOpen(true);
        setDialogDeleteSelectedObjectsOpenCalled(false);
      }
    }
  }, [selectedObjects, dialogEditSelectedObjecsOpenCalled, dialogDeleteSelectedObjecsOpenCalled]);

  useEffect(() => {
    if (selectedAccessibility.length > 0) {
      if (dialogEditSelectedAccessibilityOpenCalled) {
        setDialogEditSelectedAccessibilityOpen(true);
        setDialogEditSelectedAccessibilityOpenCalled(false);
      }
      if (dialogDeleteSelectedAccessibilityOpenCalled) {
        setDialogDeleteSelectedAccessibilityOpen(true);
        setDialogDeleteSelectedAccessibilityOpenCalled(false);
      }
    }
  }, [selectedAccessibility, dialogEditSelectedAccessibilityOpenCalled, dialogDeleteSelectedAccessibilityOpenCalled]);

  useEffect(() => {
    async function getData() {
      if (!isLoading) {
        let getObjectsData = await getObjectsFromDB(isAuthenticated, getAccessTokenSilently);
        const sortedObjects = getObjectsData.sort((a, b) => a.city.localeCompare(b.city));
        setObjects(sortedObjects);
        setFilteredObjects(sortedObjects);

        let getAccessibilityData = await getAccessibilityFromDB(isAuthenticated, getAccessTokenSilently);
        const sortedAccessibility = getAccessibilityData.sort((a, b) => a.creation_date - b.creation_date);
        sortedAccessibility.forEach(item => { item.result = getSidewalkAccessibility(item.has_sidewalk, item.accessible, item.not_accessible_reason) })
        setAccessibility(sortedAccessibility);
        setFilteredAccessibility(sortedAccessibility);
      }
    }

    getData();
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  const insertObject = useCallback(async (newValues) => {
    const newObjectId = await insertObjectFromDB(isAuthenticated, getAccessTokenSilently, newValues);

    if (newObjectId !== null) {
      const newObject = {
        id: newObjectId,
        ...newValues
      }

      const newObjects = [...objects, newObject];
      setObjects(newObjects);

      if (mapFilterSelectionOn) {
        const newMapFilteredData = [...mapFilteredData, newObject];
        setMapFilteredData(newMapFilteredData);
      }
    } else {
      // handle the error
    }
  }, [objects, isAuthenticated, getAccessTokenSilently, mapFilterSelectionOn, mapFilteredData]);

  const updateObject = useCallback(async (object, newValues) => {
    const objectUpdated = await updateObjectFromDB(isAuthenticated, getAccessTokenSilently, newValues, object.dbCity);

    if (objectUpdated) {
      const updatedObjects = objects.map(item => {
        if (item.id === newValues.id) {
          return {
            ...item,
            ...newValues
          };
        }
        return item;
      });
      setObjects(updatedObjects);

      const updatedSelectedObjects = selectedObjects.map(item => {
        if (item.id === newValues.id) {
          return {
            ...item,
            ...newValues
          };
        }
        return item;
      });
      setSelectedObjects(updatedSelectedObjects);

      if (mapFilterSelectionOn) {
        const updatedMapFilteredData = mapFilteredData.map(item => {
          if (item.id === newValues.id) {
            return {
              ...item,
              ...newValues
            };
          }
          return item;
        });
        setMapFilteredData(updatedMapFilteredData);
      }

      setUpdatedObject({ object: object, newValues: newValues });
    } else {
      // handle the error
    }
  }, [objects, selectedObjects, isAuthenticated, getAccessTokenSilently, mapFilterSelectionOn, mapFilteredData]);

  const updateAccessibilityPoint = useCallback(async (accessibilityPoint, newValues) => {
    const accessibilityUpdated = await updateAccessibilityPointFromDB(isAuthenticated, getAccessTokenSilently, newValues, accessibilityPoint.dbCity);

    newValues.result = getSidewalkAccessibility(newValues.has_sidewalk, newValues.accessible, newValues.not_accessible_reason);

    if (accessibilityUpdated) {
      const newAccessibility = accessibility.map(item => {
        if (item.id === newValues.id) {
          return {
            ...item,
            ...newValues
          };
        }
        return item;
      });
      setAccessibility(newAccessibility);

      const updatedSelectedAccessibility = selectedAccessibility.map(item => {
        if (item.id === newValues.id) {
          return {
            ...item,
            ...newValues
          };
        }
        return item;
      });
      setSelectedAccessibility(updatedSelectedAccessibility);

      if (mapFilterSelectionOn) {
        const updatedMapFilteredData = mapFilteredData.map(item => {
          if (item.id === newValues.id) {
            return {
              ...item,
              ...newValues
            };
          }
          return item;
        });
        setMapFilteredData(updatedMapFilteredData);
      }

      setUpdatedAccessibility({ accessibilityPoint: accessibilityPoint, newValues: newValues })
    } else {
      // handle the error
    }
  }, [accessibility, selectedAccessibility, isAuthenticated, getAccessTokenSilently, mapFilterSelectionOn, mapFilteredData]);

  /*const updateAccessibilitySegment = useCallback(async (accessibilitySegment, newPavement, newAvgWidthSidewalk, newWidthOk, newCrosswalkOk, newHeightOk) => {

    let valuesToUpdate = { // check this we might not need to add the ones that are false, why do we not have lat and lng?
      city: { modified: false, value: '' },
      road: { modified: false, value: '' },
      pavement: { modified: true, value: newPavement },
      slope: { modified: false, value: '' },
      avg_width_sidewalk: { modified: true, value: newAvgWidthSidewalk },
      width: { modified: true, value: newWidthOk },
      crosswalk_ok: { modified: true, value: newCrosswalkOk },
      height_ok: { modified: true, value: newHeightOk },
      date: { modified: false, value: '' },
      notes: { modified: false, value: '' },
      reviewed: { modified: false, value: '' },
    }

    let ids = ""
    accessibilitySegment.forEach(obj => ids = ids + obj.id + '_');
    ids = ids.slice(0, -1);

    const idsToEdit = ids.split('_');

    const idBatches = [];
    for (let i = 0; i < idsToEdit.length; i += 1000) {
      idBatches.push(idsToEdit.slice(i, i + 1000));
    }

    let updateData = true;

    for (const idBatch of idBatches) {
      const accessibilityUpdated = await updateAccessibilityFromDB(isAuthenticated, getAccessTokenSilently, idBatch, valuesToUpdate);
      if (!accessibilityUpdated) updateData = false;
    }

    if (updateData) {
      const updatedAccessibility = accessibility.map(item => {
        if (idsToEdit.includes(item.id)) {
          const newValues = {
            id: item.id,
            latitude: item.latitude,
            longitude: item.longitude,
            city: item.city,
            road: item.road,
            pavement: newPavement,
            slope: item.slope,
            avg_width_sidewalk: newAvgWidthSidewalk,
            height_obstacles: item.height_obstacles,
            width: newWidthOk,
            crosswalk_ok: newCrosswalkOk,
            height_ok: newHeightOk,
            result: getSidewalkAccessibility(newWidthOk, newCrosswalkOk, newHeightOk),
            date: item.date,
            creation_date: item.creation_date,
            notes: item.notes,
            image: item.image,
            reviewed: item.reviewed,
          };
          return {
            ...item,
            ...newValues
          };
        }
        return item
      });
      setAccessibility(updatedAccessibility);

      const updatedSelectedAccessibility = selectedAccessibility.map(item => {
        if (idsToEdit.includes(item.id)) {
          const newValues = {
            id: item.id,
            latitude: item.latitude,
            longitude: item.longitude,
            city: item.city,
            road: item.road,
            pavement: newPavement,
            slope: item.slope,
            avg_width_sidewalk: newAvgWidthSidewalk,
            height_obstacles: item.height_obstacles,
            width: newWidthOk,
            crosswalk_ok: newCrosswalkOk,
            height_ok: newHeightOk,
            result: getSidewalkAccessibility(newWidthOk, newCrosswalkOk, newHeightOk),
            date: item.date,
            creation_date: item.creation_date,
            notes: item.notes,
            image: item.image,
            reviewed: item.reviewed,
          };
          return {
            ...item,
            ...newValues
          };
        }
        return item
      });
      setSelectedAccessibility(updatedSelectedAccessibility);

      if (mapFilterSelectionOn) {
        const updatedMapFilteredData = mapFilteredData.map(item => {
          if (idsToEdit.includes(item.id)) {
            const newValues = {
              id: item.id,
              latitude: item.latitude,
              longitude: item.longitude,
              city: item.city,
              road: item.road,
              pavement: newPavement,
              slope: item.slope,
              avg_width_sidewalk: newAvgWidthSidewalk,
              height_obstacles: item.height_obstacles,
              width: newWidthOk,
              crosswalk_ok: newCrosswalkOk,
              height_ok: newHeightOk,
              result: getSidewalkAccessibility(newWidthOk, newCrosswalkOk, newHeightOk),
              date: item.date,
              creation_date: item.creation_date,
              notes: item.notes,
              image: item.image,
              reviewed: item.reviewed,
            };
            return {
              ...item,
              ...newValues
            };
          }
          return item
        });
        setMapFilteredData(updatedMapFilteredData);
      }
    } else {
      // handle the error
    }
  }, [accessibility, selectedAccessibility, isAuthenticated, getAccessTokenSilently, mapFilterSelectionOn, mapFilteredData]);*/

  const updateSelectedObjects = useCallback(async (valuesToUpdate) => {
    const objectsByDbCity = selectedObjects.reduce((acc, obj) => {
      if (!acc[obj.dbCity]) {
        acc[obj.dbCity] = [];
      }
      acc[obj.dbCity].push(obj);
      return acc;
    }, {});

    let updatedObjects = objects;
    let updatedSelectedObjects = selectedObjects;
    let updatedMapFilteredData = mapFilteredData;

    for (const [dbCity, objectsToEdit] of Object.entries(objectsByDbCity)) {
      let ids = ""
      objectsToEdit.forEach(obj => ids = ids + obj.id + '_');
      ids = ids.slice(0, -1);

      const idsToEdit = ids.split('_');

      const idBatches = [];
      for (let i = 0; i < idsToEdit.length; i += 1000) {
        idBatches.push(idsToEdit.slice(i, i + 1000));
      }

      let updateData = true;

      for (const idBatch of idBatches) {
        const objectsUpdated = await updateObjectsFromDB(isAuthenticated, getAccessTokenSilently, idBatch, valuesToUpdate, dbCity);
        if (!objectsUpdated) updateData = false;
      }

      if (updateData) {
        updatedObjects = updatedObjects.map(item => {
          if (idsToEdit.includes(item.id)) {
            const newValues = {
              id: item.id,
              latitude: item.latitude,
              longitude: item.longitude,
              city: valuesToUpdate.city.modified ? valuesToUpdate.city.value : item.city,
              road: valuesToUpdate.road.modified ? valuesToUpdate.road.value : item.road,
              type: valuesToUpdate.type.modified ? valuesToUpdate.type.value : item.type,
              subtype: valuesToUpdate.subtype.modified ? valuesToUpdate.subtype.value : item.subtype,
              items: valuesToUpdate.items.modified ? valuesToUpdate.items.value : item.items,
              date: valuesToUpdate.date.modified ? valuesToUpdate.date.value : item.date,
              creation_date: item.creation_date,
              notes: valuesToUpdate.notes.modified ? valuesToUpdate.notes.value : item.notes,
              images: item.images,
              reviewed: valuesToUpdate.reviewed.modified ? valuesToUpdate.reviewed.value : item.reviewed,
            };
            return {
              ...item,
              ...newValues
            };
          }
          return item
        });

        updatedSelectedObjects = updatedSelectedObjects.map(item => {
          if (idsToEdit.includes(item.id)) {
            const newValues = {
              id: item.id,
              latitude: item.latitude,
              longitude: item.longitude,
              city: valuesToUpdate.city.modified ? valuesToUpdate.city.value : item.city,
              road: valuesToUpdate.road.modified ? valuesToUpdate.road.value : item.road,
              type: valuesToUpdate.type.modified ? valuesToUpdate.type.value : item.type,
              subtype: valuesToUpdate.subtype.modified ? valuesToUpdate.subtype.value : item.subtype,
              items: valuesToUpdate.items.modified ? valuesToUpdate.items.value : item.items,
              date: valuesToUpdate.date.modified ? valuesToUpdate.date.value : item.date,
              creation_date: item.creation_date,
              notes: valuesToUpdate.notes.modified ? valuesToUpdate.notes.value : item.notes,
              images: item.images,
              reviewed: valuesToUpdate.reviewed.modified ? valuesToUpdate.reviewed.value : item.reviewed,
            };
            return {
              ...item,
              ...newValues
            };
          }
          return item
        });

        if (mapFilterSelectionOn) {
          updatedMapFilteredData = updatedMapFilteredData.map(item => {
            if (idsToEdit.includes(item.id)) {
              const newValues = {
                id: item.id,
                latitude: item.latitude,
                longitude: item.longitude,
                city: valuesToUpdate.city.modified ? valuesToUpdate.city.value : item.city,
                road: valuesToUpdate.road.modified ? valuesToUpdate.road.value : item.road,
                type: valuesToUpdate.type.modified ? valuesToUpdate.type.value : item.type,
                subtype: valuesToUpdate.subtype.modified ? valuesToUpdate.subtype.value : item.subtype,
                items: valuesToUpdate.items.modified ? valuesToUpdate.items.value : item.items,
                date: valuesToUpdate.date.modified ? valuesToUpdate.date.value : item.date,
                creation_date: item.creation_date,
                notes: valuesToUpdate.notes.modified ? valuesToUpdate.notes.value : item.notes,
                images: item.images,
                reviewed: valuesToUpdate.reviewed.modified ? valuesToUpdate.reviewed.value : item.reviewed,
              };
              return {
                ...item,
                ...newValues
              };
            }
            return item
          });
          setMapFilteredData(updatedMapFilteredData);
        }
      } else {
        // handle the error
      }
    }

    setObjects(updatedObjects);
    setSelectedObjects(updatedSelectedObjects);
    if (mapFilterSelectionOn) setMapFilteredData(updatedMapFilteredData);

  }, [selectedObjects, objects, isAuthenticated, getAccessTokenSilently, mapFilterSelectionOn, mapFilteredData]);

  const updateSelectedAccessibility = useCallback(async (valuesToUpdate) => {
    const accByDbCity = selectedAccessibility.reduce((acc, accPoint) => {
      if (!acc[accPoint.dbCity]) {
        acc[accPoint.dbCity] = [];
      }
      acc[accPoint.dbCity].push(accPoint);
      return acc;
    }, {});

    let updatedAccessibility = accessibility;
    let updatedSelectedAccessibility = selectedAccessibility;
    let updatedMapFilteredData = mapFilteredData;

    for (const [dbCity, accessibilityToEdit] of Object.entries(accByDbCity)) {
      let ids = ""
      accessibilityToEdit.forEach(obj => ids = ids + obj.id + '_');
      ids = ids.slice(0, -1);

      const idsToEdit = ids.split('_');

      const idBatches = [];
      for (let i = 0; i < idsToEdit.length; i += 1000) {
        idBatches.push(idsToEdit.slice(i, i + 1000));
      }

      let updateData = true;

      for (const idBatch of idBatches) {
        const accessibilityUpdated = await updateAccessibilityFromDB(isAuthenticated, getAccessTokenSilently, idBatch, valuesToUpdate, dbCity);
        if (!accessibilityUpdated) updateData = false;
      }

      if (updateData) {
        updatedAccessibility = updatedAccessibility.map(item => {
          if (idsToEdit.includes(item.id)) {
            const newValues = {
              id: item.id,
              latitude: item.latitude,
              longitude: item.longitude,
              city: valuesToUpdate.city.modified ? valuesToUpdate.city.value : item.city,
              road: valuesToUpdate.road.modified ? valuesToUpdate.road.value : item.road,
              pavement: valuesToUpdate.pavement.modified ? valuesToUpdate.pavement.value : item.pavement,
              slope: valuesToUpdate.slope.modified ? valuesToUpdate.slope.value : item.slope,
              avg_width_sidewalk: valuesToUpdate.avg_width_sidewalk.modified ? valuesToUpdate.avg_width_sidewalk.value : item.avg_width_sidewalk,
              has_sidewalk: valuesToUpdate.has_sidewalk.modified ? valuesToUpdate.has_sidewalk.value : item.has_sidewalk,
              accessible: valuesToUpdate.accessible.modified ? valuesToUpdate.accessible.value : item.accessible,
              not_accessible_reason: valuesToUpdate.not_accessible_reason.modified ? valuesToUpdate.not_accessible_reason.value : item.not_accessible_reason,
              result: getSidewalkAccessibility(
                valuesToUpdate.has_sidewalk.modified ? valuesToUpdate.has_sidewalk.value : item.has_sidewalk,
                valuesToUpdate.accessible.modified ? valuesToUpdate.accessible.value : item.accessible,
                valuesToUpdate.not_accessible_reason.modified ? valuesToUpdate.not_accessible_reason.value : item.not_accessible_reason,
              ),
              height_obstacles: item.height_obstacles,
              date: valuesToUpdate.date.modified ? valuesToUpdate.date.value : item.date,
              creation_date: item.creation_date,
              notes: valuesToUpdate.notes.modified ? valuesToUpdate.notes.value : item.notes,
              image: item.image,
              reviewed: valuesToUpdate.reviewed.modified ? valuesToUpdate.reviewed.value : item.reviewed,
            };
            return {
              ...item,
              ...newValues
            };
          }
          return item
        });

        updatedSelectedAccessibility = updatedSelectedAccessibility.map(item => {
          if (idsToEdit.includes(item.id)) {
            const newValues = {
              id: item.id,
              latitude: item.latitude,
              longitude: item.longitude,
              city: valuesToUpdate.city.modified ? valuesToUpdate.city.value : item.city,
              road: valuesToUpdate.road.modified ? valuesToUpdate.road.value : item.road,
              pavement: valuesToUpdate.pavement.modified ? valuesToUpdate.pavement.value : item.pavement,
              slope: valuesToUpdate.slope.modified ? valuesToUpdate.slope.value : item.slope,
              avg_width_sidewalk: valuesToUpdate.avg_width_sidewalk.modified ? valuesToUpdate.avg_width_sidewalk.value : item.avg_width_sidewalk,
              has_sidewalk: valuesToUpdate.has_sidewalk.modified ? valuesToUpdate.has_sidewalk.value : item.has_sidewalk,
              accessible: valuesToUpdate.accessible.modified ? valuesToUpdate.accessible.value : item.accessible,
              not_accessible_reason: valuesToUpdate.not_accessible_reason.modified ? valuesToUpdate.not_accessible_reason.value : item.not_accessible_reason,
              result: getSidewalkAccessibility(
                valuesToUpdate.has_sidewalk.modified ? valuesToUpdate.has_sidewalk.value : item.has_sidewalk,
                valuesToUpdate.accessible.modified ? valuesToUpdate.accessible.value : item.accessible,
                valuesToUpdate.not_accessible_reason.modified ? valuesToUpdate.not_accessible_reason.value : item.not_accessible_reason,
              ),
              height_obstacles: item.height_obstacles,
              date: valuesToUpdate.date.modified ? valuesToUpdate.date.value : item.date,
              creation_date: item.creation_date,
              notes: valuesToUpdate.notes.modified ? valuesToUpdate.notes.value : item.notes,
              image: item.image,
              reviewed: valuesToUpdate.reviewed.modified ? valuesToUpdate.reviewed.value : item.reviewed,
            };
            return {
              ...item,
              ...newValues
            };
          }
          return item
        });

        if (mapFilterSelectionOn) {
          updatedMapFilteredData = updatedMapFilteredData.map(item => {
            if (idsToEdit.includes(item.id)) {
              const newValues = {
                id: item.id,
                latitude: item.latitude,
                longitude: item.longitude,
                city: valuesToUpdate.city.modified ? valuesToUpdate.city.value : item.city,
                road: valuesToUpdate.road.modified ? valuesToUpdate.road.value : item.road,
                pavement: valuesToUpdate.pavement.modified ? valuesToUpdate.pavement.value : item.pavement,
                slope: valuesToUpdate.slope.modified ? valuesToUpdate.slope.value : item.slope,
                avg_width_sidewalk: valuesToUpdate.avg_width_sidewalk.modified ? valuesToUpdate.avg_width_sidewalk.value : item.avg_width_sidewalk,
                has_sidewalk: valuesToUpdate.has_sidewalk.modified ? valuesToUpdate.has_sidewalk.value : item.has_sidewalk,
                accessible: valuesToUpdate.accessible.modified ? valuesToUpdate.accessible.value : item.accessible,
                not_accessible_reason: valuesToUpdate.not_accessible_reason.modified ? valuesToUpdate.not_accessible_reason.value : item.not_accessible_reason,
                result: getSidewalkAccessibility(
                  valuesToUpdate.has_sidewalk.modified ? valuesToUpdate.has_sidewalk.value : item.has_sidewalk,
                  valuesToUpdate.accessible.modified ? valuesToUpdate.accessible.value : item.accessible,
                  valuesToUpdate.not_accessible_reason.modified ? valuesToUpdate.not_accessible_reason.value : item.not_accessible_reason,
                ),
                height_obstacles: item.height_obstacles,
                date: valuesToUpdate.date.modified ? valuesToUpdate.date.value : item.date,
                creation_date: item.creation_date,
                notes: valuesToUpdate.notes.modified ? valuesToUpdate.notes.value : item.notes,
                image: item.image,
                reviewed: valuesToUpdate.reviewed.modified ? valuesToUpdate.reviewed.value : item.reviewed,
              };
              return {
                ...item,
                ...newValues
              };
            }
            return item
          });
        }
      } else {
        // handle the error
      }
    }

    setAccessibility(updatedAccessibility);
    setSelectedAccessibility(updatedSelectedAccessibility);
    if (mapFilterSelectionOn) setMapFilteredData(updatedMapFilteredData);

  }, [selectedAccessibility, accessibility, isAuthenticated, getAccessTokenSilently, mapFilterSelectionOn, mapFilteredData]);

  const deleteObject = useCallback(async (object) => {

    const objectDeleted = await deleteObjectFromDB(isAuthenticated, getAccessTokenSilently, object.id, object.dbCity);

    if (objectDeleted) {
      const newObjects = objects.filter(obj => obj.id !== object.id);
      setObjects(newObjects);

      const newSelectedObjects = selectedObjects.filter(obj => obj.id !== object.id);
      setSelectedObjects(newSelectedObjects);

      if (mapFilterSelectionOn) {
        const newMapFilteredData = mapFilteredData.filter(obj => obj.id !== object.id);
        setMapFilteredData(newMapFilteredData);
      }

      setDeletedObject(object);
    } else {
      // handle the error
    }
  }, [objects, isAuthenticated, getAccessTokenSilently, selectedObjects, mapFilterSelectionOn, mapFilteredData]);

  const deleteAccessibilityPoint = useCallback(async (accessibilityPoint) => {

    const accessibilityDeleted = await deleteAccessibilityPointFromDB(isAuthenticated, getAccessTokenSilently, accessibilityPoint.id, accessibilityPoint.dbCity);

    if (accessibilityDeleted) {
      const newAccessibility = accessibility.filter(acc => acc.id !== accessibilityPoint.id);
      setAccessibility(newAccessibility);

      const newSelectedAccessibility = selectedAccessibility.filter(acc => acc.id !== accessibilityPoint.id);
      setSelectedAccessibility(newSelectedAccessibility);

      if (mapFilterSelectionOn) {
        const newMapFilteredData = mapFilteredData.filter(acc => acc.id !== accessibilityPoint.id);
        setMapFilteredData(newMapFilteredData);
      }

      setDeletedAccessibility(accessibilityPoint);

    } else {
      // handle the error
    }
  }, [accessibility, isAuthenticated, getAccessTokenSilently, selectedAccessibility, mapFilterSelectionOn, mapFilteredData]);

  const deleteSelectedObjects = useCallback(async () => {
    if (selectedObjects.length === 1) {
      deleteObject(selectedObjects[0]);
    } else {
      const objectsByDbCity = selectedObjects.reduce((acc, obj) => {
        if (!acc[obj.dbCity]) {
          acc[obj.dbCity] = [];
        }
        acc[obj.dbCity].push(obj);
        return acc;
      }, {});

      let newObjects = objects;
      let newMapFilteredData = mapFilteredData;
  
      for (const [dbCity, objectsToDelete] of Object.entries(objectsByDbCity)) {
        let ids = "";
        objectsToDelete.forEach(obj => ids = ids + obj.id + '_');
        ids = ids.slice(0, -1);

        const idsToDelete = ids.split('_');

        const idBatches = [];
        for (let i = 0; i < idsToDelete.length; i += 1000) {
          idBatches.push(idsToDelete.slice(i, i + 1000));
        }

        let updateData = true;

        for (const idBatch of idBatches) {
          const objectsDeleted = await deleteObjectsFromDB(isAuthenticated, getAccessTokenSilently, idBatch, dbCity);
          if (!objectsDeleted) updateData = false;
        }

        if (updateData) {
          newObjects = newObjects.filter(obj => !idsToDelete.includes(obj.id));

          if (mapFilterSelectionOn) {
            newMapFilteredData = newMapFilteredData.filter(obj => !idsToDelete.includes(obj.id));
          }

          selectedObjects.forEach(obj => setDeletedObject(obj));
        } else {
          // handle the error
        }
      }

      setObjects(newObjects);
      setSelectedObjects([]);
      if (mapFilterSelectionOn) setMapFilteredData(newMapFilteredData);
    }
  }, [objects, deleteObject, isAuthenticated, getAccessTokenSilently, selectedObjects, mapFilterSelectionOn, mapFilteredData]); 

  const deleteSelectedAccessibility = useCallback(async () => {
    if (selectedAccessibility.length === 1) {
      deleteAccessibilityPoint(selectedAccessibility[0]);
    } else {
      const accByDbCity = selectedAccessibility.reduce((acc, accPoint) => {
        if (!acc[accPoint.dbCity]) {
          acc[accPoint.dbCity] = [];
        }
        acc[accPoint.dbCity].push(accPoint);
        return acc;
      }, {});

      let newAccessibility = accessibility;
      let newMapFilteredData = mapFilteredData;
  
      for (const [dbCity, accessibilityToDelete] of Object.entries(accByDbCity)) {
        let ids = "";
        accessibilityToDelete.forEach(acc => ids = ids + acc.id + '_');
        ids = ids.slice(0, -1);

        const idsToDelete = ids.split('_');

        const idBatches = [];
        for (let i = 0; i < idsToDelete.length; i += 1000) {
          idBatches.push(idsToDelete.slice(i, i + 1000));
        }

        let updateData = true;

        for (const idBatch of idBatches) {
          const accessibilityDeleted = await deleteAccessibilityFromDB(isAuthenticated, getAccessTokenSilently, idBatch, dbCity);
          if (!accessibilityDeleted) updateData = false;
        }

        if (updateData) {
          newAccessibility = newAccessibility.filter(obj => !idsToDelete.includes(obj.id));

          if (mapFilterSelectionOn) {
            newMapFilteredData = newMapFilteredData.filter(obj => !idsToDelete.includes(obj.id));
          }

          selectedAccessibility.forEach(obj => setDeletedAccessibility(obj));
        } else {
          // handle the error
        }
      }

      setAccessibility(newAccessibility);
      setSelectedAccessibility([]);
      if (mapFilterSelectionOn) setMapFilteredData(newMapFilteredData);
    }
  }, [accessibility, deleteAccessibilityPoint, isAuthenticated, getAccessTokenSilently, selectedAccessibility, mapFilterSelectionOn, mapFilteredData]);

  const openImages = useCallback(async (id, dbCity, isObject, type, road) => {

    if (!imagesExpandedOnce) setImagesExpandedOnce(true);

    if (clickedAccessibilityPointId !== 0) setClickedAccessibilityPointId(id);

    setImagesOpenId(id);

    setClickedObjectType(type);
    setClickedObjectRoad(road);

    const getImagesData = await getImagesFromDB(isAuthenticated, getAccessTokenSilently, id, dbCity, isObject);

    if (getImagesData !== null) {
      setImages(getImagesData);
    } else {
      // handle the error
    }
  }, [imagesExpandedOnce, setImagesExpandedOnce, clickedAccessibilityPointId, setClickedAccessibilityPointId, isAuthenticated, getAccessTokenSilently]);

  function onSelectedModeChange(mode) {
    setSelectedMode(mode);
    if (imagesExpandedOnce) setImagesExpanded(false);
    handleCancelMapFilterSelectionClicked([]);
  }

  function handleMapFilterSelectionClicked() {
    setMapFilterSelectionOn(true);
    setSelectedObjects([]);
    setSelectedAccessibility([]);
  }

  function handleCancelMapFilterSelectionClicked() {
    setMapFilterSelectionOn(false);
    setMapFilteredData([]);
    setSelectedObjects([]);
    setSelectedAccessibility([]);
  }

  const selectMapFilterData = useCallback((dataPoint) => {
    let selectedIndex = mapFilteredData.findIndex((e) => e.id === dataPoint.id);
    let newMapFilteredData = [];

    if (selectedIndex === -1) {
      newMapFilteredData = [dataPoint].concat(mapFilteredData, newMapFilteredData);
    } else if (selectedIndex === 0) {
      newMapFilteredData = newMapFilteredData.concat(mapFilteredData.slice(1));
    } else if (selectedIndex === mapFilteredData.length - 1) {
      newMapFilteredData = newMapFilteredData.concat(mapFilteredData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newMapFilteredData = newMapFilteredData.concat(
        mapFilteredData.slice(0, selectedIndex),
        mapFilteredData.slice(selectedIndex + 1),
      );
    }

    setMapFilteredData(newMapFilteredData);

    if (selectedIndex !== -1) {
      selectedIndex = selectedObjects.findIndex((e) => e.id === dataPoint.id);
      let newSelectedObjects = [];
      if (selectedIndex === 0) {
        newSelectedObjects = newSelectedObjects.concat(selectedObjects.slice(1));
      } else if (selectedIndex === selectedObjects.length - 1) {
        newSelectedObjects = newSelectedObjects.concat(selectedObjects.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelectedObjects = newSelectedObjects.concat(
          selectedObjects.slice(0, selectedIndex),
          selectedObjects.slice(selectedIndex + 1),
        );
      }

      if (newSelectedObjects.length > 0) setSelectedObjects(newSelectedObjects);

      selectedIndex = selectedAccessibility.findIndex((e) => e.id === dataPoint.id);
      let newSelectedAccessibility = [];
      if (selectedIndex === 0) {
        newSelectedAccessibility = newSelectedAccessibility.concat(selectedAccessibility.slice(1));
      } else if (selectedIndex === selectedAccessibility.length - 1) {
        newSelectedAccessibility = newSelectedAccessibility.concat(selectedAccessibility.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelectedAccessibility = newSelectedAccessibility.concat(
          selectedAccessibility.slice(0, selectedIndex),
          selectedAccessibility.slice(selectedIndex + 1),
        );
      }

      if (newSelectedAccessibility.length > 0) setSelectedAccessibility(newSelectedAccessibility);
    }
  }, [mapFilteredData, selectedObjects, selectedAccessibility]);

  const addMultipleMapFilterDataPoints = useCallback((selectedDataPoints) => {
    setMapFilteredData((prevData) => {
      const newData = [...prevData];
      selectedDataPoints.forEach((dataPoint) => {
        const exists = newData.some((item) => item.id === dataPoint.id);
        if (!exists) {
          newData.push(dataPoint);
        }
      });
      return newData;
    });
  }, []);

  const deleteMultipleMapFilterDataPoints = useCallback((selectedDataPoints) => {
    setMapFilteredData((prevData) => {
      const newData = [...prevData];
      selectedDataPoints.forEach((dataPoint) => {
        const index = newData.findIndex((e) => e.id === dataPoint.id);
        if (index !== -1) {
          newData.splice(index, 1);
        }
      });
      return newData;
    });
  }, []);

  const handleEditObjectClicked = useCallback((object) => {
    setSelectedObject(object);
    setDialogEditObjectOpenCalled(true);
  }, []);

  const handleCloseDialogEditObject = useCallback(() => {
    setDialogEditObjectOpen(false);
  }, []);

  const handleDeleteObjectClicked = useCallback((object) => {
    setSelectedObject(object);
    setDialogDeleteObjectOpenCalled(true);
  }, []);

  const handleCloseDialogDeleteObject = useCallback(() => {
    setDialogDeleteObjectOpen(false);
  }, []);

  const handleEditSelectedObjectsClicked = useCallback(() => {
    if (mapFilterSelectionOn && selectedObjects.length === 0) {
      setSelectedObjects(mapFilteredData);
    }

    setDialogEditSelectedObjectsOpenCalled(true);
  }, [selectedObjects, mapFilterSelectionOn, mapFilteredData]);

  const handleCloseDialogEditSelectedObjects = useCallback(() => {
    setDialogEditSelectedObjectsOpen(false);
  }, []);

  const handleDeleteSelectedObjectsClicked = useCallback(() => {
    if (mapFilterSelectionOn && selectedObjects.length === 0) {
      setSelectedObjects(mapFilteredData);
    }

    setDialogDeleteSelectedObjectsOpenCalled(true);
  }, [selectedObjects, mapFilterSelectionOn, mapFilteredData]);

  const handleCloseDialogDeleteSelectedObjects = useCallback(() => {
    setDialogDeleteSelectedObjectsOpen(false);
  }, []);

  /*const handleEditAccessibilitySegmentClicked = useCallback((accessibilitySegment) => {
    setSelectedAccessibilitySegment(accessibilitySegment);
    setDialogEditAccessibilitySegmentOpenCalled(true);
  }, []);

  const handleCloseDialogEditAccessibilitySegment = useCallback(() => {
    setDialogEditAccessibilitySegmentOpen(false);
  }, []);*/ 

  const handleEditAccessibilityPointClicked = useCallback((accessibilityPoint) => {
    setSelectedAccessibilityPoint(accessibilityPoint);
    setDialogEditAccessibilityPointOpenCalled(true);
  }, []);

  const handleCloseDialogEditAccessibilityPoint = useCallback(() => {
    setDialogEditAccessibilityPointOpen(false);
  }, []);

  const handleDeleteAccessibilityPointClicked = useCallback((accessibilityPoint) => {
    setSelectedAccessibilityPoint(accessibilityPoint);
    setDialogDeleteAccessibilityPointOpenCalled(true);
  }, []);

  const handleCloseDialogDeleteAccessibilityPoint = useCallback(() => {
    setDialogDeleteAccessibilityPointOpen(false);
  }, []);

  const handleEditSelectedAccessibilityClicked = useCallback(() => {
    if (mapFilterSelectionOn && selectedAccessibility.length === 0) {
      setSelectedAccessibility(mapFilteredData);
    }

    setDialogEditSelectedAccessibilityOpenCalled(true);
  }, [selectedAccessibility, mapFilterSelectionOn, mapFilteredData]);

  const handleCloseDialogEditSelectedAccessibility = useCallback(() => {
    setDialogEditSelectedAccessibilityOpen(false);
  }, []);

  const handleDeleteSelectedAccessibilityClicked = useCallback(() => {
    if (mapFilterSelectionOn && selectedAccessibility.length === 0) {
      setSelectedAccessibility(mapFilteredData);
    }

    setDialogDeleteSelectedAccessibilityOpenCalled(true);
  }, [selectedAccessibility, mapFilterSelectionOn, mapFilteredData]);

  const handleCloseDialogDeleteSelectedAccessibility = useCallback(() => {
    setDialogDeleteSelectedAccessibilityOpen(false);
  }, []);

  return (
    <div id="digitalInventory">
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", zIndex: 1 }}>
          {selectedMode === 'objects' ?
            <ObjectsFilters
              data={objects}
              onFilteredDataChange={setFilteredObjects}
              updatedObject={updatedObject}
              deletedObject={deletedObject}
            />
            :
            <AccessibilityFilters
              data={accessibility}
              onFilteredDataChange={setFilteredAccessibility}
              updatedAccessibility={updatedAccessibility}
              deletedAccessibility={deletedAccessibility}
            />
          }
        </div>
        <div style={{ position: "absolute", right: 0, zIndex: 1 }}>
          <ToggleObjectsAccessibility
            selectedMode={selectedMode}
            onSelectedModeChange={onSelectedModeChange}
          />
        </div>
        <div style={{ position: "relative", zIndex: 0 }}>
          <Map
            selectedMode={selectedMode}
            editModeOn={editModeOn}
            mapPosition={mapPosition}
            onMapPositionChange={setMapPosition}
            filteredObjects={filteredObjects}
            selectedObjects={selectedObjects}
            hoveringObjectId={hoveringObjectId}
            onHoveringObjectIdChange={setHoveringObjectId}
            popupOpenObjectId={clickedObjectId}
            onPopupOpenObjectIdChange={setClickedObjectId}
            insertObject={insertObject}
            updateObject={updateObject}
            handleEditObjectClicked={handleEditObjectClicked}
            handleDeleteObjectClicked={handleDeleteObjectClicked}
            filteredAccessibility={filteredAccessibility}
            popupOpenAccessibilityPointId={clickedAccessibilityPointId}
            onPopupOpenAccessibilityPointIdChange={setClickedAccessibilityPointId}
            openImages={openImages}
            imagesOpenId={imagesOpenId}
            mapFilterSelectionOn={mapFilterSelectionOn}
            onMapFilterSelectionClicked={handleMapFilterSelectionClicked}
            onCancelMapFilterSelectionClicked={handleCancelMapFilterSelectionClicked}
            selectMapFilterData={selectMapFilterData}
            addMultipleMapFilterDataPoints={addMultipleMapFilterDataPoints}
            deleteMultipleMapFilterDataPoints={deleteMultipleMapFilterDataPoints}
          />
        </div>
      </div>
      {selectedMode === 'objects' ?
        <ObjectsTable
          editModeOn={editModeOn}
          filteredObjects={mapFilterSelectionOn ? mapFilteredData : filteredObjects}
          selectedObjects={selectedObjects}
          onSelectedObjectsChange={setSelectedObjects}
          hoveringObjectId={hoveringObjectId}
          onHoveringObjectIdChange={setHoveringObjectId}
          popupOpenObjectId={clickedObjectId}
          onPopupOpenObjectIdChange={setClickedObjectId}
          handleEditObjectClicked={handleEditObjectClicked}
          handleDeleteObjectClicked={handleDeleteObjectClicked}
          handleEditSelectedObjectsClicked={handleEditSelectedObjectsClicked}
          handleDeleteSelectedObjectsClicked={handleDeleteSelectedObjectsClicked}
          openImages={openImages}
          onMapPositionChange={setMapPosition}
          mapFilterSelectionOn={mapFilterSelectionOn}
          selectMapFilterData={selectMapFilterData}
        />
        :
        <AccessibilityTable
          editModeOn={editModeOn}
          filteredAccessibility={mapFilterSelectionOn ? mapFilteredData : filteredAccessibility}
          selectedAccessibility={selectedAccessibility}
          onSelectedAccessibilityChange={setSelectedAccessibility}
          popupOpenAccessibilityPointId={clickedAccessibilityPointId}
          onClickedAccessibilityPointIdChange={setClickedAccessibilityPointId}
          handleEditAccessibilityPointClicked={handleEditAccessibilityPointClicked}
          handleDeleteAccessibilityPointClicked={handleDeleteAccessibilityPointClicked}
          handleEditSelectedAccessibilityClicked={handleEditSelectedAccessibilityClicked}
          handleDeleteSelectedAccessibilityClicked={handleDeleteSelectedAccessibilityClicked}
          openImages={openImages}
          onMapPositionChange={setMapPosition}
          mapFilterSelectionOn={mapFilterSelectionOn}
          selectMapFilterData={selectMapFilterData}
        />
      }
      {selectedMode === 'objects' ?
        <Fragment>
          <DialogEditObject
            open={dialogEditObjectOpen}
            handleClose={handleCloseDialogEditObject}
            object={selectedObject}
            updateObject={updateObject}
          />
          <DialogDeleteObject
            open={dialogDeleteObjectOpen}
            handleClose={handleCloseDialogDeleteObject}
            object={selectedObject}
            deleteObject={deleteObject}
          />
          <DialogEditSelectedObjects
            open={dialogEditSelectedObjecsOpen}
            handleClose={handleCloseDialogEditSelectedObjects}
            objects={selectedObjects}
            updateSelectedObjects={updateSelectedObjects}
          />
          <DialogDeleteSelectedObjects
            open={dialogDeleteSelectedObjecsOpen}
            handleClose={handleCloseDialogDeleteSelectedObjects}
            selectedObjectsLength={selectedObjects.length}
            deleteSelectedObjects={deleteSelectedObjects}
          />
        </Fragment>
        :
        <Fragment>
          {/*<DialogEditAccessibilitySegment
            open={dialogEditAccessibilitySegmentOpen}
            handleClose={handleCloseDialogEditAccessibilitySegment}
            accessibilitySegment={selectedAccessibilitySegment}
            updateAccessibilitySegment={updateAccessibilitySegment}
          />*/}
          <DialogEditAccessibilityPoint
            open={dialogEditAccessibilityPointOpen}
            handleClose={handleCloseDialogEditAccessibilityPoint}
            accessibilityPoint={selectedAccessibilityPoint}
            updateAccessibilityPoint={updateAccessibilityPoint}
          />
          <DialogDeleteAccessibilityPoint
            open={dialogDeleteAccessibilityPointOpen}
            handleClose={handleCloseDialogDeleteAccessibilityPoint}
            accessibilityPoint={selectedAccessibilityPoint}
            deleteAccessibilityPoint={deleteAccessibilityPoint}
          />
          <DialogEditSelectedAccessibility
            open={dialogEditSelectedAccessibilityOpen}
            handleClose={handleCloseDialogEditSelectedAccessibility}
            accessibility={selectedAccessibility}
            updateSelectedAccessibility={updateSelectedAccessibility}
          />
          <DialogDeleteSelectedAccessibility
            open={dialogDeleteSelectedAccessibilityOpen}
            handleClose={handleCloseDialogDeleteSelectedAccessibility}
            selectedAccessibilityLength={selectedAccessibility.length}
            deleteSelectedAccessibility={deleteSelectedAccessibility}
          />
        </Fragment>
      }
      {(images.length > 0) && (
        <div style={{ position: "fixed", right: 0, top: '120px', zIndex: 1 }}>
          <ImagesCard
            editModeOn={editModeOn}
            imagesExpanded={imagesExpanded}
            onImagesExpandedChange={setImagesExpanded}
            imagesOpenId={imagesOpenId}
            onImagesOpenIdChange={setImagesOpenId}
            images={images}
            type={clickedObjectType}
            road={clickedObjectRoad}
            openImages={openImages}
            filteredAccessibility={filteredAccessibility}
          />
        </div>
      )}
    </div>
  );
}

function getSidewalkAccessibility(hasSidewalk, isAccessible, notAccessibleReason) {
  if (hasSidewalk) {
    if (isAccessible) {
      return 'Acera accesible';
    } else {

      const issues = [];
      const widthIssues = [];

      if (notAccessibleReason.width.fence) widthIssues.push("valla");
      if (notAccessibleReason.width.barrier) widthIssues.push("barrera");
      if (notAccessibleReason.width.vegetation) widthIssues.push("vegetación");
      if (notAccessibleReason.width.billboard) widthIssues.push("cartel publicitario");
      if (notAccessibleReason.width.hydrant) widthIssues.push("hidrante");
      if (notAccessibleReason.width.junction_box) widthIssues.push("caja eléctrica");
      if (notAccessibleReason.width.phone) widthIssues.push("cabina telefónica");
      if (notAccessibleReason.width.light) widthIssues.push("farola");
      if (notAccessibleReason.width.pole) widthIssues.push("poste");
      if (notAccessibleReason.width.utility_pole) widthIssues.push("poste de servicios");

      if (widthIssues.length > 0) {

        let widthMessage = "";

        if (notAccessibleReason.width.width) {
          widthMessage = "Anchura general insuficiente y por ";
          if (widthIssues.length === 1) widthMessage += "obstáculo ";
          else widthMessage += "obstáculos ";
        } else {
          widthMessage = "Anchura insuficiente por ";
        }

        if (widthIssues.length === 1) widthMessage += widthIssues[0];
        if (widthIssues.length === 2) widthMessage += widthIssues[0] + " y " + widthIssues[1];

        if (widthIssues.length > 2) {
          for (let i = 0; i < widthIssues.length; i++) {
            if (i === 0) {
              widthMessage += widthIssues[i];
            } else if (i < widthIssues.length - 1) {
              widthMessage += ", " + widthIssues[i];
            } else {
              widthMessage += ", y " + widthIssues[i];
            }
          }
        }

        issues.push(widthMessage);
      } else if (notAccessibleReason.width.width) issues.push("Anchura general insuficiente")

      const heightIssues = [];

      if (notAccessibleReason.height.sign) heightIssues.push("señal");
      if (notAccessibleReason.height.traffic_light) heightIssues.push("semáforo");

      if (heightIssues.length === 1) issues.push("Altura insuficiente por " + heightIssues[0]);
      if (heightIssues.length === 2) issues.push("Altura insuficiente por " + heightIssues[0] + " y " + heightIssues[1]);

      const crosswalkIssues = [];

      if (notAccessibleReason.crosswalk.lowered) crosswalkIssues.push("rebaje");
      if (notAccessibleReason.crosswalk.duropanot) crosswalkIssues.push("duropanot");

      if (crosswalkIssues.length === 1) issues.push("Paso de cebra sin " + crosswalkIssues[0]);
      if (crosswalkIssues.length === 2) issues.push("Paso de cebra sin " + crosswalkIssues[0] + " ni " + crosswalkIssues[1]);

      return issues.join("; ");
    }
  } else {
    return 'No hay acera';
  }

  /*if (width === 0 && crosswalk !== 1 && height !== 1) {
    return 'Acera accesible';
  }

  const issues = [];
  if (width === 1) issues.push('anchura de acera insuficiente');
  if (crosswalk === 1) issues.push('paso de cebra no rebajado');
  if (height === 1) issues.push('altura de objetos insuficiente');

  let message = issues[0];
  if (issues.length === 2) message = issues.join(' y ');
  if (issues.length === 3) message = issues[0] + ', ' + issues[1] + ', y ' + issues[2];
  return message.charAt(0).toUpperCase() + message.slice(1);*/
};