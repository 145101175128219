import React, { useMemo } from 'react';

import ObjectMarker from './ObjectMarker';

const ObjectMarkerMemo = React.memo(ObjectMarker);

export default function ObjectMarkerWrapper(props) {

  const { editModeOn, object, hoveringObjectId, popupOpenObjectId, selectedObjects, onHoveringObjectIdChange,
    onPopupOpenObjectIdChange, updateObject, handleEditObjectClicked, handleDeleteObjectClicked, openImages,
    mapFilterSelectionOn, selectMapFilterData } = props;

  const hovering = useMemo(() => hoveringObjectId === object.id || popupOpenObjectId === object.id, [hoveringObjectId, popupOpenObjectId, object.id]);
  const selected = useMemo(() => selectedObjects.findIndex((e) => e.id === object.id) !== -1, [selectedObjects, object.id]);

  return (
    <ObjectMarkerMemo
      editModeOn={editModeOn}
      object={object}
      hovering={hovering}
      selected={selected}
      onHoveringObjectIdChange={onHoveringObjectIdChange}
      onPopupOpenObjectIdChange={onPopupOpenObjectIdChange}
      updateObject={updateObject}
      handleEditObjectClicked={handleEditObjectClicked}
      handleDeleteObjectClicked={handleDeleteObjectClicked}
      openImages={openImages}
      mapFilterSelectionOn={mapFilterSelectionOn}
      selectMapFilterData={selectMapFilterData}
    />
  );
}