import React from 'react';

import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const StyledGradientText = styled(Typography)(({ theme }) => ({
  width: 'fit-content',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
}));

export default function GradientText({ children, ...props }) {
  return <StyledGradientText {...props} >{children}</StyledGradientText>
}