import React, { useState, useEffect, forwardRef } from 'react';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale'

import dayjs from 'dayjs';
import "dayjs/locale/es";

import { types, subtypes } from '../../../constants/objectTypes.js';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const reviewedOptions = [true, false];
const itemsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function DialogEditSelectedObjects(props) {

  const { open, handleClose, objects, updateSelectedObjects } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const [city, setCity] = useState('');
  const [road, setRoad] = useState('');
  const [type, setType] = useState('');
  const [subtype, setSubtype] = useState('');
  const [subtypeOptions, setSubtypeOptions] = useState(['No especificado']);
  const [items, setItems] = useState('');
  const [reviewed, setReviewed] = useState('');
  const [date, setDate] = useState('');
  const [notes, setNotes] = useState('');

  const [cityModified, setCityModified] = useState(false);
  const [roadModified, setRoadModified] = useState(false);
  const [typeModified, setTypeModified] = useState(false);
  const [subtypeModified, setSubtypeModified] = useState(false);
  const [itemsModified, setItemsModified] = useState(false);
  const [reviewedModified, setReviewedModified] = useState(false);
  const [dateModified, setDateModified] = useState(false);
  const [notesModified, setNotesModified] = useState(false);

  const [cityLocked, setCityLocked] = useState(false);
  const [roadLocked, setRoadLocked] = useState(false);
  const [typeLocked, setTypeLocked] = useState(false);
  const [itemsLocked, setItemsLocked] = useState(false);
  const [reviewedLocked, setReviewedLocked] = useState(false);
  const [dateLocked, setDateLocked] = useState(false);
  const [notesLocked, setNotesLocked] = useState(false);

  const [errorCity, setErrorCity] = useState(false);
  const [errorRoad, setErrorRoad] = useState(false);

  useEffect(() => {
    if (open && objects.length > 0) {

      setCityModified(false);
      setRoadModified(false);
      setTypeModified(false);
      setSubtypeModified(false);
      setItemsModified(false);
      setReviewedModified(false);
      setDateModified(false);
      setNotesModified(false);

      let uniqueCity = objects[0].city;
      let uniqueRoad = objects[0].road;
      let uniqueType = objects[0].type;
      let uniqueSubtype = objects[0].subtype;
      let uniqueItems = objects[0].items;
      let uniqueReviewed = objects[0].reviewed;
      let uniqueDate = objects[0].date;
      let uniqueNotes = objects[0].notes;

      objects.forEach(obj => {
        if (obj.city !== uniqueCity) uniqueCity = null;
        if (obj.road !== uniqueRoad) uniqueRoad = null;
        if (obj.type !== uniqueType) uniqueType = null;
        if (obj.subtype !== uniqueSubtype) uniqueSubtype = null;
        if (obj.items !== uniqueItems) uniqueItems = null;
        if (obj.reviewed !== uniqueReviewed) uniqueReviewed = null;
        if (obj.date !== uniqueDate) uniqueDate = null;
        if (obj.notes !== uniqueNotes) uniqueNotes = null;
      });

      if (uniqueType === 'Tapa (pozos y arquetas)' || uniqueType === 'Contenedor - Papelera' || uniqueType === 'Señal de trafico') {
        const newSubtypeOptions = subtypes.find((e) => e.type === uniqueType).subtypes;
        setSubtypeOptions(newSubtypeOptions);
      } else {
        setSubtypeOptions(['No especificado']);
      }

      if (uniqueCity === null) {
        setCity('');
        setCityLocked(true);
      } else {
        setCity(uniqueCity);
        setCityLocked(false);
      }

      if (uniqueRoad === null) {
        setRoad('');
        setRoadLocked(true);
      } else {
        setRoad(uniqueRoad);
        setRoadLocked(false);
      }

      if (uniqueType === null || uniqueSubtype === null) {
        setType('');
        setSubtype('');
        setTypeLocked(true);
      } else {
        setType(uniqueType);
        setSubtype(uniqueSubtype);
        setTypeLocked(false);
      }

      if (uniqueItems === null) {
        setItems('');
        setItemsLocked(true);
      } else {
        setItems(uniqueItems);
        setItemsLocked(false);
      }

      if (uniqueReviewed === null) {
        setReviewed('');
        setReviewedLocked(true);
      } else {
        setReviewed(uniqueReviewed);
        setReviewedLocked(false);
      }

      if (uniqueDate === null) {
        setDate(dayjs().valueOf());
        setDateLocked(true);
      } else {
        setDate(dayjs(uniqueDate).valueOf());
        setDateLocked(false);
      }

      if (uniqueNotes === null) {
        setNotes('');
        setNotesLocked(true);
      } else {
        setNotes(uniqueNotes);
        setNotesLocked(false);
      }

      setErrorCity(false);
      setErrorRoad(false);
      setOpenDialog(true);
    }
  }, [open, objects]);

  function closeDialog() {
    setOpenDialog(false);
    handleClose();
  }

  function updateCity(event) {
    setCity(event.target.value);
    setCityModified(true);
  }

  function updateRoad(event) {
    setRoad(event.target.value);
    setRoadModified(true);
  }

  const handleTypeSelected = (event) => {
    setType(event.target.value);
    setTypeModified(true);
    if (event.target.value === 'Tapa (pozos y arquetas)' || event.target.value === 'Contenedor - Papelera' || event.target.value === 'Señal de trafico') {
      const newSubtypeOptions = subtypes.find((e) => e.type === event.target.value).subtypes;
      setSubtypeOptions(newSubtypeOptions);
    } else {
      setSubtypeOptions(['No especificado']);
    }
    setSubtype('No especificado');
    setSubtypeModified(true);
  }

  const handleSubtypeSelected = (event) => {
    setSubtype(event.target.value);
    setSubtypeModified(true);
  }

  const handleItemsSelected = (event) => {
    setItems(event.target.value);
    setItemsModified(true);
  }

  function updateReviewed(event) {
    setReviewed(event.target.value);
    setReviewedModified(true);
  }

  function updateNotes(event) {
    setNotes(event.target.value);
    setNotesModified(true);
  }

  function handleCityLockClicked() {
    setCityLocked(!cityLocked);
  }

  function handleRoadLockClicked() {
    setRoadLocked(!roadLocked);
  }

  function handleTypeLockClicked() {
    setTypeLocked(!typeLocked);
  }

  function handleItemsLockClicked() {
    setItemsLocked(!itemsLocked);
  }

  function handleReviewedLockClicked() {
    setReviewedLocked(!reviewedLocked);
  }

  function handleDateLockClicked() {
    setDateLocked(!dateLocked);
  }

  function handleNotesLockClicked() {
    setNotesLocked(!notesLocked);
  }

  function handleSaveEditClicked() {
    let allValuesFilled = true;
    if (city === '' && !cityLocked) {
      setErrorCity(true);
      allValuesFilled = false;
    } else {
      setErrorCity(false);
    }

    if (road === '' && !roadLocked) {
      setErrorRoad(true);
      allValuesFilled = false;
    } else {
      setErrorRoad(false);
    }

    if (allValuesFilled) {
      let values = {
        city: { modified: cityModified && !cityLocked , value: city },
        road: { modified: roadModified && !roadLocked, value: road },
        type: { modified: typeModified && !typeLocked, value: type },
        subtype: { modified: subtypeModified && !typeLocked, value: subtype },
        items: { modified: itemsModified && !itemsLocked, value: items },
        date: { modified: dateModified && !dateLocked, value: date },
        notes: { modified: notesModified && !notesLocked, value: notes },
        reviewed: { modified: reviewedModified && !reviewedLocked, value: reviewed },
      }
      closeDialog();
      updateSelectedObjects(values);
    }
  }

  return (
    <Dialog open={openDialog} onClose={closeDialog} TransitionComponent={Transition}>
      <DialogTitle>Editar {objects.length} objetos</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Tooltip title={cityLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handleCityLockClicked}
            >
              {cityLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            multiline
            margin="dense"
            id="input-text-city"
            label="Ciudad"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={city}
            disabled={cityLocked}
            error={errorCity}
            onChange={updateCity}
            sx={{ ml: '8px' }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={roadLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handleRoadLockClicked}
            >
              {roadLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            multiline
            margin="dense"
            id="input-text-road"
            label="Calle"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={road}
            disabled={roadLocked}
            error={errorRoad}
            onChange={updateRoad}
            sx={{ ml: '8px' }}
          />
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: '12px' }}>
          <Tooltip title={typeLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px' }}
              onClick={handleTypeLockClicked}
            >
              {typeLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ minWidth: '400px', ml: '8px' }}>
            <FormControl fullWidth>
              <InputLabel id="select-type-label">Categoria</InputLabel>
              <Select
                labelId="select-type-label"
                id="select-type"
                value={type}
                label="Categoria"
                disabled={typeLocked}
                onChange={handleTypeSelected}
              >
                {types.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: '14px' }}>
          <Tooltip title={typeLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px' }}
              onClick={handleTypeLockClicked}
            >
              {typeLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ minWidth: '400px', ml: '8px' }}>
            <FormControl fullWidth>
              <InputLabel id="select-subtype-label">Subategoria</InputLabel>
              <Select
                labelId="select-subtype-label"
                id="select-subtype"
                value={subtype}
                label="Subcategoria"
                disabled={typeLocked}
                onChange={handleSubtypeSelected}
              >
                {subtypeOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: '14px' }}>
          <Tooltip title={itemsLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px' }}
              onClick={handleItemsLockClicked}
            >
              {itemsLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ minWidth: '400px', ml: '8px' }}>
            <FormControl fullWidth>
              <InputLabel id="select-items-label">Elementos</InputLabel>
              <Select
                labelId="select-items-label"
                id="select-items"
                value={items}
                label="Elementos"
                disabled={itemsLocked}
                onChange={handleItemsSelected}
              >
                {itemsOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: '14px' }}>
          <Tooltip title={reviewedLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px' }}
              onClick={handleReviewedLockClicked}
            >
              {reviewedLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ minWidth: '400px', ml: '8px' }}>
            <FormControl fullWidth>
              <InputLabel id="select-type-label">Revisado</InputLabel>
              <Select
                labelId="select-reviewed-label"
                id="select-reviewed"
                value={reviewed}
                label="Revisado"
                disabled={reviewedLocked}
                onChange={updateReviewed}
              >
                {reviewedOptions.map((option, index) => {
                  let textReviewed;
                  if (option) textReviewed = "Sí"
                  else textReviewed = "No"
                  return (
                    <MenuItem key={index} value={option}>{textReviewed}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: '14px' }}>
          <Tooltip title={dateLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px' }}
              onClick={handleDateLockClicked}
            >
              {dateLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ ml: '8px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
              <DatePicker
                label="Fecha"
                value={date}
                disabled={dateLocked}
                onChange={(newValue) => {
                  setDate(dayjs(newValue).startOf('day').valueOf());
                  setDateModified(true);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={notesLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handleNotesLockClicked}
            >
              {notesLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            multiline
            margin="dense"
            id="input-text-notes"
            label="Notas"
            type="text"
            fullWidth
            variant="standard"
            disabled={notesLocked}
            defaultValue={notes}
            onChange={updateNotes}
            sx={{ ml: '8px' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeDialog}>Cancelar</Button>
        <Button color="success" variant="contained" onClick={handleSaveEditClicked}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}