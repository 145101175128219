import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { styled, useTheme } from '@mui/material/styles';

const GradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to bottom, ${theme.palette.neutral.light}, #ffffff)`,
}));

export default function Privacy() {

  const theme = useTheme();

  return (
    <Box id="privacy">
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 500 }}>
            Aviso legal y política de privacidad
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mt: '30px' }}>
            Aviso legal
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            De conformidad con lo dispuesto en los artículos 10 y 11 de la Ley 34/ 2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico se pone a disposición de los usuarios y visitantes la información legal relativa a la Entidad propietaria del sitio web ubicado en la dirección de Internet www.gemminis.io.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            Titular de la página web
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Denominación social: DCOD INGENIERÍA S.L. <br />
            <br />
            B-57562316<br />
            <br />
            C/ALFONS EL MAGNANIM, 29 – 07004 Palma de Mallorca, Baleares.<br />
            <br />
            Dirección de correo electrónico: info@gemminis.io<br />
            <br />
            Teléfono: 971 76 11 50.<br />
            <br />
            Inscrito en el Registro Mercantil de Palma de Mallorca Folio 98, Tomo 2351, Hoja PM 62.377, inscripción 1ª.<br />
            <br />
            Actividad: Gestión y explotación de concesiones.<br />
            <br />
            Para navegar en esta página web como visitante o para registrarse y poder acceder a los servicios ofrecidos por www.mollevant.es, se deberán aceptar las siguientes condiciones de servicio:<br />
            <br />
            A. El usuario y el navegante asumen estas condiciones de servicio.<br />
            <br />
            B. Si el usuario o el navegante no aceptan estas condiciones, no podrán usar esta página web, ni acceder a los servicios que ofrece DCODE Ingeniería S.L.<br />
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            Uso correcto de la página web
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            A. Las presentes Condiciones de Uso regulan el acceso y utilización de la página web www.gemminis.io. La utilización de esta Web le atribuye la condición de usuario de la misma desde el acceso e inicio de la navegación por la misma. Desde el momento de acceso a cualquiera de sus contenidos el usuario acepta de manera expresa las presentes condiciones generales. El usuario acepta las condiciones particulares aplicables de los diferentes servicios que oferta la entidad en la web a la que accede.<br />
            <br />
            B. A través de la página web, DCODE Ingeniería S.L. (Decode) facilita a los Usuarios el acceso y la utilización de diversa información y servicios.<br />
            <br />
            C. La página web tiene carácter gratuito para los Usuarios. El Usuario se compromete a utilizar la página web de conformidad con la Ley, con las disposiciones de estas Condiciones Generales, así como con la moral y buenas costumbres generalmente aceptadas y el orden público.<br />
            <br />
            D. Con carácter general para la prestación de los servicios y el acceso a la información de la página web no se exige la previa suscripción o registro de los Usuarios. No obstante, DCODE Ingeniería S.L. (Decode), condiciona la utilización de algunos de los Servicios a la previa cumplimentación del correspondiente registro o formulario de recogida de datos del Usuario. El citado registro se efectuará en la forma expresamente indicada en el propio servicio o en las Condiciones Particulares que, en su caso, lo regulen.<br />
            <br />
            E. El usuario se obliga desde la aceptación a realizar un uso de la página web www.gemminis.io, los contenidos y sus servicios de conformidad con la ley, las buenas costumbres y el orden público, empleando la diligencia adecuada a la naturaleza del servicio del que disfruta a través de la página web www.gemminis.io. <br />
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            Modificaciones
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            La entidad se reserva el derecho a modificar, unilateralmente, en cualquier momento y sin previo aviso las presentes condiciones. En estos casos se procederá a su publicación y aviso con la máxima antelación posible. De igual modo, se reserva el derecho a modificar, unilateralmente, en cualquier momento y sin previo aviso, la presentación y configuración de la Web.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            Enlaces con terceros
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            El presente Aviso Legal se refiere únicamente a la página web y contenidos de la entidad, y no se aplica a los enlaces o a las páginas web de terceros accesibles a través de la página web. La entidad no es responsable del contenido de ninguna de las páginas web de destino de un enlace, ni de ningún enlace incluido en una página web a la que se llegue desde la web de la entidad.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            Propiedad intelectual e industrial
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
          Todos los contenidos de la página Web son titularidad exclusiva de la entidad y, con carácter enunciativo, que no limitativo, el diseño gráfico, código fuente, logos, textos, gráficos, ilustraciones, fotografías, y demás elementos que aparecen en la página web. Así mismo los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en la página Web están protegidos por la Ley de propiedad intelectual e industrial. Corresponde a la entidad el ejercicio exclusivo de los derechos de explotación de la propiedad intelectual mencionada, en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública. El usuario tiene prohibido cualquier uso no consentido total o parcial de cualquiera de los contenidos de la página web que integran los derechos de propiedad intelectual o industrial de la entidad sobre la página y/o sus contenidos. <br />
          <br />
          La entidad se reserva el derecho a realizar cualquier tipo de acción legal contra cualquier usuario que realice una acción que implique reproducción, distribución, comercialización, transformación y, en general, cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los contenidos de la página Web y que constituya una infracción de los derechos de propiedad intelectual y/o industrial de la misma.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            Notificaciones
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            A los efectos de las presentes Condiciones Generales, y para cualquier comunicación que sea precisa entre la entidad y el Usuario, estos deberán dirigirse mediante el correo electrónico. Las comunicaciones de la entidad al Usuario se realizarán conforme a los datos aportados por éste al registrarse en la página web www.gemminis.io. El Usuario acepta expresamente y para todas las comunicaciones relacionadas con la utilización de la página web y/o la contratación de los servicios en él ofertados, la utilización del correo electrónico como procedimiento válido para la remisión de dichas comunicaciones.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            Legislación aplicable sumisión a fuero
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Las presentes condiciones se regirán por la legislación española, que será de aplicación en lo no dispuesto en este contrato en materia de interpretación, validez y ejecución. Las partes renuncian expresamente al fuero que les pudiera corresponder y someten expresamente a los Juzgados y Tribunales de Palma de Mallorca para resolver cualquier controversia que pueda surgir en la interpretación o ejecución de las presentes condiciones contractuales.
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mt: '40px' }}>
            Política de privacidad
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            A los efectos de lo dispuesto en el art. 13 y el art. 14 del RGPD se le facilita la siguiente información:<br />
            <br />
            a. RESPONSABLE: DCOD INGENIERIA S.L.<br />
            <br />
            b. NIF/CIF: B57562316<br />
            <br />
            c. DIRECCIÓN: C/ALFONS EL MAGNANIM, 29 – 07004 PALMA<br />
            <br />
            d. TELÉFONO 971 76 11 50<br />
            <br />
            e. EMAIL: info@gemminis.io<br />
            <br />
            f. FINALIDAD: En DCODE Ingeniería S.L. (Decode) tratamos la información que nos facilita con el fin de prestar los servicios y/o vender los productos contratados por usted a través de nuestra web y gestionar el envío de información y prospección comercial. Con el fin de poder ofrecerle servicios de acuerdo con sus intereses, elaboraremos un perfil comercial, en base a la información facilitada. No se tomarán decisiones automatizadas en base a dicho perfil. Los datos personales proporcionados se conservarán, mientras se mantenga la relación comercial y no se solicite su supresión por el interesado, durante un plazo de 5 años a partir de la última contratación y/o compra efectuada por usted. Los datos proporcionados se conservarán mientras no solicite el cese de la actividad.<br />
            <br />
            g. LEGITIMACIÓN: La base legal para el tratamiento de sus datos es la ejecución del contrato mediante la compra del producto y/o servicio de conformidad con el Código de Comercio y Código Civil. Resulta de aplicación la Ley de Servicios de Sociedad de la Información 34/2002, artículos 20 y 21, para el envío de ofertas comerciales mediante telecomunicaciones. La oferta prospectiva de productos y servicios está basada en el consentimiento que se le solicita sin que en ningún caso la retirada de este consentimiento condicione el contrato de adquisición de producto y/o prestación de servicio. Existe la obligación de facilitar los datos de carácter personal o, en caso contrario, no se puede prestar el servicio y/o vender el producto y/o facilitar la oferta solicitada y gestionar el envío de información comercial.<br />
            <br />
            h. DESTINATARIOS: No se cederán datos a terceros, salvo obligación legal. No hay previsión de transferencia de datos a terceros países. No se toman decisiones de adecuación, garantías, normas corporativas vinculantes o situaciones específicas aplicables.<br />
            <br />
            i. DERECHOS: Cualquier persona tiene derecho a obtener confirmación sobre si en DCODE Ingeniería S.L. (Decode) estamos tratando datos personales que les conciernen. Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar la supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones. Usted tiene derecho a presentar una Reclamación ante la Autoridad de Control: Agencia Española de Protección de Datos (www.agpd.es). Procedencia: el propio interesado.<br />
            <br />
            <br />
            De conformidad con el art. 13 y 14 del RGPD se le facilita la siguiente información:<br />
            <br />
            - El cliente acepta expresamente la inclusión de los datos de carácter personal recabados durante la navegación por la página o proporcionados mediante la cumplimentación de cualquier formulario, así como los derivados de una posible relación comercial en los ficheros automatizados de datos de carácter personal referidos en el apartado primero. Durante el proceso de recogida de datos y en cada lugar de la Web en el que se soliciten datos de carácter personal, el cliente será informado, ya sea mediante un hipervínculo, ya sea mediante la inclusión de las menciones oportunas en el propio formulario, del carácter obligatorio o no de recogida de tales datos de carácter personal. A todo cliente que decide registrarse en la página web de nuestra empresa le solicitamos los datos de carácter personal necesarios para los fines del servicio solicitado, que no es otro que es la prestación de los servicios y/o venta de productos ofertados en la Web.<br />
            <br />
            - El cliente/usuario podrá ejercitar, respecto de los datos recabados en la forma prevista en el apartado anterior, los derechos reconocidos en los artículos 15 a 21 del Reglamento (UE) 2016/679, y en particular los derechos de acceso, rectificación, supresión, limitación al tratamiento, portabilidad de los datos, oposición y decisiones individuales automatizadas, siempre que resultase pertinente. Los derechos referidos en el párrafo precedente podrán ejercitarse por cada cliente a través de un formulario de ejercicios de derechos que nos solicitará por correo electrónico. La solicitud escrita y firmada podrá enviarla por correo postal a la siguiente dirección: DCODE Ingeniería S.L. (Decode) C/ALFONS EL MAGNANIM, 29 – 07004 Palma de Mallorca, Baleares, adjuntando en ambos casos fotocopia del DNI del cliente.<br />
            <br />
            - El tratamiento automatizado a que serán sometidos todos los datos de carácter personal recogidos como consecuencia de la solicitud, utilización, contratación de cualquier producto o servicio o de cualquier transacción u operación realizada a través de esta página web tiene como finalidad principal el mantenimiento de la relación contractual con el propietario de esta página web.<br />
            <br />
            - Usted autoriza el envío de publicidad de nuestros servicios y productos. Sus datos de carácter personal serán utilizados para gestionar el envío de publicidad a través de medios tradicionales o electrónicos. Las direcciones de correo electrónico y los datos de carácter personal que usted nos proporcione a través del formulario de contacto de la página web serán utilizados exclusivamente para atender las consultas que nos plantee por este medio. Resulta de aplicación lo dispuesto en los art 22.1 y 22.2 de la ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico que se modifica en virtud del art. 4 del Real Decreto-Ley 13/2012, respecto al uso y tratamiento de sus datos de carácter personal con el fin de gestionar el envío de publicidad.<br />
            <br />
            - La entidad garantiza la confidencialidad de los datos de carácter personal. No obstante la Empresa y/o Entidad revelará a las autoridades públicas competentes los datos personales y cualquier otra información que esté en su poder o sea accesible a través de sus sistemas y sea requerida de conformidad con las disposiciones legales y reglamentarias aplicables al caso. Los datos de carácter personal podrán ser conservados en los ficheros titularidad de DCODE Ingeniería S.L. (Decode). incluso una vez finalizadas las relaciones formalizadas a través de la página web de la empresa, exclusivamente a los fines indicados anteriormente y, en todo caso, durante los plazos legalmente establecidos, a disposición de autoridades administrativas o judiciales.

          </Typography>
        </Box>
      </GradientBox>
    </Box>
  );
}