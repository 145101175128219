import React, { useEffect, useRef } from 'react';

import L from "leaflet";

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';

import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import PolylineRoundedIcon from '@mui/icons-material/PolylineRounded';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function MapFilterSelection(props) {

  const { mapFilterSelectionOn, onMapFilterSelectionClicked, drawPolygonOn, onDrawPolygonClicked, handleCancelDrawPolygonClicked,
    deletePolygons, onCancelMapFilterSelectionClicked, addObjectClicked } = props;

  const containerRef = React.useRef(null);
  const childContainerRef = React.useRef(null);

  // do this because of bug on leaflet clicks
  const ref = useRef(undefined);
  useEffect(() => {
    if (ref.current) L.DomEvent.disableClickPropagation(ref.current);
  });

  function cancelClicked() {
    deletePolygons();
    onCancelMapFilterSelectionClicked();
  }

  return (
    <div ref={ref} className={'leaflet-bottom leaflet-right'} style={{ marginBottom: '14px' }}>
      <div className="leaflet-control">
        <Box ref={containerRef} sx={{ position: 'absolute', right: '50px', top: 0 }}>
          <Box ref={childContainerRef} sx={{ position: 'absolute', right: '56px', top: 0 }}>
            <Slide direction="left" in={drawPolygonOn} container={childContainerRef.current}>
              <Tooltip title="Cancelar dibujo" arrow enterDelay={500}>
                <Fab
                  id="btn-marker-set"
                  size="medium"
                  color="error"
                  aria-label="Fijar posición"
                  sx={{ mr: '6px' }}
                  onClick={handleCancelDrawPolygonClicked}
                >
                  <CloseRoundedIcon sx={{ fontSize: 28 }} />
                </Fab>
              </Tooltip>
            </Slide>
          </Box>
          <Slide direction="left" in={mapFilterSelectionOn} container={containerRef.current}>
            <Tooltip title="Dibujar selección" arrow enterDelay={500}>
              <Fab
                id="btn-draw-polygon"
                size="medium"
                color={drawPolygonOn ? "blue" : "neutral"}
                aria-label="Fijar posición"
                sx={{ mr: '6px' }}
                onClick={onDrawPolygonClicked}
                disabled={addObjectClicked}
              >
                <PolylineRoundedIcon color={drawPolygonOn ? "default" : "blue"} sx={{ fontSize: 28 }} />
              </Fab>
            </Tooltip>
          </Slide>
        </Box>
        <Tooltip title={mapFilterSelectionOn ? "Cancelar" : "Seleccionar"} arrow enterDelay={500}>
          <Fab
            id={mapFilterSelectionOn ? "btn-cancel-selection" : "btn-select-markers"}
            size="medium"
            color="neutral"
            aria-label={mapFilterSelectionOn ? "Cancelar" : "Seleccionar"}
            onClick={mapFilterSelectionOn ? cancelClicked : onMapFilterSelectionClicked} // on cancel force cancel on drawing
          >
            {mapFilterSelectionOn ?
              <CloseRoundedIcon color="error" sx={{ fontSize: 28 }} />
              :
              <PlaylistAddCheckRoundedIcon color="primary" sx={{ fontSize: 28 }} />
            }
          </Fab>
        </Tooltip>
      </div>
    </div>
  )
}