import React, { useEffect, useState, forwardRef } from 'react';

import axios from 'axios';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale'

import dayjs from 'dayjs';
import "dayjs/locale/es";

import { types, subtypes } from '../../../constants/objectTypes.js';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogCreateObject(props) {

  const { dialogCreateOpen, handleCloseDialogCreate, coordinates, insertObject } = props;

  const [city, setCity] = useState('Desconocido');
  const [road, setRoad] = useState('Desconocido');
  const [type, setType] = useState('');
  const [subtype, setSubtype] = useState('No especificado');
  const [subtypeOptions, setSubtypeOptions] = useState(['No especificado']);
  const [items, setItems] = useState(1);
  const [date, setDate] = useState('');
  const [notes, setNotes] = useState('');
  const [errorCity, setErrorCity] = useState(false);
  const [errorRoad, setErrorRoad] = useState(false);
  const [errorType, setErrorType] = useState(false);

  const itemsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    if(!dialogCreateOpen) {
      setType('');
      setSubtype('No especificado');
      setItems('1');
      setErrorCity(false);
      setErrorRoad(false);
      setErrorType(false);
      setDate(new Date().getTime());
    }
  }, [dialogCreateOpen]);

  useEffect(() => {
    if(!(coordinates.lat === 0 && coordinates.lng === 0)) {
      axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinates.lat}&lon=${coordinates.lng}`)
        .then(response => {
          let newCity = 'Desconocido';
          let newRoad = 'Desconocido';
          if(response.data.address.state_district !== undefined) newCity = response.data.address.state_district;
          if(response.data.address.county !== undefined) newCity = response.data.address.county;
          if(response.data.address.municipality !== undefined) newCity = response.data.address.municipality;
          if(response.data.address.city !== undefined) newCity = response.data.address.city;
          if(response.data.address.town !== undefined) newCity = response.data.address.town;
          if(response.data.address.village !== undefined) newCity = response.data.address.village;
          if(response.data.address.road !== undefined) newRoad = response.data.address.road;
          setCity(newCity);
          setRoad(newRoad);
        });
    }
  }, [coordinates]);

  function updateCity(event) {
    setCity(event.target.value);
  }

  function updateRoad(event) {
    setRoad(event.target.value);
  }

  function updateNotes(event) {
    setNotes(event.target.value);
  }

  const handleTypeSelected = (event) => {
    setType(event.target.value);
    if (event.target.value === 'Tapa (pozos y arquetas)' || event.target.value === 'Contenedor - Papelera' || event.target.value === 'Señal de trafico') {
      const newSubtypeOptions = subtypes.find((e) => e.type === event.target.value).subtypes;
      setSubtypeOptions(newSubtypeOptions);
    } else {
      setSubtypeOptions(['No especificado']);
    }
    setSubtype('No especificado');
  }

  const handleSubtypeSelected = (event) => {
    setSubtype(event.target.value);
  }

  const handleItemsSelected = (event) => {
    setItems(event.target.value);
  }

  function handleCreateClicked() {
    let allValuesFilled = true;
    if(city === '') {
      setErrorCity(true);
      allValuesFilled = false;
    } else {
      setErrorCity(false);
    }

    if(road === '') {
      setErrorRoad(true);
      allValuesFilled = false;
    } else {
      setErrorRoad(false);
    }

    if(type === '') {
      setErrorType(true);
      allValuesFilled = false;
    } else {
      setErrorType(false);
    }
    
    if(allValuesFilled) {
      let values = {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        city: city,
        road: road,
        type: type,
        subtype: subtype,
        items: items,
        date: date,
        creation_date: date,
        notes: notes,
        reviewed: true,
      }
      insertObject(values);
      handleCloseDialogCreate();
    }
  }

  return (
    <Dialog open={dialogCreateOpen} onClose={handleCloseDialogCreate} TransitionComponent={Transition}>
      <DialogTitle>Crear nuevo objeto</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          margin="dense"
          id="input-text-city"
          label="Ciudad"
          type="text"
          fullWidth
          variant="standard"
          value={city}
          error={errorCity}
          onChange={updateCity}
        />
        <TextField
          multiline
          margin="dense"
          id="input-text-road"
          label="Calle"
          type="text"
          fullWidth
          variant="standard"
          value={road}
          error={errorRoad}
          onChange={updateRoad}
        />
        <Box sx={{ minWidth: '450px', mt: '12px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-type-label">Categoria</InputLabel>
            <Select
              labelId="select-type-label"
              id="select-type"
              value={type}
              label="Categoria"
              error={errorType}
              onChange={handleTypeSelected}
            >
              {types.map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: '450px', mt: '14px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-subtype-label">Subategoria</InputLabel>
            <Select
              labelId="select-subtype-label"
              id="select-subtype"
              value={subtype}
              label="Subcategoria"
              onChange={handleSubtypeSelected}
            >
              {subtypeOptions.map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: '450px', mt: '14px', mb: '14px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-items-label">Elementos</InputLabel>
            <Select
              labelId="select-items-label"
              id="select-items"
              value={items}
              label="Elementos"
              onChange={handleItemsSelected}
            >
              {itemsOptions.map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <DatePicker
            label="Fecha"
            value={date}
            onChange={(newValue) => {
              setDate(dayjs(newValue).startOf('day').valueOf());
            }}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </LocalizationProvider>
        <TextField
          multiline
          margin="dense"
          id="input-text-notes"
          label="Notas"
          type="text"
          fullWidth
          variant="standard"
          onChange={updateNotes}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleCloseDialogCreate}>Cancelar</Button>
        <Button color="success" variant="contained" onClick={handleCreateClicked}>Añadir</Button>
      </DialogActions>
    </Dialog>
  );
}