import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';

import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

import GradientBox from '../customs/GradientBox';
import GradientText from '../customs/GradientText';

import { sendEmail } from '../../utils/api';

export default function Contact() {

  const theme = useTheme();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function updateName(event) {
    setName(event.target.value);
  }

  function updateEmail(event) {
    setEmail(event.target.value);
  }

  function updateMessage(event) {
    setMessage(event.target.value);
  }

  function handleSendClicked() {
    sendEmail(name + " con correo " + email + " solicita: \n\n" + message);
    setName('');
    setEmail('');
    setMessage('');
  }

  return (
    <Box id="contact">
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Card sx={{ p: theme.spacing(3), borderRadius: theme.shape.borderRadius }}>
            <Typography variant="body1" color="grey.800" fontWeight='medium' sx={{ ml: theme.spacing(0.5), mb: '0' }}>
              Nombre
            </Typography>
            <TextField
              multiline
              margin="dense"
              id="input-text-city"
              label="Nombre"
              type="text"
              fullWidth
              variant="outlined"
              value={name}
              onChange={updateName}
            />
            <Typography variant="body1" color="grey.800" fontWeight='medium' sx={{ mt: theme.spacing(2), ml: theme.spacing(0.5), mb: '0' }}>
              Correo electrónico
            </Typography>
            <TextField
              multiline
              margin="dense"
              id="input-text-road"
              label="Email"
              type="text"
              fullWidth
              variant="outlined"
              value={email}
              onChange={updateEmail}
            />
            <Typography variant="body1" color="grey.800" fontWeight='medium' sx={{ mt: theme.spacing(2), ml: theme.spacing(0.5), mb: '0' }}>
              ¿En qué podemos ayudarle?
            </Typography>
            <TextField
              multiline
              margin="dense"
              id="input-text-road"
              label="Mensaje"
              type="text"
              fullWidth
              variant="outlined"
              rows={6}
              value={message}
              onChange={updateMessage}
            />
            <Button
              color="primary"
              variant="contained"
              size="large"
              aria-label="Send mail"
              endIcon={<SendRoundedIcon sx={{ pl: '2px' }} />}
              onClick={handleSendClicked}
              sx={{ mt: theme.spacing(2) }}
            >
              Enviar
            </Button>
          </Card>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Typography variant="h4" color="grey.800" gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(2), mb: theme.spacing(6), textAlign: 'center' }}>
            También puede contactar con nosotros:
          </Typography>
          <Grid container columnSpacing={4} rowSpacing={1} sx={{ mb: theme.spacing(2) }}>
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
              <Typography variant="h6" color="grey.800" gutterBottom fontWeight='medium' sx={{ mt: '4px' }} >
                - Escribiendo un correo a:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              <Button
                color="tertiary"
                href='mailto:info@gemminis.io'
                variant="contained"
                size="large"
                startIcon={<EmailRoundedIcon />}
              >
                info@gemminis.io
              </Button>
            </Grid>
          </Grid>
          <Grid container columnSpacing={4} rowSpacing={1} sx={{ mb: theme.spacing(2) }}>
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
              <Typography variant="h6" color="grey.800" gutterBottom fontWeight='medium' sx={{ mt: '4px' }} >
                - Llamando al:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              <Button
                color="tertiary"
                href='tel:+34971761150'
                variant="contained"
                size="large"
                startIcon={<PhoneRoundedIcon />}
              >
                + 34 971 761 150
              </Button>
            </Grid>
          </Grid>
          <Grid container columnSpacing={4} rowSpacing={1} sx={{ mb: theme.spacing(2) }}>
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
              <Typography variant="h6" color="grey.800" gutterBottom fontWeight='medium' sx={{ mt: '4px' }} >
                - A través de LinkedIn:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              <Button
                color="tertiary"
                href='https://www.linkedin.com/company/gemminis/'
                variant="contained"
                size="large"
                startIcon={<ShareRoundedIcon />}
              >
                GEMMINIS
              </Button>
            </Grid>
          </Grid>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6), pt: theme.spacing(14) }}>
          <GradientText variant="h3" gutterBottom fontWeight='medium' sx={{ pb: '20px' }}>
            ¿Listo para el siguiente paso? Defina su Inventario Digital y reciba un presupuesto personalizado.
          </GradientText>
          <Button
            color="primary"
            href='/empecemos/'
            variant="contained"
            size="large"
            aria-label="Start now"
            sx={{ fontSize: 'large', my: theme.spacing(4) }}
            endIcon={<ArrowForwardRoundedIcon />}
          >
            Empecemos
          </Button>
        </Box>
      </GradientBox>
    </Box>
  );
}