import React from 'react';

import { styled } from '@mui/material/styles';

const SyledGradientBorderImage = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'inline-block',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    height: '20px',
  },
  '&::before': {
    top: 0,
    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, rgba(255, 128, 0, 0.85) 20%, rgba(255, 128, 0, 0.6) 50%, transparent)`,
  },
  '&::after': {
    bottom: 0,
    background: `linear-gradient(to top, ${theme.palette.neutral.light}, rgba(245, 245, 245, 0.85) 20%, rgba(245, 245, 245, 0.6) 50%, transparent)`,
  },
  '& img': {
    display: 'block',
    width: '100%',
  },
}));

export default function GradientBorderImage({ children, ...props }) {
  return <SyledGradientBorderImage {...props} >{children}</SyledGradientBorderImage>
}