import BikeParking from '../assets/images/bike-parking.jpg';
import Bench from '../assets/images/bench.jpg';
import CCTV from '../assets/images/cctv.jpg';
import TrashCan from '../assets/images/trash-can.jpg';
import StreetLamp from '../assets/images/street-lamp.jpg';
import FireHydrant from '../assets/images/fire-hydrant.jpg';
import ElectricalBox from '../assets/images/electrical-box.jpg';
import Drainage from '../assets/images/drainage.jpg';
import Crosswalk from '../assets/images/crosswalk.jpg';
import TrafficLight from '../assets/images/traffic-light.jpg';
import StreetSign from '../assets/images/street-sign.jpg';
import StreetWell from '../assets/images/street-well.jpg';
import ElectricalTower from '../assets/images/electrical-tower.jpg';

import SidewalkWidth from '../assets/images/sidewalk-width.jpg';
import SidewalkObjectsHeight from '../assets/images/sidewalk-objects-height.jpg';
import Pavements from '../assets/images/sidewalk-pavements.jpg';
import Podotactile from '../assets/images/podotactile-sidewalk.jpg';
import AccessibleCrosswalk from '../assets/images/accessible-crosswalk.jpg';
import SidewalkVehicles from '../assets/images/sidewalk-vehicles.jpg';

import RoadSeparators from '../assets/images/road-separators.jpg';
import CrashBarriers from '../assets/images/road-crash-barriers.jpg';
import RoadLines from '../assets/images/road-lines.jpg';

import Bollard from '../assets/images/bollard.jpg';
import PortServicePoint from '../assets/images/port-service-point.jpg';



export const inventoryCatalogue = [
  {
    name: 'Aparca bicis',
    image: BikeParking,
  },
  {
    name: 'Banco',
    image: Bench,
  },
  {
    name: 'Cámara CCTV',
    image: CCTV,
  },
  {
    name: 'Contenedor - Papelera',
    image: TrashCan,
  },
  {
    name: 'Farola',
    image: StreetLamp,
  },
  {
    name: 'Hidrante',
    image: FireHydrant,
  },
  {
    name: 'Hornacina',
    image: ElectricalBox,
  },
  {
    name: 'Imbornal',
    image: Drainage,
  },
  {
    name: 'Paso de cebra',
    image: Crosswalk,
  },
  {
    name: 'Semáforo',
    image: TrafficLight,
  },
  {
    name: 'Señal de tráfico',
    image: StreetSign,
  },
  {
    name: 'Tapa (pozos y arquetas)',
    image: StreetWell,
  },
  {
    name: 'Torre de electricidad',
    image: ElectricalTower,
  },
];

export const accessibilityCatalogue = [
  {
    name: 'Anchura acera',
    image: SidewalkWidth,
  },
  {
    name: 'Altura objetos en acera',
    image: SidewalkObjectsHeight,
  },
  {
    name: 'Pavimento',
    image: Pavements,
  },
  {
    name: 'Podotáctil',
    image: Podotactile,
  },
  {
    name: 'Rebaje paso de cebra',
    image: AccessibleCrosswalk,
  },
  {
    name: 'Vehículos en acera',
    image: SidewalkVehicles,
  },
];

export const stakeoutsCatalogue = [
  {
    name: 'Barrera New Jersey',
    image: RoadSeparators,
  },
  {
    name: 'Bionda',
    image: CrashBarriers,
  },
  {
    name: 'Límites de carril',
    image: RoadLines,
  },
];

export const portsCatalogue = [
  {
    name: 'Bolardo',
    image: Bollard,
  },
  {
    name: 'Punto de servicio',
    image: PortServicePoint,
  },
];