import React, { useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function ObjectsTableRow(props) {

  const { editModeOn, row, hovering, onHoveringObjectIdChange, isItemSelected, handleSelectClick,
    handleEditObjectClicked, handleDeleteObjectClicked, openImages, onMapPositionChange,
    mapFilterSelectionOn, selectMapFilterData } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);

  var textReviewed = row.reviewed ? "Sí" : "No";

  return (
    <React.Fragment>
      <TableRow
        role="checkbox"
        aria-checked={isItemSelected}
        onClick={() => {
          onMapPositionChange([row.latitude, row.longitude]);
          setCollapseOpen(!collapseOpen);
          openImages(row.id, row.dbCity, true, row.type, row.road);
        }}
        onMouseEnter={() => onHoveringObjectIdChange(row.id)}
        onMouseLeave={() => onHoveringObjectIdChange(0)}
        sx={{
          backgroundColor:
            hovering ?
              isItemSelected ?
                'faded.dark'
                :
                'neutral.light'
              :
              isItemSelected ?
                'faded.light'
                :
                'neutral'
        }}
      >
        <TableCell padding="checkbox" sx={{ border: 'none' }}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={(event) => {
              event.stopPropagation();
              handleSelectClick(row);
            }}
          />
        </TableCell>
        <TableCell sx={{ border: 'none' }}>{row.city}</TableCell>
        <TableCell sx={{ border: 'none' }}>{row.road}</TableCell>
        <TableCell sx={{ border: 'none' }}>{row.type}</TableCell>
        <TableCell sx={{ border: 'none' }}>{row.subtype}</TableCell>
        <TableCell sx={{ border: 'none' }}>{row.items}</TableCell>
        <TableCell sx={{ border: 'none' }}>{textReviewed}</TableCell>
        <TableCell sx={{ border: 'none' }}>{new Date(row.date).toLocaleDateString('es-ES')}</TableCell>
        <TableCell sx={{ border: 'none', width: '100px', maxWidth: '260px', whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }}>{row.notes}</TableCell>
        {editModeOn && (
          <TableCell padding="none" align="left" sx={{ paddingLeft: '5px', border: 'none' }}>
            <Tooltip title="Editar" arrow enterDelay={500}>
              <IconButton
                color={
                  hovering ?
                    "success"
                    :
                    isItemSelected ?
                      'faded.light'
                      :
                      'neutral'
                }
                aria-label="Editar objeto"
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditObjectClicked(row);
                }}
              >
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar" arrow enterDelay={500}>
              <IconButton
                color={
                  hovering ?
                    "error"
                    :
                    isItemSelected ?
                      'faded.light'
                      :
                      'neutral'
                }
                aria-label="Eliminar objecto"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteObjectClicked(row);
                }}
              >
                <DeleteRoundedIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        <TableCell padding="none" align="left" sx={{ paddingLeft: '3px', border: 'none' }}>
          {mapFilterSelectionOn && (
            <Tooltip title="Deseleccionar" arrow enterDelay={500}>
              <IconButton
                color={
                  hovering ?
                    "error"
                    :
                    isItemSelected ?
                      'faded.light'
                      :
                      'neutral'
                }
                aria-label="Deseleccionar objecto"
                onClick={(event) => {
                  event.stopPropagation();
                  selectMapFilterData(row);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow
        role="checkbox"
        aria-checked={isItemSelected}
        onClick={() => {
          onMapPositionChange([row.latitude, row.longitude]);
        }}
        onMouseEnter={() => onHoveringObjectIdChange(row.id)}
        onMouseLeave={() => onHoveringObjectIdChange(0)}
        sx={{
          backgroundColor:
            hovering ?
              isItemSelected ?
                'faded.dark'
                :
                'neutral.light'
              :
              isItemSelected ?
                'faded.light'
                :
                'neutral'
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={12}>
          <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box sx={{ mb: '6px' }}>
                <Typography variant='string' gutterBottom fontWeight='medium'>Coordenadas: </Typography>
                <Typography variant='string' gutterBottom >{row.latitude + ", " + row.longitude}</Typography>
              </Box>
              <Box sx={{ mb: '6px' }}>
                <Typography variant='string' gutterBottom fontWeight='medium'>Fecha de creación: </Typography>
                <Typography variant='string' gutterBottom >{new Date(row.creation_date).toLocaleDateString('es-ES')}</Typography>
              </Box>
              <Box>
                <Typography variant='string' gutterBottom fontWeight='medium'>Notas: </Typography>
                <Typography variant='string' gutterBottom >{row.notes}</Typography>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}