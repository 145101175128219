import React, { forwardRef } from 'react';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogDeleteSelectedAccessibility(props) {

  const { open, handleClose, selectedAccessibilityLength, deleteSelectedAccessibility } = props;

  function handleDeleteClicked() {
    handleClose();
    deleteSelectedAccessibility();
  }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>Eliminar puntos de accesibilidad seleccionados</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-delete-description">
          Estas seguro de que deseas eliminar los {selectedAccessibilityLength} puntos de accesibilidad seleccionados?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>Cancelar</Button>
        <Button color="error" variant="contained" onClick={handleDeleteClicked}>Eliminar</Button>
      </DialogActions>
    </Dialog>
  );
}