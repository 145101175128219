import React, { useState, useRef, useCallback, useMemo } from 'react';

import axios from 'axios';

import { useMap } from 'react-leaflet/hooks'

import { Marker } from 'react-leaflet/Marker'

import ObjectMarkerPopup from './ObjectMarkerPopup';
import { getMarkerIcons } from '../../../constants/markerIcons';

export default function ObjectMarker(props) {

  const { editModeOn, object, hovering, selected, onHoveringObjectIdChange, onPopupOpenObjectIdChange,
    updateObject, handleEditObjectClicked, handleDeleteObjectClicked, openImages,
    mapFilterSelectionOn, selectMapFilterData } = props;
  
  const lowOpacity = 0.5;

  const [ myIcon, hoveredIcon, draggableIcon ] = useMemo(() => getMarkerIcons(object.type, object.subtype), [object.type, object.subtype]);

  const [position, setPosition] = useState([object.latitude, object.longitude])
  const [draggable, setDraggable] = useState(false);
  const [moved, setMoved] = useState(false);
  const [currentIcon, setCurrentIcon] = useState(myIcon);
  const [opacity, setOpacity] = useState(lowOpacity)

  const markerRef = useRef(null);

  const map = useMap();

  const originalPosition = [object.latitude, object.longitude];

  const [prevType, setPrevType] = useState(object.type);
  const [prevSubtype, setPrevSubtype] = useState(object.subtype);
  if (prevType !== object.type || prevSubtype !== object.subtype) {
    setPrevType(object.type);
    setPrevSubtype(object.subtype);
    setCurrentIcon(hoveredIcon);
  }

  const [prevHovering, setPrevHovering] = useState(hovering);
  if(prevHovering !== hovering) {
    setPrevHovering(hovering);
    if (!draggable) {
      if (hovering) {
      setCurrentIcon(hoveredIcon);
        setOpacity(1);
      } else if(!markerRef.current.isPopupOpen()) {
        setCurrentIcon(myIcon);
        if(!selected) setOpacity(lowOpacity);
      }
    }
  }

  const [prevSelected, setPrevSelected] = useState(selected);
  if(prevSelected !== selected) {
    setPrevSelected(selected);
    if (!draggable) {
      if (selected) {
        setOpacity(1);
      } else {
        if(!hovering) setOpacity(lowOpacity);
      }
    }
  }

  const eventHandlers = useMemo(
    () => ({
      click () {
        openImages(object.id, object.dbCity, true, object.type, object.road);
        if(mapFilterSelectionOn) selectMapFilterData(object);
      },
      dragstart() { if(!moved) setMoved(true) },
      dragend() {
        markerRef.current.openPopup();
        setPosition(markerRef.current.getLatLng());
      },
      mouseover() { onHoveringObjectIdChange(object.id) },
      mouseout() { onHoveringObjectIdChange(0) },
      popupopen() {
        onPopupOpenObjectIdChange(object.id);
      },
      popupclose() { onPopupOpenObjectIdChange(0) }
    }),
    [object, mapFilterSelectionOn, selectMapFilterData, openImages, moved, onHoveringObjectIdChange, onPopupOpenObjectIdChange],
  );

  const updateObjectPosition = useCallback((coordinates) => {
    axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinates.lat}&lon=${coordinates.lng}`)
    .then(response => {
      let city = 'Desconocido';
      let road = 'Desconocido';
      if(response.data.address.state_district !== undefined) city = response.data.address.state_district;
      if(response.data.address.county !== undefined) city = response.data.address.county;
      if(response.data.address.municipality !== undefined) city = response.data.address.municipality;
      if(response.data.address.city !== undefined) city = response.data.address.city;
      if(response.data.address.town !== undefined) city = response.data.address.town;
      if(response.data.address.village !== undefined) city = response.data.address.village;
      if(response.data.address.road !== undefined) road = response.data.address.road;

      let values = {
        id: object.id,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        city: city,
        road: road,
        type: object.type,
        subtype: object.subtype,
        items: object.items,
        date: object.date,
        creation_date: object.creation_date,
        notes: object.notes,
        images: object.images,
        reviewed: object.reviewed,
      }
      updateObject(object, values);
    });
  }, [object, updateObject]);

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
    setCurrentIcon(draggableIcon);
    if (draggable) {
      markerRef.current.closePopup();
      setCurrentIcon(myIcon);
      setMoved(false);
      updateObjectPosition(markerRef.current.getLatLng());
    }
  }, [draggable, draggableIcon, myIcon, updateObjectPosition]);

  function cancelMovePosition() {
    markerRef.current.closePopup();
    setPosition(originalPosition);
    setCurrentIcon(myIcon);
    setDraggable(false);
    setMoved(false);
    map.flyTo(originalPosition);  // only do this if its outside of the view
  }

  return (
    <Marker
      ref={markerRef}
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      icon={currentIcon}
      opacity={opacity}
      riseOnHover
    >
      <ObjectMarkerPopup
        editModeOn={editModeOn}
        object={object}
        draggable={draggable}
        toggleDraggable={toggleDraggable}
        moved={moved}
        cancelMovePosition={cancelMovePosition}
        handleEditObjectClicked={handleEditObjectClicked}
        handleDeleteObjectClicked={handleDeleteObjectClicked}
      />
    </Marker>
  )
}