import React, { useRef, Fragment } from 'react';

import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import Carousel from 'react-material-ui-carousel';

export default function ImagesCard(props) {

  const { editModeOn, imagesExpanded, onImagesExpandedChange, imagesOpenId, onImagesOpenIdChange, images, type, road, openImages, filteredAccessibility } = props;

  const lastIdRef = useRef(0);

  if (imagesOpenId !== 0) {
    lastIdRef.current = imagesOpenId;
  }

  const height = editModeOn ? 270 : 216;
  const width = editModeOn ? 480 : 384;

  const limitedImages = images.slice(0, 20);

  function openNewImages(isNext) {
    const index = filteredAccessibility.findIndex((e) => e.id === imagesOpenId);
    const nextIndex = isNext ?
      (index + 1) % filteredAccessibility.length
      :
      (index - 1 + filteredAccessibility.length) % filteredAccessibility.length;

    const nextObject = filteredAccessibility[nextIndex];
    openImages(nextObject.id, nextObject.dbCity, false, nextObject.result, nextObject.road);
  }

  function handleImagesExpandedClicked() {
    onImagesExpandedChange(!imagesExpanded);
    onImagesOpenIdChange(imagesExpanded ? 0 : lastIdRef.current);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', minHeight: '314px' }}>
      <Button
        onClick={handleImagesExpandedClicked}
        aria-label="show more"
        color="neutral"
        variant="contained"
        sx={{
          position: 'absolute',
          left: -26,
          top: editModeOn ? '50%' : '40%',
          transform: 'translateY(-50%)',
          minWidth: '26px',
          maxWidth: '26px',
          minHeight: '44px',
          maxHeight: '44px',
          padding: '0px',
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px',
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderLeft: '1px solid',
          borderRigth: '0px',
          borderColor: "neutral.dark",
        }}
      >
        {imagesExpanded ?
          <KeyboardArrowRightRoundedIcon sx={{ fontSize: 22, ml: '2px' }} />
          :
          <KeyboardArrowLeftRoundedIcon sx={{ fontSize: 22, ml: '2px' }} />
        }
      </Button>
      <Collapse in={imagesExpanded} orientation="horizontal" timeout="auto" unmountOnExit>
        <Card
          sx={{
            width: width,
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderLeft: '1px solid',
            borderRigth: '0px',
            borderColor: "neutral.dark",
          }}
        >
          {limitedImages.length > 1 ?
            <Carousel
              autoPlay={false}
              animation='slide'
              height={height}
              indicatorIconButtonProps={{ style: { color: '#ffffff' } }}
              activeIndicatorIconButtonProps={{ style: { color: '#ff8000' } }}
              indicatorContainerProps={{
                style: {
                  position: 'absolute',
                  bottom: 10,
                  zIndex: 1,
                }
              }}
            >
              {limitedImages.map((image, index) =>
                <div style={{ position: 'relative', display: 'inline-block' }} key={index}>
                  <img
                    key={index}
                    src={image.presignedUrl}
                    style={{
                      width: width + 'px',
                    }}
                    alt="La imagen no ha podido ser descargada"
                  />
                  {image.roi &&
                    <div
                      style={{
                        position: 'absolute',
                        top: image.roi[0] / 4 + 'px',
                        left: image.roi[1] / 4 + 'px',
                        height: (image.roi[2] - image.roi[0]) / 4 + 'px',
                        width: (image.roi[3] - image.roi[1]) / 4 + 'px',
                        border: '1px solid red',
                        background: 'tansparent',
                      }}
                    />
                  }
                </div>
              )}
            </Carousel>
            :
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <img
                src={limitedImages[0].presignedUrl}
                style={{
                  width: width + 'px',
                }}
                alt="La imagen no ha podido ser descargada"
              />
              {limitedImages[0].roi ?
                <div
                  style={{
                    position: 'absolute',
                    top: limitedImages[0].roi[0] / 4 + 'px',
                    left: limitedImages[0].roi[1] / 4 + 'px',
                    height: (limitedImages[0].roi[2] - limitedImages[0].roi[0]) / 4 + 'px',
                    width: (limitedImages[0].roi[3] - limitedImages[0].roi[1]) / 4 + 'px',
                    border: '2px solid red',
                    background: 'tansparent',
                  }}
                />
                :
                <Fragment>
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: '50%', // Center horizontally
                      transform: 'translateX(-50%)', // Center horizontally
                    }}
                  >
                    <IconButton color="neutral" variant="text" onClick={() => openNewImages(true)}>
                      <KeyboardArrowUpRoundedIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: '50%', // Center horizontally
                      transform: 'translateX(-50%)', // Center horizontally
                    }}
                  >
                    <IconButton color="neutral" variant="text" onClick={() => openNewImages(false)}>
                      <KeyboardArrowDownRoundedIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                  </div>
                </Fragment>
              }
            </div>
          }
          <Typography sx={{ m: '6px', textAlign: 'center', fontSize: '14px' }}>{type} en la calle {road}</Typography>
        </Card>
      </Collapse>
    </div>
  );
}