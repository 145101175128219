import React, { useEffect, useRef } from 'react';

import L from "leaflet";

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function CreateObject(props) {

  const { addObjectClicked, onAddObjectClicked, onMarkerSetClicked, onCancelClicked, drawPolygonOn } = props;

  const containerRef = React.useRef(null);

  // do this because of bug on leaflet clicks
  const ref = useRef(undefined);
  useEffect(() => {
    if (ref.current) L.DomEvent.disableClickPropagation(ref.current);
  });

  return (
    <div ref={ref} className={'leaflet-bottom leaflet-right'} style={{ marginBottom: '70px' }}>
      <div className="leaflet-control">
        <Box ref={containerRef} sx={{ position: 'absolute', right: '50px', top: 0 }}>
          <Slide direction="left" in={addObjectClicked} container={containerRef.current}>
            <Tooltip title="Fijar posición" arrow enterDelay={500}>
              <Fab
                id="btn-marker-set"
                size="medium"
                color="success"
                aria-label="Fijar posición"
                sx={{ mr: '6px' }}
                onClick={onMarkerSetClicked}
              >
                <CheckRoundedIcon sx={{ fontSize: 28 }} />
              </Fab>
            </Tooltip>
          </Slide>
        </Box>
        <Tooltip title={addObjectClicked ? "Cancelar" : "Crear objeto"} arrow enterDelay={500}>
          <Fab
            id={addObjectClicked ? "btn-cancel-add-marker" : "btn-add-marker"}
            size="medium"
            color={addObjectClicked ? "error" : "primary"}
            aria-label={addObjectClicked ? "Cancelar" : "Crear objeto"}
            onClick={addObjectClicked ? onCancelClicked : onAddObjectClicked}
            disabled={drawPolygonOn}
          >
            {addObjectClicked ?
              <CloseRoundedIcon sx={{ fontSize: 28 }} />
              :
              <AddRoundedIcon sx={{ fontSize: 28 }} />
            }
          </Fab>
        </Tooltip>
      </div>
    </div>
  )
}