import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { visuallyHidden } from '@mui/utils';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import ExportData from './ExportData';

export default function ObjectsTableHeader(props) {

  const { editModeOn, filteredObjects, selectedObjects, order, orderBy, onRequestSort, onSelectAllClick,
    mapFilterSelectionOn, handleEditSelectedObjectsClicked, handleDeleteSelectedObjectsClicked } = props;

  const rowCount = filteredObjects.length;
  const numSelected = selectedObjects.length;

  const selectedsDisabled = mapFilterSelectionOn ? rowCount === 0 : numSelected === 0;

  const createSortHandler = (property) => (event) => { onRequestSort(property, event) };

  const headCells = [
    {
      id: 'city',
      numeric: false,
      disablePadding: true,
      label: 'Ciudad',
    },
    {
      id: 'road',
      numeric: false,
      disablePadding: true,
      label: 'Calle',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: true,
      label: 'Categoria',
    },
    {
      id: 'subtype',
      numeric: false,
      disablePadding: true,
      label: 'Subcategoria',
    },
    {
      id: 'items',
      numeric: false,
      disablePadding: true,
      label: 'Elementos',
    },
    {
      id: 'reviewed',
      numeric: false,
      disablePadding: true,
      label: 'Revisado',
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: 'Fecha',
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: true,
      label: 'Notas',
    }
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id="checkbox-select-all"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Marcar todos',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {editModeOn && (
          <TableCell padding='none' align="left">
            <Tooltip
              title={selectedsDisabled ? "No hay elementos seleccionados" : "Editar seleccionados"}
              arrow
              enterDelay={500}
              componentsProps={{ tooltip: { sx: { top: '17px', }, } }}
            >
              <span>
                <IconButton
                  color="success"
                  aria-label="Eliminar seleccionados"
                  disabled={selectedsDisabled}
                  onClick={handleEditSelectedObjectsClicked}
                >
                  <EditRoundedIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={selectedsDisabled ? "No hay elementos seleccionados" : "Eliminar seleccionados"}
              arrow
              enterDelay={500}
              componentsProps={{ tooltip: { sx: { top: '17px', }, } }}
            >
              <span>
                <IconButton
                  color="error"
                  aria-label="Eliminar seleccionados"
                  disabled={selectedsDisabled}
                  onClick={handleDeleteSelectedObjectsClicked}
                >
                  <DeleteRoundedIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        )}
        <TableCell padding='none' align="left">
          <ExportData
            objects={true}
            filteredData={filteredObjects}
            selectedData={selectedObjects}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
