import React, { useState, useEffect, useRef } from 'react';

import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import { ScaleControl } from 'react-leaflet/ScaleControl';
import { useMapEvents } from 'react-leaflet/hooks';
import { Polygon } from 'react-leaflet/Polygon'
import { Popup } from 'react-leaflet/Popup'

import L from "leaflet";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';

import { useTheme } from '@mui/material/styles';

import SendRoundedIcon from '@mui/icons-material/SendRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import GradientBox from '../customs/GradientBox';
import GradientText from '../customs/GradientText';

import MapFilterSelection from '../digitalInventory/map/MapFilterSelection';

import { mapBlueDotSmallMarker, mapBlueDotBigMarker } from '../../constants/markerIcons';

import { sendEmail } from '../../utils/api';

import "../../styles/Map.css";

export default function Start() {

  const theme = useTheme();

  const center = [40.42, -3.7]; // center of Madrid (to mimic the center of Spain)

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [polygons, setPolygons] = useState([]);

  const polygonRef = useRef(null);
  const polygonPointsRef = useRef([]);
  const polygonMarkersRef = useRef([]);

  useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  }, []);

  function MapEvents() {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;

        const markerIcon = polygonPointsRef.current.length === 0 ? mapBlueDotBigMarker : mapBlueDotSmallMarker;
        const marker = new L.marker([lat, lng], { icon: markerIcon }).addTo(map);

        if (polygonPointsRef.current.length === 0) {
          marker.on('click', () => {
            handleDrawPolygonClicked();
          });
        }

        polygonMarkersRef.current.push(marker);

        polygonPointsRef.current.push([lat, lng]);

        if (polygonRef.current) {
          map.removeLayer(polygonRef.current);
        }

        polygonRef.current = new L.Polygon(polygonPointsRef.current, {
          color: '#1976f2',
          fillOpacity: 0.1,
          interactive: false,
        }).addTo(map);
      },
      mousemove(e) {
        if (polygonPointsRef.current.length > 0) {
          const { lat, lng } = e.latlng;
          const updatedPoints = [...polygonPointsRef.current, [lat, lng]];

          if (polygonRef.current) {
            map.removeLayer(polygonRef.current);
          }

          polygonRef.current = new L.Polygon(updatedPoints, {
            color: '#1976f2',
            fillOpacity: 0.1,
            interactive: false,
          }).addTo(map);
        }
      },
    });
    return false;
  }

  function handleDrawPolygonClicked() {
    if (polygonRef.current && polygonMarkersRef.current) {
      polygonMarkersRef.current.forEach((marker) => marker.remove());
      polygonMarkersRef.current = [];

      const updatedPolygons = [...polygons, polygonPointsRef.current];
      setPolygons(updatedPolygons);

      polygonRef.current.remove();
      polygonRef.current = null;
      polygonPointsRef.current = [];
    }
  }

  function handleCancelDrawPolygonClicked() {
    if (polygonRef.current && polygonMarkersRef.current) {
      polygonMarkersRef.current.forEach((marker) => marker.remove());
      polygonMarkersRef.current = [];

      polygonRef.current.remove();
      polygonRef.current = null;
      polygonPointsRef.current = [];
    }
  }

  function handleDeletePolygonClicked(index) {
    const updatedPolygons = [...polygons];
    setPolygons(updatedPolygons);
  }

  function deletePolygons() {
    setPolygons([]);
    if (polygonRef.current && polygonMarkersRef.current) {
      polygonMarkersRef.current.forEach((marker) => marker.remove());
      polygonMarkersRef.current = [];

      polygonRef.current.remove();
      polygonRef.current = null;
      polygonPointsRef.current = [];
    }
  }

  function updateName(event) {
    setName(event.target.value);
  }

  function updateCompany(event) {
    setCompany(event.target.value);
  }

  function updateAddress(event) {
    setAddress(event.target.value);
  }

  function updatePhone(event) {
    setPhone(event.target.value);
  }

  function updateEmail(event) {
    setEmail(event.target.value);
  }

  function handleSendClicked() {
    sendEmail("Se ha solicitado un presupuesto con los siguientes datos:\n\n"
      + "Nombre: " + name + "\n\nEmpresa: " + company + "\n\nDirección: " + address + "\n\nTeléfono: " + phone + "\n\nCorreo: " +  email + "\n\nÁreas:\n\n" + polygons.join('\n\n'));
    setName('');
    setCompany('');
    setAddress('');
    setPhone('');
    setEmail('');
    setPolygons([]);
  }

  return (
    <Box id="contact">
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Typography variant="h4" color="grey.800" gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(2), mb: theme.spacing(6) }}>
            En solo dos simples pasos puede solicitar un presupuesto personalizado.
          </Typography>
          <Typography variant="h6" color="grey.800" gutterBottom fontWeight='medium' sx={{ my: theme.spacing(3) }}>
            1. Rellene los siguientes datos necesarios.
          </Typography>
          <Card sx={{ p: theme.spacing(3), borderRadius: theme.shape.borderRadius }}>
            <Typography variant="body1" color="grey.800" fontWeight='medium' sx={{ ml: theme.spacing(0.5), mb: '0' }}>
              Nombre
            </Typography>
            <TextField
              multiline
              margin="dense"
              id="input-text-city"
              label="Nombre"
              type="text"
              fullWidth
              variant="outlined"
              value={name}
              onChange={updateName}
            />
            <Typography variant="body1" color="grey.800" fontWeight='medium' sx={{ mt: theme.spacing(2), ml: theme.spacing(0.5), mb: '0' }}>
              Empresa
            </Typography>
            <TextField
              multiline
              margin="dense"
              id="input-text-road"
              label="Empresa"
              type="text"
              fullWidth
              variant="outlined"
              value={company}
              onChange={updateCompany}
            />
            <Typography variant="body1" color="grey.800" fontWeight='medium' sx={{ mt: theme.spacing(2), ml: theme.spacing(0.5), mb: '0' }}>
              Dirección
            </Typography>
            <TextField
              multiline
              margin="dense"
              id="input-text-road"
              label="Dirección"
              type="text"
              fullWidth
              variant="outlined"
              value={address}
              onChange={updateAddress}
            />
            <Typography variant="body1" color="grey.800" fontWeight='medium' sx={{ mt: theme.spacing(2), ml: theme.spacing(0.5), mb: '0' }}>
              Teléfono
            </Typography>
            <TextField
              multiline
              margin="dense"
              id="input-text-road"
              label="Teléfono"
              type="text"
              fullWidth
              variant="outlined"
              value={phone}
              onChange={updatePhone}
            />
            <Typography variant="body1" color="grey.800" fontWeight='medium' sx={{ mt: theme.spacing(2), ml: theme.spacing(0.5), mb: '0' }}>
              Correo electrónico
            </Typography>
            <TextField
              multiline
              margin="dense"
              id="input-text-road"
              label="Correo electrónico"
              type="text"
              fullWidth
              variant="outlined"
              value={email}
              onChange={updateEmail}
            />
          </Card>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Typography variant="h6" color="grey.800" gutterBottom fontWeight='medium' sx={{ my: theme.spacing(3) }}>
            2. Seleccione en el mapa las áreas donde desea obtener los Inventarios Digitales.
          </Typography>
          <Card sx={{ borderRadius: theme.shape.borderRadius }}>
            <MapContainer
              id="mapCursorDraw"
              center={center}
              zoom={12}
              scrollWheelZoom={true}
              style={{ height: '515px' }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxNativeZoom={19}
                maxZoom={21}
                minZoom={3}
              />
              <ScaleControl position='bottomleft' imperial={false} />
              <MapEvents />
              <MapFilterSelection
                mapFilterSelectionOn={true}
                onMapFilterSelectionClicked={() => { }}
                drawPolygonOn={true}
                onDrawPolygonClicked={handleDrawPolygonClicked}
                handleCancelDrawPolygonClicked={handleCancelDrawPolygonClicked}
                deletePolygons={deletePolygons}
                onCancelMapFilterSelectionClicked={() => { }}
                addObjectClicked={false}
              />
              {polygons.map((polyPoints, index) => (
                <Polygon key={index} positions={polyPoints} color="#1976f2" fillOpacity={0.1} >
                  <Popup closeButton={false}>
                    <Button
                      color="error"
                      size="small"
                      aria-label="Borrar"
                      sx={{ fontWeight: 'regular' }}
                      onClick={(e) => {
                        e.view.L.DomEvent.stopPropagation(e); // do this because of bug on leaflet clicks
                        handleDeletePolygonClicked(index);
                      }}
                      endIcon={<DeleteRoundedIcon />}
                    >
                      Eliminar
                    </Button>
                  </Popup>
                </Polygon>
              ))}
            </MapContainer>
          </Card>
          <Button
            color="primary"
            variant="contained"
            size="large"
            aria-label="Send mail"
            endIcon={<SendRoundedIcon sx={{ pl: '2px' }} />}
            onClick={handleSendClicked}
            sx={{ mt: theme.spacing(4), fontSize: '20px' }}
          >
            Enviar
          </Button>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <GradientText variant="h3" gutterBottom fontWeight='medium' sx={{ my: theme.spacing(10) }}>
            ¡Muchas gracias por confiar en nosotros! Nos pondremos en contacto con usted lo antes posible y le enviaremos su presupuesto.
          </GradientText>
        </Box>
      </GradientBox>
    </Box>
  );
}