import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function SingleAccessibilityTableRow(props) {

  const { editModeOn, row, onClickedAccessibilityPointIdChange, isItemSelected, handleSelectClick,
    handleEditAccessibilityPointClicked, handleDeleteAccessibilityPointClicked, openImages, onMapPositionChange } = props;

  var textReviewed = row.reviewed ? "Sí" : "No";

  var height_obstacles = "";

  if (row.height_obstacles) {
    for (const obstacle of row.height_obstacles) {
      const obstacleText = obstacle.type === "traffic_sign" ? "Señal" : "Semáforo";
      height_obstacles += obstacleText + " (Id: " + obstacle.id + ") : " + obstacle.height + " m, " 
    }

    height_obstacles = height_obstacles.slice(0, -2);
  }

  return (
    <React.Fragment>
      <TableRow
        role="checkbox"
        aria-checked={isItemSelected}
        onClick={() => {
          onMapPositionChange([row.latitude, row.longitude]);
          openImages(row.id, row.dbCity, false, row.result, row.road);
        }}
        sx={{
          backgroundColor:
            isItemSelected ?
              'faded.light'
              :
              'neutral'
        }}
      >
        <TableCell padding="checkbox" sx={{ border: 'none' }}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={(event) => {
              event.stopPropagation();
              handleSelectClick(row);
            }}
          />
        </TableCell>
        <TableCell sx={{ border: 'none' }}>{row.city}</TableCell>
        <TableCell sx={{ border: 'none' }}>{row.road}</TableCell>
        <TableCell sx={{ border: 'none' }}>{row.pavement}</TableCell>
        <TableCell sx={{ border: 'none' }}>{row.slope}</TableCell>
        <TableCell sx={{ border: 'none' }}>{row.avg_width_sidewalk}</TableCell>
        <TableCell sx={{ border: 'none', whiteSpace: 'pre-wrap' }}>{row.result.replaceAll("; ", "\n")}</TableCell>
        <TableCell sx={{ border: 'none' }}>{textReviewed}</TableCell>
        <TableCell sx={{ border: 'none' }}>{new Date(row.date).toLocaleDateString('es-ES')}</TableCell>
        <TableCell sx={{ border: 'none', width: '100px', maxWidth: '260px', whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }}>{row.notes}</TableCell>
        {editModeOn && (
          <TableCell padding="none" align="left" sx={{ paddingLeft: '5px', border: 'none' }}>
            <Tooltip title="Editar" arrow enterDelay={500}>
              <IconButton
                color="success"
                aria-label="Editar objeto"
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditAccessibilityPointClicked(row);
                }}
              >
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar" arrow enterDelay={500}>
              <IconButton
                color="error"
                aria-label="Eliminar objecto"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteAccessibilityPointClicked(row);
                }}
              >
                <DeleteRoundedIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        <TableCell padding="none" align="left" sx={{ paddingLeft: '3px', border: 'none' }}>
          <Tooltip title="Desmarcar" arrow enterDelay={500}>
            <IconButton
              color='faded.light'
              aria-label="Deseleccionar objecto"
              onClick={(event) => {
                event.stopPropagation();
                onClickedAccessibilityPointIdChange(0);
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow
        role="checkbox"
        aria-checked={isItemSelected}
        onClick={() => {
          onMapPositionChange([row.latitude, row.longitude]);
        }}
        sx={{
          backgroundColor:
            isItemSelected ?
              'faded.light'
              :
              'neutral'
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={12}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Box sx={{ mb: '6px' }}>
                <Typography variant='string' gutterBottom fontWeight='medium'>Coordenadas: </Typography>
                <Typography variant='string' gutterBottom >{row.latitude + ", " + row.longitude}</Typography>
              </Box>
              <Box sx={{ mb: '6px' }}>
                <Typography variant='string' gutterBottom fontWeight='medium'>Fecha de creación: </Typography>
                <Typography variant='string' gutterBottom >{new Date(row.creation_date).toLocaleDateString('es-ES')}</Typography>
              </Box>
              {height_obstacles !== "" && (
                <Box sx={{ mb: '6px' }}>
                  <Typography variant='string' gutterBottom fontWeight='medium'>Altura obstáculos: </Typography>
                  <Typography variant='string' gutterBottom >{height_obstacles}</Typography>
                </Box>
              )}
              <Box>
                <Typography variant='string' gutterBottom fontWeight='medium'>Notas: </Typography>
                <Typography variant='string' gutterBottom >{row.notes}</Typography>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}