import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';

import GradientBox from '../customs/GradientBox';

export default function Privacy() {

  const theme = useTheme();

  return (
    <Box id="privacy">
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 500 }}>
            Política de cookies
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            La entidad: DCOD Ingeniería S.L con NIF B-57562316 y dirección en C/Alfons el Magnànim, 29 – 07004  – Palma de Mallorca, Baleares; dirección de correo electrónico: info@gemminis.io y web www.gemminis.io,  le informa al usuario de manera expresa que, de conformidad con el art. 22.2 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSI), esta web utiliza ‘cookies’ propias y de terceros para ofrecerle una mejor experiencia y servicio. Al navegar o utilizar nuestros servicios, el usuario acepta de manera expresa el uso que hacemos de las ‘cookies’. Sin embargo, el usuario tiene la opción de impedir la generación de ‘cookies’ y la eliminación de las mismas mediante la selección de la correspondiente opción en su navegador. En caso de bloquear el uso de ‘cookies’ en su navegador, es posible que algunos servicios o funcionalidades de la página web no estén disponibles.<br />
            <br />
            *Se entienden por Cookies propias: Aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor, y desde el que se presta el servicio solicitado por el usuario.
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            ¿Qué es una ‘cookie’?
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Una ‘cookie’ es un fichero que se descarga en los sitios web y que determinadas páginas web envían al navegador, y que se almacenan en el terminal del usuario, el cual puede ser un ordenador personal, un teléfono móvil, una tableta, etc. Estos archivos permiten que el sitio web recuerde información sobre su visita, como el idioma y las opciones preferidas, lo que le puede permitir recuperar información sobre la navegación que se efectúa desde su equipo, de esta manera se pretende facilitar su próxima visita y hacer que el sitio resulte más útil al personalizar su contenido.
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            ¿Cómo se utilizan las ‘cookies’?
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Al navegar por esta página web, el usuario está aceptando que se puedan instalar ‘cookies’ en su terminal y que nos permiten conocer la siguiente información:<br />
            <br />
            - Información estadística del uso de la web.<br />
            <br />
            - El login del usuario para mantener la sesión activa en la web.<br />
            <br />
            - El formato de la web preferente en el acceso desde dispositivos móviles.<br />
            <br />
            - Las últimas búsquedas realizadas en los servicios de la web y datos de personalización de estos servicios.<br />
            <br />
            - Información sobre los anuncios que se muestran al usuario.<br />
            <br />
            - Información de las encuestas en las que ha participado el usuario<br />
            <br />
            - Datos de la conexión con las redes sociales para los usuarios que acceden con su usuario de Facebook o Twitter.
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            Tipos de ‘cookies’ utilizadas
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Esta web utiliza tanto ‘cookies’ temporales de sesión como ‘cookies’ permanentes. Las ‘cookies’ de sesión almacenan datos únicamente mientras el usuario accede a la web, y las ‘cookies’ permanentes almacenan los datos en el terminal para que sean accedidos y utilizados en más de una sesión. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión.<br />
            <br />
            Según la finalidad para la que se traten los datos obtenidos a través de las ‘cookies’, el web puede utilizar:
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            ‘Cookies’ técnicas
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan, como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            ‘Cookies’ de personalización
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario, como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, la geolocalización del terminal y la configuración regional desde donde se accede al servicio.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            ‘Cookies’ publicitarias
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado con base en criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            ‘Cookies’ de publicidad comportamental
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            ’Cookies’ de análisis estadísticos
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Son aquellas que permiten realizar el seguimiento y análisis del comportamiento de los usuarios en los sitios web. La información recogida mediante este tipo de ‘cookies’ se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, con el fin de introducir mejoras en el servicio en función de los datos de uso que hacen los usuarios.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, mt: '20px' }}>
            ‘Cookies’ de terceros
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos a través de las cookies. En algunas páginas web se pueden instalar ‘cookies’ de terceros que permiten gestionar y mejorar los servicios ofrecidos. Por ejemplo, servicios estadísticos de Google Analytics y Comscore.<br />
            <br />
            La Entidad utiliza en este sitio web las siguientes cookies que se detallan a continuación:
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, mt: '30px' }}>
            ‘Cookies’ Propias:
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            El presente Aviso Legal se refiere únicamente a la página web y contenidos de la entidad, y no se aplica a los enlaces o a las páginas web de terceros accesibles a través de la página web. La entidad no es responsable del contenido de ninguna de las páginas web de destino de un enlace, ni de ningún enlace incluido en una página web a la que se llegue desde la web de la entidad.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, mt: '30px' }}>
            ‘Cookies’ de terceros
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            El presente Aviso Legal se refiere únicamente a la página web y contenidos de la entidad, y no se aplica a los enlaces o a las páginas web de terceros accesibles a través de la página web. La entidad no es responsable del contenido de ninguna de las páginas web de destino de un enlace, ni de ningún enlace incluido en una página web a la que se llegue desde la web de la entidad.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, mt: '30px' }}>
            ‘Cookies’ de Google
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            El presente Aviso Legal se refiere únicamente a la página web y contenidos de la entidad, y no se aplica a los enlaces o a las páginas web de terceros accesibles a través de la página web. La entidad no es responsable del contenido de ninguna de las páginas web de destino de un enlace, ni de ningún enlace incluido en una página web a la que se llegue desde la web de la entidad.
          </Typography>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mt: '30px' }}>
            ¿Cómo administrar ‘cookies’ en el navegador?
          </Typography>
          <Typography variant="body1" color="grey.800" gutterBottom>
            El usuario tiene la opción de permitir, bloquear o eliminar las ‘cookies’ instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su terminal.
          </Typography>
        </Box>
      </GradientBox>
    </Box>
  );
}