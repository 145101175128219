import React, { useState, useEffect, forwardRef } from 'react';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale'

import dayjs from 'dayjs';
import "dayjs/locale/es";

import { pavements } from '../../../constants/pavements.js';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const sidewalkOptions = ['Acera accesible', 'Acera no accesible', 'No hay acera'];
const reviewedOptions = [true, false];

export default function DialogEditAccessibilityPoint(props) {

  const { open, handleClose, accessibilityPoint, updateAccessibilityPoint } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const [city, setCity] = useState('');
  const [road, setRoad] = useState('');
  const [pavement, setPavement] = useState('');
  const [slope, setSlope] = useState('');
  const [avgWidthSidewalk, setAvgWidthSidewalk] = useState('');
  const [sidewalk, setSidewalk] = useState('');
  const [accessibilityIssues, setAccessibilityIssues] = useState({
    width: {
      widthChecked: false,
      fenceChecked: false,
      barrierChecked: false,
      vegetationChecked: false,
      billboardChecked: false,
      hydrantChecked: false,
      junctionBoxChecked: false,
      phoneChecked: false,
      lightChecked: false,
      poleChecked: false,
      utilityPoleChecked: false,
    },
    height: {
      signChecked: false,
      trafficLightChecked: false,
    },
    crosswalk: {
      loweredChecked: false,
      duropanotChecked: false,
    }
  });
  const [reviewed, setReviewed] = useState(true);
  const [date, setDate] = useState('');
  const [notes, setNotes] = useState('');

  const [pavementDisabled, setPavementDisabled] = useState(false);
  const [widthDisabled, setWidthDisabled] = useState(false);
  const [accessibilityIssuesDisabled, setAccessibilityIssuesDisabled] = useState(false);

  const [errorCity, setErrorCity] = useState(false);
  const [errorRoad, setErrorRoad] = useState(false);
  const [errorPavement, setErrorPavement] = useState(false);
  const [errorSlope, setErrorSlope] = useState(false);
  const [errorWidth, setErrorWidth] = useState(false);
  const [errorIssues, setErrorIssues] = useState(false);

  useEffect(() => {
    if (open && accessibilityPoint !== undefined) {

      setCity(accessibilityPoint.city);
      setRoad(accessibilityPoint.road);
      setPavement(accessibilityPoint.pavement);
      setSlope(accessibilityPoint.slope);
      setAvgWidthSidewalk(accessibilityPoint.avg_width_sidewalk);

      if (accessibilityPoint.has_sidewalk) {
        setPavementDisabled(false);
        setWidthDisabled(false);
        if (accessibilityPoint.accessible) {
          setSidewalk('Acera accesible');
          setAccessibilityIssuesDisabled(true);
        } else {
          setSidewalk('Acera no accesible');
          setAccessibilityIssuesDisabled(false);
        }
      } else {
        setSidewalk('No hay acera');
        setPavementDisabled(true);
        setWidthDisabled(true);
        setAccessibilityIssuesDisabled(true);
      }

      setAccessibilityIssues({
        width: {
          widthChecked: accessibilityPoint.not_accessible_reason.width.width,
          fenceChecked: accessibilityPoint.not_accessible_reason.width.fence,
          barrierChecked: accessibilityPoint.not_accessible_reason.width.barrier,
          vegetationChecked: accessibilityPoint.not_accessible_reason.width.vegetation,
          billboardChecked: accessibilityPoint.not_accessible_reason.width.billboard,
          hydrantChecked: accessibilityPoint.not_accessible_reason.width.hydrant,
          junctionBoxChecked: accessibilityPoint.not_accessible_reason.width.junction_box,
          phoneChecked: accessibilityPoint.not_accessible_reason.width.phone,
          lightChecked: accessibilityPoint.not_accessible_reason.width.light,
          poleChecked: accessibilityPoint.not_accessible_reason.width.pole,
          utilityPoleChecked: accessibilityPoint.not_accessible_reason.width.utility_pole,
        },
        height: {
          signChecked: accessibilityPoint.not_accessible_reason.height.sign,
          trafficLightChecked: accessibilityPoint.not_accessible_reason.height.traffic_Light,
        },
        crosswalk: {
          loweredChecked: accessibilityPoint.not_accessible_reason.crosswalk.lowered,
          duropanotChecked: accessibilityPoint.not_accessible_reason.crosswalk.duropanot,
        }
      });

      setReviewed(accessibilityPoint.reviewed);
      setDate(dayjs(accessibilityPoint.date).valueOf());

      let newNotes = accessibilityPoint?.notes;
      if (newNotes === undefined) {
        newNotes = "";
      }
      setNotes(newNotes);

      setErrorCity(false);
      setErrorRoad(false);
      setErrorPavement(false);
      setErrorSlope(false);
      setErrorWidth(false);
      setErrorIssues(false);
      setOpenDialog(true);
    }
  }, [open, accessibilityPoint]);

  function closeDialog() {
    setOpenDialog(false);
    handleClose();
  }

  function updateCity(event) {
    setCity(event.target.value);
  }

  function updateRoad(event) {
    setRoad(event.target.value);
  }

  function updatePavement(event) {
    setPavement(event.target.value);
  }

  function updateSlope(event) {
    let text = event.target.value.trim().replace(",", ".");
    setSlope(text);
  }

  function updateAvgWidthSidewalk(event) {
    let text = event.target.value.trim().replace(",", ".");
    setAvgWidthSidewalk(text);
  }

  function updateSidewalk(event) {
    let newValue = event.target.value;
    setSidewalk(newValue);

    switch (newValue) {
      case 'Acera accesible':
        setAccessibilityIssues({
          width: {
            widthChecked: false,
            fenceChecked: false,
            barrierChecked: false,
            vegetationChecked: false,
            billboardChecked: false,
            hydrantChecked: false,
            junctionBoxChecked: false,
            phoneChecked: false,
            lightChecked: false,
            poleChecked: false,
            utilityPoleChecked: false,
          },
          height: {
            signChecked: false,
            trafficLightChecked: false,
          },
          crosswalk: {
            loweredChecked: false,
            duropanotChecked: false,
          }
        });
        setPavementDisabled(false);
        setWidthDisabled(false);
        setAccessibilityIssuesDisabled(true);
        break;
      case 'Acera no accesible':
        setPavementDisabled(false);
        setWidthDisabled(false);
        setAccessibilityIssuesDisabled(false);
        break;
      case 'No hay acera':
        setPavement('');
        setAvgWidthSidewalk('');
        setAccessibilityIssues({
          width: {
            widthChecked: false,
            fenceChecked: false,
            barrierChecked: false,
            vegetationChecked: false,
            billboardChecked: false,
            hydrantChecked: false,
            junctionBoxChecked: false,
            phoneChecked: false,
            lightChecked: false,
            poleChecked: false,
            utilityPoleChecked: false,
          },
          height: {
            signChecked: false,
            trafficLightChecked: false,
          },
          crosswalk: {
            loweredChecked: false,
            duropanotChecked: false,
          }
        });
        setPavementDisabled(true);
        setWidthDisabled(true);
        setAccessibilityIssuesDisabled(true);
        break;
      default:
        setPavement('');
        setAvgWidthSidewalk('');
        setAccessibilityIssues({
          width: {
            widthChecked: false,
            fenceChecked: false,
            barrierChecked: false,
            vegetationChecked: false,
            billboardChecked: false,
            hydrantChecked: false,
            junctionBoxChecked: false,
            phoneChecked: false,
            lightChecked: false,
            poleChecked: false,
            utilityPoleChecked: false,
          },
          height: {
            signChecked: false,
            trafficLightChecked: false,
          },
          crosswalk: {
            loweredChecked: false,
            duropanotChecked: false,
          }
        });
        setPavementDisabled(true);
        setWidthDisabled(true);
        setAccessibilityIssuesDisabled(true);
        break;
    }
  }

  function handleIssuesChange(event) {
    const names = event.target.name.split("-");
    const category = names[0];
    const issue = names[1];

    setAccessibilityIssues({
      ...accessibilityIssues,
      [category]: {
        ...accessibilityIssues[category],
        [issue]: event.target.checked
      }
    });
  };

  function updateReviewed(event) {
    setReviewed(event.target.value);
  }

  function updateNotes(event) {
    setNotes(event.target.value);
  }

  function handleSaveEditClicked() {
    let allValuesFilled = true;

    if (city === '') {
      setErrorCity(true);
      allValuesFilled = false;
    } else {
      setErrorCity(false);
    }

    if (road === '') {
      setErrorRoad(true);
      allValuesFilled = false;
    } else {
      setErrorRoad(false);
    }

    if (!pavementDisabled && pavement === '') {
      setErrorPavement(true);
      allValuesFilled = false;
    } else {
      setErrorPavement(false);
    }

    if (!/^(?:\d+|\d+\.\d+)$/.test(slope)) {
      setErrorSlope(true);
      allValuesFilled = false;
    } else {
      setErrorSlope(false);
    }

    if (!widthDisabled && !/^(?:\d+|\d+\.\d+)$/.test(avgWidthSidewalk)) {
      setErrorWidth(true);
      allValuesFilled = false;
    } else {
      setErrorWidth(false);
    }

    if (!accessibilityIssuesDisabled &&
      [
        accessibilityIssues.width.widthChecked,
        accessibilityIssues.width.fenceChecked,
        accessibilityIssues.width.barrierChecked,
        accessibilityIssues.width.vegetationChecked,
        accessibilityIssues.width.billboardChecked,
        accessibilityIssues.width.hydrantChecked,
        accessibilityIssues.width.junctionBoxChecked,
        accessibilityIssues.width.phoneChecked,
        accessibilityIssues.width.lightChecked,
        accessibilityIssues.width.poleChecked,
        accessibilityIssues.width.utilityPoleChecked,
        accessibilityIssues.height.signChecked,
        accessibilityIssues.height.trafficLightChecked,
        accessibilityIssues.crosswalk.loweredChecked,
        accessibilityIssues.crosswalk.duropanotChecked,
      ].filter((v) => v).length === 0) {

        setErrorIssues(true);
        allValuesFilled = false;
    } else {
      setErrorIssues(false);
    }

    if (allValuesFilled) {
      let hasSidewalk = false;
      let isAccessible = false;

      if (sidewalk !== "No hay acera") {
        hasSidewalk = true;
        if (sidewalk !== "Acera no accesible") {
          isAccessible = true;
        }
      }

      let values = {
        id: accessibilityPoint.id,
        latitude: accessibilityPoint.latitude,
        longitude: accessibilityPoint.longitude,
        city: city,
        road: road,
        pavement: pavement,
        slope: slope,
        avg_width_sidewalk: avgWidthSidewalk,
        has_sidewalk: hasSidewalk,
        accessible: isAccessible,
        not_accessible_reason: {
          width: {
            width: accessibilityIssues.width.widthChecked,
            fence: accessibilityIssues.width.fenceChecked,
            barrier: accessibilityIssues.width.barrierChecked,
            vegetation: accessibilityIssues.width.vegetationChecked,
            billboard: accessibilityIssues.width.billboardChecked,
            hydrant: accessibilityIssues.width.hydrantChecked,
            junction_box: accessibilityIssues.width.junctionBoxChecked,
            phone: accessibilityIssues.width.phoneChecked,
            light: accessibilityIssues.width.lightChecked,
            pole: accessibilityIssues.width.poleChecked,
            utility_pole: accessibilityIssues.width.utilityPoleChecked,
          },
          height: {
            sign: accessibilityIssues.height.signChecked,
            traffic_light: accessibilityIssues.height.trafficLightChecked,
          },
          crosswalk: {
            lowered: accessibilityIssues.crosswalk.loweredChecked,
            duropanot: accessibilityIssues.crosswalk.duropanotChecked,
          }
        },
        height_obstacles: accessibilityPoint.height_obstacles,
        date: date,
        creation_date: accessibilityPoint.creation_date,
        notes: notes,
        image: accessibilityPoint.image,
        reviewed: reviewed,
      }
      closeDialog();
      updateAccessibilityPoint(accessibilityPoint, values);
    }
  }

  return (
    <Dialog open={openDialog} onClose={closeDialog} TransitionComponent={Transition}>
      <DialogTitle>Editar punto de accesibilidad</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          margin="dense"
          id="input-text-city"
          label="Ciudad"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={city}
          error={errorCity}
          onChange={updateCity}
        />
        <TextField
          multiline
          margin="dense"
          id="input-text-road"
          label="Calle"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={road}
          error={errorRoad}
          onChange={updateRoad}
        />
        <Box sx={{ minWidth: '400px', mt: '12px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-pavement-label">Pavimento</InputLabel>
            <Select
              labelId="select-pavement-label"
              id="select-pavement"
              value={pavement}
              label="Pavimento"
              disabled={pavementDisabled}
              error={errorPavement}
              onChange={updatePavement}
            >
              {pavements.map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <TextField
          multiline
          margin="dense"
          id="input-text-slope"
          label="Pendiente (%)"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={slope}
          error={errorSlope}
          onChange={updateSlope}
        />
        <TextField
          multiline
          margin="dense"
          id="input-text-width"
          label="Anchura acera (m)"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={avgWidthSidewalk}
          disabled={widthDisabled}
          error={errorWidth}
          onChange={updateAvgWidthSidewalk}
        />
        <Box sx={{ minWidth: '400px', mt: '12px', mb: '14px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-type-label">Accesibilidad</InputLabel>
            <Select
              labelId="select-accessibility-label"
              id="select-accessibility"
              value={sidewalk}
              label="Accesible"
              onChange={updateSidewalk}
            >
              {sidewalkOptions.map((option, index) => {
                return (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            disabled={accessibilityIssuesDisabled}
            error={errorIssues}
            component="fieldset"
            sx={{ mt: '12px' }}
            variant="standard"
          >
            {errorIssues && (
              <FormLabel component="legend">Selecciona mínimo una</FormLabel>
            )}
            <FormGroup>
              <Typography sx={{ mt: '10px', mb: '6px' }}>Anchura de acera insuficiente (&lt; 1.8 m)</Typography>
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.widthChecked} onChange={handleIssuesChange} name="width-widthChecked" />
                }
                label="Anchura general insuficiente"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.fenceChecked} onChange={handleIssuesChange} name="width-fenceChecked" />
                }
                label="Por valla"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.barrierChecked} onChange={handleIssuesChange} name="width-barrierChecked" />
                }
                label="Por barrera"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.vegetationChecked} onChange={handleIssuesChange} name="width-vegetationChecked" />
                }
                label="Por vegetación"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.billboardChecked} onChange={handleIssuesChange} name="width-billboardChecked" />
                }
                label="Por cartel"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.hydrantChecked} onChange={handleIssuesChange} name="width-hydrantChecked" />
                }
                label="Por hidrante"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.junctionBoxChecked} onChange={handleIssuesChange} name="width-junctionBoxChecked" />
                }
                label="Por caja eléctrica"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.phoneChecked} onChange={handleIssuesChange} name="width-phoneChecked" />
                }
                label="Por cabina telefónica"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.lightChecked} onChange={handleIssuesChange} name="width-lightChecked" />
                }
                label="Anchura insuficiente por farola"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.poleChecked} onChange={handleIssuesChange} name="width-poleChecked" />
                }
                label="Por poste"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.width.utilityPoleChecked} onChange={handleIssuesChange} name="width-utilityPoleChecked" />
                }
                label="Por poste de servicios"
              />
              <Typography sx={{ mt: '10px', mb: '6px' }}>Altura de obstáculos insuficiente (&lt; 2.2 m)</Typography>
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.height.signChecked} onChange={handleIssuesChange} name="height-signChecked" />
                }
                label="Por señal"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.height.trafficLightChecked} onChange={handleIssuesChange} name="height-trafficLightChecked" />
                }
                label="Por semáforo"
              />
              <Typography sx={{ mt: '10px', mb: '6px' }}>Paso de cebra no adaptado</Typography>
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.crosswalk.loweredChecked} onChange={handleIssuesChange} name="crosswalk-loweredChecked" />
                }
                label="Sin rebaje"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={accessibilityIssues.crosswalk.duropanotChecked} onChange={handleIssuesChange} name="crosswalk-duropanotChecked" />
                }
                label="Sin duropanot"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: '400px', mt: '14px', mb: '14px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-type-label">Revisado</InputLabel>
            <Select
              labelId="select-reviewed-label"
              id="select-reviewed"
              value={reviewed}
              label="Revisado"
              onChange={updateReviewed}
            >
              {reviewedOptions.map((option, index) => {
                let textReviewed;
                if (option) textReviewed = "Sí"
                else textReviewed = "No"
                return (
                  <MenuItem key={index} value={option}>{textReviewed}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <DatePicker
            label="Fecha"
            value={date}
            onChange={(newValue) => {
              setDate(dayjs(newValue).startOf('day').valueOf());
            }}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </LocalizationProvider>
        <TextField
          multiline
          margin="dense"
          id="input-text-notes"
          label="Notas"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={notes}
          onChange={updateNotes}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeDialog}>Cancelar</Button>
        <Button color="success" variant="contained" onClick={handleSaveEditClicked}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}