import React, { useEffect, useState, useRef, Fragment } from 'react';

import Button from '@mui/material/Button';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import { useMapEvents } from 'react-leaflet/hooks';
import { Polygon } from 'react-leaflet/Polygon'
import { Popup } from 'react-leaflet/Popup'

import L from "leaflet";

import MarkerClusterGroup from 'react-leaflet-cluster'

import "leaflet/dist/leaflet.css";
import "../../../styles/Map.css"

import MapMover from './MapMover';
import ObjectMarkerWrapper from './ObjectMarkerWrapper';
import MyZoomControls from './MyZoomControls';
import Location from './Location';
import MapFilterSelection from './MapFilterSelection';
import CreateObject from './CreateObject';
import DialogCreateObject from '../dialogs/DialogCreateObject';
import AccessibilityMarkers from './AccessibilityMarkers';

import { mapBlueDotSmallMarker, mapBlueDotBigMarker } from '../../../constants/markerIcons';

export default function Map(props) {

  const { selectedMode, editModeOn, mapPosition, onMapPositionChange, filteredObjects, selectedObjects, hoveringObjectId,
    onHoveringObjectIdChange, popupOpenObjectId, onPopupOpenObjectIdChange, insertObject, updateObject,
    handleEditObjectClicked, handleDeleteObjectClicked, filteredAccessibility,
    popupOpenAccessibilityPointId, onPopupOpenAccessibilityPointIdChange,
    openImages, imagesOpenId, mapFilterSelectionOn, onMapFilterSelectionClicked, onCancelMapFilterSelectionClicked,
    selectMapFilterData, addMultipleMapFilterDataPoints, deleteMultipleMapFilterDataPoints } = props;

  const [addObjectClicked, setAddObjectClicked] = useState(false);
  const [dialogCreateOpen, setDialogCreateOpen] = useState(false);

  const [drawPolygonOn, setDrawPolygonOn] = useState(false);

  const mapCursor = useRef("mapCursorDefault");
  const newMarker = useRef(L.marker([0, 0]));

  const [polygons, setPolygons] = useState([]);

  const polygonRef = useRef(null);
  const polygonPointsRef = useRef([]);
  const polygonMarkersRef = useRef([]);

  const center = [40.42, -3.7]; // center of Madrid (to mimic the center of Spain)

  useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
  }, []);

  useEffect(() => {
    setPolygons([]);
  }, [selectedMode]);

  function MapEvents() {
    const map = useMapEvents({
      click(e) {
        if (drawPolygonOn) {
          const { lat, lng } = e.latlng;

          const markerIcon = polygonPointsRef.current.length === 0 ? mapBlueDotBigMarker : mapBlueDotSmallMarker;
          const marker = new L.marker([lat, lng], { icon: markerIcon }).addTo(map);

          if (polygonPointsRef.current.length === 0) {
            marker.on('click', () => {
              handleDrawPolygonClicked();
            });
          }

          polygonMarkersRef.current.push(marker);

          polygonPointsRef.current.push([lat, lng]);

          if (polygonRef.current) {
            map.removeLayer(polygonRef.current);
          }

          polygonRef.current = new L.Polygon(polygonPointsRef.current, {
            color: '#1976f2',
            fillOpacity: 0.1,
            interactive: false,
          }).addTo(map);
        } else {
          map.setView(e.latlng);

          if (addObjectClicked) {
            let { lat, lng } = newMarker.current.getLatLng();
            newMarker.current.setLatLng(e.latlng);
            if (lat === 0 || lng === 0) newMarker.current.addTo(map);
          }
        }
      },
      mousemove(e) {
        if (polygonPointsRef.current.length > 0) {
          const { lat, lng } = e.latlng;
          const updatedPoints = [...polygonPointsRef.current, [lat, lng]];

          if (polygonRef.current) {
            map.removeLayer(polygonRef.current);
          }

          polygonRef.current = new L.Polygon(updatedPoints, {
            color: '#1976f2',
            fillOpacity: 0.1,
            interactive: false,
          }).addTo(map);
        }
      },
    });
    return false;
  }

  function handleAddObjectClicked() {
    setAddObjectClicked(!addObjectClicked);
    updateCursor("marker");
  }

  function handleMarkerSetClicked() {
    let { lat, lng } = newMarker.current.getLatLng();
    if (lat !== 0 || lng !== 0) setDialogCreateOpen(true);
  }

  function handleCancelAddObjectClicked() {
    setAddObjectClicked(!addObjectClicked);
    updateCursor("default");
    newMarker.current.remove();
    newMarker.current.setLatLng([0, 0]);
  }

  function handleCloseDialogCreate() {
    setDialogCreateOpen(false);
    setAddObjectClicked(false);
    updateCursor("default");
    newMarker.current.remove();
    newMarker.current.setLatLng([0, 0]);
  };

  function handleDrawPolygonClicked() {
    if (drawPolygonOn && polygonRef.current && polygonMarkersRef.current) {
      polygonMarkersRef.current.forEach((marker) => marker.remove());
      polygonMarkersRef.current = [];

      const updatedPolygons = [...polygons, polygonPointsRef.current];
      setPolygons(updatedPolygons);

      const markersInsidePolygon = [];
      const selectedModeData = selectedMode === 'objects' ? filteredObjects : filteredAccessibility;

      selectedModeData.forEach((data) => {
        if (isPointInsidePolygon(data.latitude, data.longitude, polygonPointsRef.current)) {
          markersInsidePolygon.push(data);
        }
      });

      addMultipleMapFilterDataPoints(markersInsidePolygon);

      polygonRef.current.remove();
      polygonRef.current = null;
      polygonPointsRef.current = [];
      updateCursor("default");
    } else {
      updateCursor("draw");
    }

    setDrawPolygonOn(!drawPolygonOn);
  }

  function handleCancelDrawPolygonClicked() {
    if (polygonRef.current && polygonMarkersRef.current) {
      polygonMarkersRef.current.forEach((marker) => marker.remove());
      polygonMarkersRef.current = [];

      polygonRef.current.remove();
      polygonRef.current = null;
      polygonPointsRef.current = [];
    }

    setDrawPolygonOn(!drawPolygonOn);
    updateCursor("default");
  }

  function handleDeletePolygonClicked(index) {
    const updatedPolygons = [...polygons];

    let deletedPolygonPoints = updatedPolygons.splice(index, 1)[0];

    const markersInsidePolygon = [];
    const selectedModeData = selectedMode === 'objects' ? filteredObjects : filteredAccessibility;

    selectedModeData.forEach((data) => {
      if (isPointInsidePolygon(data.latitude, data.longitude, deletedPolygonPoints)) {
        markersInsidePolygon.push(data);
      }
    });

    deleteMultipleMapFilterDataPoints(markersInsidePolygon);

    setPolygons(updatedPolygons);
  }

  function deletePolygons() {
    setPolygons([]);
    setDrawPolygonOn(false);
    if (polygonRef.current && polygonMarkersRef.current) {
      polygonMarkersRef.current.forEach((marker) => marker.remove());
      polygonMarkersRef.current = [];

      polygonRef.current.remove();
      polygonRef.current = null;
      polygonPointsRef.current = [];
    }
    updateCursor("default");
  }

  function updateCursor(value) {
    switch (value) {
      case "default":
        document.getElementById(mapCursor.current).id = "mapCursorDefault";
        mapCursor.current = "mapCursorDefault";
        break;
      case "marker":
        document.getElementById(mapCursor.current).id = "mapCursorMarker";
        mapCursor.current = "mapCursorMarker";
        break;
      case "draw":
        document.getElementById(mapCursor.current).id = "mapCursorDraw";
        mapCursor.current = "mapCursorDraw";
        break;
      default:
        document.getElementById(mapCursor.current).id = "mapCursorDefault";
        mapCursor.current = "mapCursorDefault";
        break;
    }
  }

  return (
    <div>
      <MapContainer
        id="mapCursorDefault"
        center={center}
        zoom={12}
        zoomControl={false}
        scrollWheelZoom={true}
        style={{ height: '515px' }}
        renderer={L.canvas()}
        preferCanvas={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={21}
          minZoom={3}
        />
        <MyZoomControls />
        <Location />
        <MapMover
          mapPosition={mapPosition}
          onMapPositionChange={onMapPositionChange}
          data={selectedMode === 'objects' ? filteredObjects : filteredAccessibility}
        />
        <MapEvents />
        <MapFilterSelection
          mapFilterSelectionOn={mapFilterSelectionOn}
          onMapFilterSelectionClicked={onMapFilterSelectionClicked}
          drawPolygonOn={drawPolygonOn}
          onDrawPolygonClicked={handleDrawPolygonClicked}
          handleCancelDrawPolygonClicked={handleCancelDrawPolygonClicked}
          deletePolygons={deletePolygons}
          onCancelMapFilterSelectionClicked={onCancelMapFilterSelectionClicked}
          addObjectClicked={addObjectClicked}
        />
        {polygons.map((polyPoints, index) => (
          <Polygon key={index} positions={polyPoints} color="#1976f2" fillOpacity={0.1} >
            <Popup closeButton={false}>
              <Button
                color="error"
                size="small"
                aria-label="Borrar"
                sx={{ fontWeight: 'regular' }}
                onClick={(e) => {
                  e.view.L.DomEvent.stopPropagation(e); // do this because of bug on leaflet clicks
                  handleDeletePolygonClicked(index);
                }}
                endIcon={<DeleteRoundedIcon />}
              >
                Desmarcar
              </Button>
            </Popup>
          </Polygon>
        ))}
        {selectedMode === 'objects' ?
          <Fragment>
            {editModeOn && (
              <Fragment>
                <CreateObject
                  addObjectClicked={addObjectClicked}
                  onAddObjectClicked={handleAddObjectClicked}
                  onMarkerSetClicked={handleMarkerSetClicked}
                  onCancelClicked={handleCancelAddObjectClicked}
                  drawPolygonOn={drawPolygonOn}
                />
                <DialogCreateObject
                  dialogCreateOpen={dialogCreateOpen}
                  handleCloseDialogCreate={handleCloseDialogCreate}
                  coordinates={newMarker.current.getLatLng()}
                  insertObject={insertObject}
                />
              </Fragment>
            )}
            <MarkerClusterGroup
              chunkedLoading
              maxClusterRadius={30}
            >
              {filteredObjects.map((value) => {
                return (
                  <ObjectMarkerWrapper
                    key={value.id}
                    editModeOn={editModeOn}
                    object={value}
                    hoveringObjectId={hoveringObjectId}
                    popupOpenObjectId={popupOpenObjectId}
                    selectedObjects={selectedObjects}
                    onHoveringObjectIdChange={onHoveringObjectIdChange}
                    onPopupOpenObjectIdChange={onPopupOpenObjectIdChange}
                    updateObject={updateObject}
                    handleEditObjectClicked={handleEditObjectClicked}
                    handleDeleteObjectClicked={handleDeleteObjectClicked}
                    openImages={openImages}
                    mapFilterSelectionOn={mapFilterSelectionOn}
                    selectMapFilterData={selectMapFilterData}
                  />
                );
              })}
            </MarkerClusterGroup>
          </Fragment>
          :
          <AccessibilityMarkers
            filteredAccessibility={filteredAccessibility}
            popupOpenAccessibilityPointId={popupOpenAccessibilityPointId}
            onPopupOpenAccessibilityPointIdChange={onPopupOpenAccessibilityPointIdChange}
            openImages={openImages}
            imagesOpenId={imagesOpenId}
            mapFilterSelectionOn={mapFilterSelectionOn}
            selectMapFilterData={selectMapFilterData}
          />
        }
      </MapContainer>
    </div>
  );
}

function isPointInsidePolygon(lat, lng, polygonPoints) {
  var x = lat, y = lng;

  var inside = false;
  for (var i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
    var xi = polygonPoints[i][0], yi = polygonPoints[i][1];
    var xj = polygonPoints[j][0], yj = polygonPoints[j][1];

    var intersect = ((yi > y) !== (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
};