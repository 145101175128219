import React from "react";

import Papa from 'papaparse';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

export default function ExportData(props) {

  const { objects, filteredData, selectedData } = props;

  const handleExportCSV = () => {
    let data;
    if (selectedData.length > 0) data = selectedData;
    else data = filteredData;

    let modifiedData = [];

    let fileName = "gemminis_";
    let cities = "";
    if(objects) {
      modifiedData = data.map((row) => {
        if (!cities.includes(row.city)) {
          cities += "-" + row.city;
        }

        return {
          Latitud: row.latitude,
          Longitud: row.longitude,
          Ciudad: row.city,
          Calle: row.road,
          Categoria: row.type,
          Subcategoria: row.subtype,
          Elementos: row.items,
          Revisado: row.reviewed ? "Si" : "No",
          Fecha: new Date(row.date).toLocaleDateString('es-ES'),
          Fecha_creacion: new Date(row.creation_date).toLocaleDateString('es-ES'),
          Notas: row.notes,
        };
      });

      fileName = fileName + "objetos" + cities + ".csv";

    } else {
      modifiedData = data.map((row) => {
        if (!cities.includes(row.city)) {
          cities += "-" + row.city;
        }

        var height_obstacles = "";

        if (row.height_obstacles) {
          for (const obstacle of row.height_obstacles) {
            const obstacleText = obstacle.type === "traffic_sign" ? "Señal" : "Semáforo";
            height_obstacles += obstacleText + " (Id: " + obstacle.id + ") : " + obstacle.height + " m, " 
          }
      
          height_obstacles = height_obstacles.slice(0, -2);
        }
  
        return {
          Latitud: row.latitude,
          Longitud: row.longitude,
          Ciudad: row.city,
          Calle: row.road,
          Pavimento_acera: row.pavement,
          Pendiente_porcentaje: row.slope,
          Anchura_acera_metros: row.avg_width_sidewalk,
          Accesibilidad: row.result,
          Altura_objetos: height_obstacles,
          Revisado: row.reviewed ? "Sí" : "No",
          Fecha: new Date(row.date).toLocaleDateString('es-ES'),
          Fecha_creacion: new Date(row.creation_date).toLocaleDateString('es-ES'),
          Notas: row.notes,
        };
      });

      fileName = fileName + "accesibilidad" + cities + ".csv";
    }

    const csv = Papa.unparse(modifiedData);

    csv.replace("Pavimento_acera", "Pavimento acera");
    csv.replace("Pendiente_porcentaje", "Pendiente (%)");
    csv.replace("Anchura_acera_metros", "Anchura acera (m)");
    csv.replace("Altura_objetos", "Altura objetos");

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  };

  return (
    <Tooltip
      title={selectedData.length > 0 ? "Descargar seleccionados" : "Descargar tabla"}
      arrow
      enterDelay={500}
    >
      <IconButton
        color="success"
        aria-label="Descargar seleccionados"
        onClick={handleExportCSV}
      >
        <FileDownloadRoundedIcon sx={{ fontSize: 30 }} />
      </IconButton>
    </Tooltip>
  );
}