import L from "leaflet";

import MapMarkerIcon from '../assets/markerIcons/map-marker.png';
import MapMarkerBlueIcon from '../assets/markerIcons/map-marker-blue.png';
import MapBlueDotMarkerIcon from '../assets/markerIcons/map-blue-dot.png';

import BikeParkingDarkIcon from '../assets/markerIcons/bike-parking-dark.png';
import BenchDarkIcon from '../assets/markerIcons/bench-dark.png';
import CCTVDarkIcon from '../assets/markerIcons/cctv-dark.png';
import ConstructionTrashBinDarkIcon from '../assets/markerIcons/construction-trash-bin-dark.png';
import TrashBinDarkIcon from '../assets/markerIcons/trash-bin-dark.png';
import TrashCanDarkIcon from '../assets/markerIcons/trash-can-dark.png';
import StreetLampDarkIcon from '../assets/markerIcons/street-lamp-dark.png';
import FireHydrantDarkIcon from '../assets/markerIcons/fire-hydrant-dark.png';
import ElectricalBoxDarkIcon from '../assets/markerIcons/electrical-box-dark.png';
import DrainageDarkIcon from '../assets/markerIcons/drainage-dark.png';
import CrosswalkDarkIcon from '../assets/markerIcons/crosswalk-dark.png';
import TrafficDarkDarkIcon from '../assets/markerIcons/traffic-light-dark.png';
import StreetArchetteDarkIcon from '../assets/markerIcons/street-archette-dark.png';
import StreetWellDarkIcon from '../assets/markerIcons/street-well-dark.png';
import YeildSignDarkIcon from '../assets/markerIcons/yeild-sign-dark.png';
import NoEntrySignDarkIcon from '../assets/markerIcons/no-entry-sign-dark.png';
import NoParkingSignDarkIcon from '../assets/markerIcons/no-parking-sign-dark.png';
import EndOfLimitationsSignDarkIcon from '../assets/markerIcons/end-of-limitations-sign-dark.png';
import MandatorySignDarkIcon from '../assets/markerIcons/mandatory-sign-dark.png';
import CrosswalkSignDarkIcon from '../assets/markerIcons/crosswalk-sign-dark.png';
import DangerSignDarkIcon from '../assets/markerIcons/danger-sign-dark.png';
import ForbiddenSignDarkIcon from '../assets/markerIcons/forbidden-sign-dark.png';
import StopSignDarkIcon from '../assets/markerIcons/stop-sign-dark.png';
import SpeedLimitSignDarkIcon from '../assets/markerIcons/speed-limit-sign-dark.png';
import DirectionsSignDarkIcon from '../assets/markerIcons/directions-sign-dark.png';
import ElectricalTowerDarkIcon from '../assets/markerIcons/electrical-tower-dark.png';

export const mapBlueDotSmallMarker = new L.Icon({
  iconUrl: MapBlueDotMarkerIcon,
  iconSize: [10, 10],
  iconAnchor: [5, 5],
  popupAnchor: [1, -12],
});

export const mapBlueDotBigMarker = new L.Icon({
  iconUrl: MapBlueDotMarkerIcon,
  iconSize: [16, 16],
  iconAnchor: [8, 8],
  popupAnchor: [1, -20],
});

export const getMarkerIcons = (type, subtype) => {
  let myIcon
  let hoveredIcon;
  let draggableIcon = new L.Icon({
    iconUrl: MapMarkerBlueIcon,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [1, -52],
  });

  switch (type) {
    case "Aparca bicis":
      myIcon = new L.Icon({
        iconUrl: BikeParkingDarkIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [1, -52],
      });
      hoveredIcon = new L.Icon({
        iconUrl: BikeParkingDarkIcon,
        iconSize: [52, 52],
        iconAnchor: [26, 52],
        popupAnchor: [1, -52],
      });
      break;
    case "Banco":
      myIcon = new L.Icon({
        iconUrl: BenchDarkIcon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [1, -34],
      });
      hoveredIcon = new L.Icon({
        iconUrl: BenchDarkIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [1, -34],
      });
      break;
    case "Camara CCTV":
      myIcon = new L.Icon({
        iconUrl: CCTVDarkIcon,
        iconSize: [36, 36],
        iconAnchor: [18, 36],
        popupAnchor: [1, -46],
      });
      hoveredIcon = new L.Icon({
        iconUrl: CCTVDarkIcon,
        iconSize: [46, 46],
        iconAnchor: [23, 46],
        popupAnchor: [1, -46],
      });
      break;
    case "Contenedor - Papelera":
      switch (subtype) {
        case "Contenedor":
          myIcon = new L.Icon({
            iconUrl: TrashBinDarkIcon,
            iconSize: [34, 30],
            iconAnchor: [17, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: TrashBinDarkIcon,
            iconSize: [44, 40],
            iconAnchor: [22, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Obra":
          myIcon = new L.Icon({
            iconUrl: ConstructionTrashBinDarkIcon,
            iconSize: [50, 20],
            iconAnchor: [25, 20],
            popupAnchor: [1, -28],
          });
          hoveredIcon = new L.Icon({
            iconUrl: ConstructionTrashBinDarkIcon,
            iconSize: [62, 26],
            iconAnchor: [31, 26],
            popupAnchor: [1, -28],
          });
          break;
        case "Papelera":
          myIcon = new L.Icon({
            iconUrl: TrashCanDarkIcon,
            iconSize: [26, 26],
            iconAnchor: [13, 26],
            popupAnchor: [1, -36],
          });
          hoveredIcon = new L.Icon({
            iconUrl: TrashCanDarkIcon,
            iconSize: [34, 34],
            iconAnchor: [17, 34],
            popupAnchor: [1, -36],
          });
          break;
        default:
          myIcon = new L.Icon({
            iconUrl: TrashCanDarkIcon,
            iconSize: [26, 26],
            iconAnchor: [13, 26],
            popupAnchor: [1, -36],
          });
          hoveredIcon = new L.Icon({
            iconUrl: TrashCanDarkIcon,
            iconSize: [34, 34],
            iconAnchor: [17, 34],
            popupAnchor: [1, -36],
          });
          break;
      }
      break;
    case "Farola":
      myIcon = new L.Icon({
        iconUrl: StreetLampDarkIcon,
        iconSize: [26, 40],
        iconAnchor: [13, 40],
        popupAnchor: [1, -52],
      });
      hoveredIcon = new L.Icon({
        iconUrl: StreetLampDarkIcon,
        iconSize: [36, 50],
        iconAnchor: [18, 50],
        popupAnchor: [1, -52],
      });
      break;
    case "Hidrante":
      myIcon = new L.Icon({
        iconUrl: FireHydrantDarkIcon,
        iconSize: [26, 26],
        iconAnchor: [13, 26],
        popupAnchor: [1, -36],
      });
      hoveredIcon = new L.Icon({
        iconUrl: FireHydrantDarkIcon,
        iconSize: [34, 34],
        iconAnchor: [17, 34],
        popupAnchor: [1, -36],
      });
      break;
    case "Hornacina":
      myIcon = new L.Icon({
        iconUrl: ElectricalBoxDarkIcon,
        iconSize: [24, 32],
        iconAnchor: [12, 32],
        popupAnchor: [1, -42],
      });
      hoveredIcon = new L.Icon({
        iconUrl: ElectricalBoxDarkIcon,
        iconSize: [32, 40],
        iconAnchor: [16, 40],
        popupAnchor: [1, -42],
      });
      break;
    case "Imbornal":
      myIcon = new L.Icon({
        iconUrl: DrainageDarkIcon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [1, -42],
      });
      hoveredIcon = new L.Icon({
        iconUrl: DrainageDarkIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [1, -42],
      });
      break;
    case "Marca vial - Paso de cebra":
      myIcon = new L.Icon({
        iconUrl: CrosswalkDarkIcon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [1, -44],
      });
      hoveredIcon = new L.Icon({
        iconUrl: CrosswalkDarkIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [1, -44],
      });
      break;
    case "Semaforo":
      myIcon = new L.Icon({
        iconUrl: TrafficDarkDarkIcon,
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        popupAnchor: [1, -34],
      });
      hoveredIcon = new L.Icon({
        iconUrl: TrafficDarkDarkIcon,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [1, -34],
      });
      break;
    case "Señal de trafico":
      switch (subtype) {
        case "Ceda el paso":
          myIcon = new L.Icon({
            iconUrl: YeildSignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: YeildSignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Entrada prohibida":
          myIcon = new L.Icon({
            iconUrl: NoEntrySignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: NoEntrySignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Estacionamiento":
          myIcon = new L.Icon({
            iconUrl: NoParkingSignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: NoParkingSignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Fin Limitaciones":
          myIcon = new L.Icon({
            iconUrl: EndOfLimitationsSignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: EndOfLimitationsSignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Obligatoriedad":
          myIcon = new L.Icon({
            iconUrl: MandatorySignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: MandatorySignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Paso de cebra":
          myIcon = new L.Icon({
            iconUrl: CrosswalkSignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: CrosswalkSignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Peligro":
          myIcon = new L.Icon({
            iconUrl: DangerSignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: DangerSignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Prohibicion":
          myIcon = new L.Icon({
            iconUrl: ForbiddenSignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: ForbiddenSignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Stop":
          myIcon = new L.Icon({
            iconUrl: StopSignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: StopSignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        case "Velocidad":
          myIcon = new L.Icon({
            iconUrl: SpeedLimitSignDarkIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: SpeedLimitSignDarkIcon,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
            popupAnchor: [1, -42],
          });
          break;
        default:
          myIcon = new L.Icon({
            iconUrl: DirectionsSignDarkIcon,
            iconSize: [34, 34],
            iconAnchor: [17, 34],
            popupAnchor: [1, -42],
          });
          hoveredIcon = new L.Icon({
            iconUrl: DirectionsSignDarkIcon,
            iconSize: [42, 42],
            iconAnchor: [21, 42],
            popupAnchor: [1, -42],
          });
          break;
      }
      break;
    case "Tapa (pozos y arquetas)":
      switch (subtype) {
        case "Arqueta":
          myIcon = new L.Icon({
            iconUrl: StreetArchetteDarkIcon,
            iconSize: [26, 26],
            iconAnchor: [13, 24],
            popupAnchor: [1, -36],
          });
          hoveredIcon = new L.Icon({
            iconUrl: StreetArchetteDarkIcon,
            iconSize: [34, 34],
            iconAnchor: [17, 34],
            popupAnchor: [1, -36],
          });
          break;
        case "Pozo":
          myIcon = new L.Icon({
            iconUrl: StreetWellDarkIcon,
            iconSize: [26, 26],
            iconAnchor: [13, 24],
            popupAnchor: [1, -36],
          });
          hoveredIcon = new L.Icon({
            iconUrl: StreetWellDarkIcon,
            iconSize: [34, 34],
            iconAnchor: [17, 34],
            popupAnchor: [1, -36],
          });
          break;
        default:
          myIcon = new L.Icon({
            iconUrl: StreetWellDarkIcon,
            iconSize: [26, 26],
            iconAnchor: [13, 24],
            popupAnchor: [1, -36],
          });
          hoveredIcon = new L.Icon({
            iconUrl: StreetWellDarkIcon,
            iconSize: [34, 34],
            iconAnchor: [17, 34],
            popupAnchor: [1, -36],
          });
          break;
      }
      break;
    case "Torre":
      myIcon = new L.Icon({
        iconUrl: ElectricalTowerDarkIcon,
        iconSize: [36, 40],
        iconAnchor: [18, 40],
        popupAnchor: [1, -52],
      });
      hoveredIcon = new L.Icon({
        iconUrl: ElectricalTowerDarkIcon,
        iconSize: [46, 50],
        iconAnchor: [23, 50],
        popupAnchor: [1, -52],
      });
      break;
    default:
      myIcon = new L.Icon({
        iconUrl: MapMarkerIcon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [1, -40],
      });
      hoveredIcon = new L.Icon({
        iconUrl: MapMarkerIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [1, -40],
      });
      break;
  }

  return [myIcon, hoveredIcon, draggableIcon];
}