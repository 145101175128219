import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

import Layout from './components/layout/Layout';
import Home from './components/pages/Home';
import Inventories from './components/pages/Inventories';
import Catalogue from './components/pages/Catalogue';
import Plans from './components/pages/Plans';
import Demo from './components/pages/Demo';
import Contact from './components/pages/Contact';
import Start from './components/pages/Start';
import Privacy from './components/pages/Privacy';
import Cookies from './components/pages/Cookies';
import NoMatch from './components/pages/NoMatch';

export default function App() {

  let theme = createTheme({
    palette: {
      primary: {
        light: '#ff9933',
        main: '#ff8000',
        dark: '#e67300',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#ff9933',
        main: '#ffffff',
        dark: '#ff8000',
        contrastText: '#000000'
      },
      tertiary: {
        light: '#ff9933',
        main: '#ffffff',
        dark: '#e0e0e0',
        contrastText: '#ff8000'
      },
      blue: {
        light: '#42a5f5',
        main: '#1976f2',
        dark: '#1565c0',
        contrastText: '#ffffff'
      },
      neutral: {
        light: '#f5f5f5',
        main: '#ffffff',
        dark: '#e0e0e0',
        contrastText: '#000000'
      },
      faded: {
        light: 'rgba(255, 155, 0, 0.08)',
        main: 'rgba(255, 128, 0, 0.10)',
        dark: 'rgba(255, 128, 0, 0.12)',
        contrastText: '#000000'
      },
      footerButton: {
        light: '#f5f5f5',
        main: '#00000099',
        dark: '#e0e0e0',
        contrastText: '#00000099'
      },
    },
    typography: {
      fontFamily: [
        'Poppins',
      ].join(','),
    },
  });

  theme = responsiveFontSizes(theme);

  return (
    <div id="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/inventarios/" element={<Inventories />} />
              <Route path="/catalogo/:section?" element={<Catalogue />} />
{/*               <Route path="/planes/" element={<Plans />} />     */}
              <Route path="/demo/" element={<Demo />} />
              <Route path="/contacto/" element={<Contact />} />
              <Route path="/empecemos/" element={<Start />} />
              <Route path="/privacidad/" element={<Privacy />} />
              <Route path="/cookies/" element={<Cookies />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </Layout>
        </Router>
      </ThemeProvider>
    </div>
  );
}