import axios from 'axios';

//const serverUrl = 'http://localhost:3001';
const serverUrl = 'https://maps.gemminis.io:3001';

export async function getObjectsFromDB(isAuthenticated, getAccessTokenSilently) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const response = await axios.get(serverUrl + '/api/private/objects', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      const response = await axios.get(serverUrl + '/api/public/objects');
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAccessibilityFromDB(isAuthenticated, getAccessTokenSilently) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const response = await axios.get(serverUrl + '/api/private/accessibility', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      const response = await axios.get(serverUrl + '/api/public/accessibility');
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function insertObjectFromDB(isAuthenticated, getAccessTokenSilently, newValues) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const response = await axios.post(serverUrl + '/api/private/object', newValues, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      const response = await axios.post(serverUrl + '/api/public/object', newValues);
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateObjectFromDB(isAuthenticated, getAccessTokenSilently, newValues, dbCity) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios.patch(serverUrl + '/api/private/object', newValues, {
        headers: {
          Authorization: `Bearer ${token}`,
          city: dbCity,
        }
      });
    } else {
      await axios.patch(serverUrl + '/api/public/object', newValues);
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateAccessibilityPointFromDB(isAuthenticated, getAccessTokenSilently, newValues, dbCity) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios.patch(serverUrl + '/api/private/accessibilitypoint', newValues, {
        headers: {
          Authorization: `Bearer ${token}`,
          city: dbCity,
        }
      });
    } else {
      await axios.patch(serverUrl + '/api/public/accessibilitypoint', newValues);
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateObjectsFromDB(isAuthenticated, getAccessTokenSilently, ids, valuesToUpdate, dbCity) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios.patch(serverUrl + '/api/private/objects',
        {
          data: {
            ids: ids,
            valuesToUpdate: valuesToUpdate
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            city: dbCity,
          }
        }
      );
    } else {
      await axios.patch(serverUrl + '/api/public/objects', {
        data: {
          ids: ids,
          valuesToUpdate: valuesToUpdate
        }
      });
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateAccessibilityFromDB(isAuthenticated, getAccessTokenSilently, ids, valuesToUpdate, dbCity) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios.patch(serverUrl + '/api/private/accessibility',
        {
          data: {
            ids: ids,
            valuesToUpdate: valuesToUpdate
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            city: dbCity,
          }
        }
      );
    } else {
      await axios.patch(serverUrl + '/api/public/accessibility', {
        data: {
          ids: ids,
          valuesToUpdate: valuesToUpdate
        }
      });
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteObjectFromDB(isAuthenticated, getAccessTokenSilently, id, dbCity) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios.delete(serverUrl + '/api/private/objects/' + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          city: dbCity,
        }
      });
    } else {
      await axios.delete(serverUrl + '/api/public/objects/' + id);
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteAccessibilityPointFromDB(isAuthenticated, getAccessTokenSilently, id, dbCity) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios.delete(serverUrl + '/api/private/accessibility/' + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          city: dbCity,
        }
      });
    } else {
      await axios.delete(serverUrl + '/api/public/accessibility/' + id);
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteObjectsFromDB(isAuthenticated, getAccessTokenSilently, ids, dbCity) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios.delete(serverUrl + '/api/private/objects/0', {
        headers: {
          Authorization: `Bearer ${token}`,
          city: dbCity,
        },
        data: {
          ids: ids
        }
      });
    } else {
      await axios.delete(serverUrl + '/api/public/objects/0', {
        data: {
          ids: ids
        }
      });
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function deleteAccessibilityFromDB(isAuthenticated, getAccessTokenSilently, ids, dbCity) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await axios.delete(serverUrl + '/api/private/accessibility/0', {
        headers: {
          Authorization: `Bearer ${token}`,
          city: dbCity,
        },
        data: {
          ids: ids
        }
      });
    } else {
      await axios.delete(serverUrl + '/api/public/accessibility/0', {
        data: {
          ids: ids
        }
      });
    }

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getImagesFromDB(isAuthenticated, getAccessTokenSilently, id, dbCity, isObject) {
  try {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const response = await axios.get(serverUrl + '/api/private/images/' + id + '?isObject=' + isObject, {
        headers: {
          Authorization: `Bearer ${token}`,
          city: dbCity,
        }
      });
      return response.data;
    } else {
      const response = await axios.get(serverUrl + '/api/public/images/' + id + '?isObject=' + isObject);
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function sendEmail(body) {
  try {
    const response = await axios.post(serverUrl + '/api/public/email', {
      data: {
        body: body
      }
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return false;
  }
}
