import React, { forwardRef } from 'react';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function DialogLocationNotAllowed(props) {

  const { dialogLocationOpen, handleCloseDialogLocation} = props;

  return (
    <Dialog open={dialogLocationOpen} onClose={handleCloseDialogLocation} TransitionComponent={Transition}>
      <DialogTitle>Ubicación desactivada</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-location-not-allowed-description">
          El permiso para conocer tu ubicación actual está desactivado. Por favor, actívalo para poder ver tu posición en el mapa. Para activarlo prueba a recargar la página.
          <br/><br/>Si al recargar no se le pregunta si desea permitir acceso a la ubicación, deberá activarla manualmente. En Google Chrome tiene la opción de activarlo desde la barra superior, a la izquierda de la opción "marcar pestaña como favoritos".
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialogLocation}>Entendido</Button>
      </DialogActions>
    </Dialog>
  );
}