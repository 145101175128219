import React from 'react';

import NavbarGemminis from './NavbarGemminis';
import FooterGemminis from './FooterGemminis';

function Layout(props) {
  return (
    <>
      <NavbarGemminis />
        {props.children}
      <FooterGemminis />
    </>
  );
}

export default Layout;
