import React, { useState, useEffect, forwardRef } from 'react';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale'

import dayjs from 'dayjs';
import "dayjs/locale/es";

import { types, subtypes } from '../../../constants/objectTypes.js';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogEditObject(props) {

  const { open, handleClose, object, updateObject } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const [city, setCity] = useState('');
  const [road, setRoad] = useState('');
  const [type, setType] = useState('');
  const [subtype, setSubtype] = useState('');
  const [subtypeOptions, setSubtypeOptions] = useState(['No especificado']);
  const [items, setItems] = useState('');
  const [reviewed, setReviewed] = useState('');
  const [date, setDate] = useState('');
  const [notes, setNotes] = useState('');
  const [errorCity, setErrorCity] = useState(false);
  const [errorRoad, setErrorRoad] = useState(false);

  const itemsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const reviewedOptions = [true, false];

  useEffect(() => {
    if (open && object !== undefined) {

      if (object.type === 'Tapa (pozos y arquetas)' || object.type === 'Contenedor - Papelera' || object.type === 'Señal de trafico') {
        const newSubtypeOptions = subtypes.find((e) => e.type === object.type).subtypes;
        setSubtypeOptions(newSubtypeOptions);
      } else {
        setSubtypeOptions(['No especificado']);
      }

      setCity(object.city);
      setRoad(object.road);
      setType(object.type);
      setSubtype(object.subtype);
      setItems(object.items);
      setReviewed(object.reviewed);
      setDate(dayjs(object.date).valueOf());

      let newNotes = object?.notes;
      if (newNotes === undefined) {
        newNotes = "";
      }
      setNotes(newNotes);

      setErrorCity(false);
      setErrorRoad(false);
      setOpenDialog(true);
    }
  }, [open, object]);

  function closeDialog() {
    setOpenDialog(false);
    handleClose();
  }

  function updateCity(event) {
    setCity(event.target.value);
  }

  function updateRoad(event) {
    setRoad(event.target.value);
  }

  const handleTypeSelected = (event) => {
    setType(event.target.value);
    if (event.target.value === 'Tapa (pozos y arquetas)' || event.target.value === 'Contenedor - Papelera' || event.target.value === 'Señal de trafico') {
      const newSubtypeOptions = subtypes.find((e) => e.type === event.target.value).subtypes;
      setSubtypeOptions(newSubtypeOptions);
    } else {
      setSubtypeOptions(['No especificado']);
    }
    setSubtype('No especificado');
  }

  const handleSubtypeSelected = (event) => {
    setSubtype(event.target.value);
  }

  const handleItemsSelected = (event) => {
    setItems(event.target.value);
  }

  function updateReviewed(event) {
    setReviewed(event.target.value);
  }

  function updateNotes(event) {
    setNotes(event.target.value);
  }

  function handleSaveEditClicked() {
    let allValuesFilled = true;
    if(city === '') {
      setErrorCity(true);
      allValuesFilled = false;
    } else {
      setErrorCity(false);
    }

    if(road === '') {
      setErrorRoad(true);
      allValuesFilled = false;
    } else {
      setErrorRoad(false);
    }

    if(allValuesFilled) {
      let values = {
        id: object.id,
        latitude: object.latitude,
        longitude: object.longitude,
        city: city,
        road: road,
        type: type,
        subtype: subtype,
        items: items,
        date: date,
        creation_date: object.creation_date,
        notes: notes,
        images: object.images,
        reviewed: reviewed,
      }
      closeDialog();
      updateObject(object, values);
    }
  }

  return (
    <Dialog open={openDialog} onClose={closeDialog} TransitionComponent={Transition}>
      <DialogTitle>Editar objeto</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          margin="dense"
          id="input-text-city"
          label="Ciudad"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={city}
          error={errorCity}
          onChange={updateCity}
        />
        <TextField
          multiline
          margin="dense"
          id="input-text-road"
          label="Calle"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={road}
          error={errorRoad}
          onChange={updateRoad}
        />
        <Box sx={{ minWidth: '400px', mt: '12px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-type-label">Categoria</InputLabel>
            <Select
              labelId="select-type-label"
              id="select-type"
              value={type}
              label="Categoria"
              onChange={handleTypeSelected}
            >
              {types.map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: '400px', mt: '14px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-subtype-label">Subategoria</InputLabel>
            <Select
              labelId="select-subtype-label"
              id="select-subtype"
              value={subtype}
              label="Subcategoria"
              onChange={handleSubtypeSelected}
            >
              {subtypeOptions.map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: '400px', mt: '14px', mb: '14px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-items-label">Elementos</InputLabel>
            <Select
              labelId="select-items-label"
              id="select-items"
              value={items}
              label="Elementos"
              onChange={handleItemsSelected}
            >
              {itemsOptions.map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: '400px', mt: '14px', mb: '14px' }}>
          <FormControl fullWidth>
            <InputLabel id="select-type-label">Revisado</InputLabel>
            <Select
              labelId="select-reviewed-label"
              id="select-reviewed"
              value={reviewed}
              label="Revisado"
              onChange={updateReviewed}
            >
              {reviewedOptions.map((option, index) => {
                let textReviewed;
                if (option) textReviewed = "Sí"
                else textReviewed = "No"
                return (
                  <MenuItem key={index} value={option}>{textReviewed}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <DatePicker
            label="Fecha"
            value={date}
            onChange={(newValue) => {
              setDate(dayjs(newValue).startOf('day').valueOf());
            }}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </LocalizationProvider>
        <TextField
          multiline
          margin="dense"
          id="input-text-notes"
          label="Notas"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={notes}
          onChange={updateNotes}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeDialog}>Cancelar</Button>
        <Button color="success" variant="contained" onClick={handleSaveEditClicked}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}