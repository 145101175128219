import React, { useState, useEffect, forwardRef } from 'react';

import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale'

import dayjs from 'dayjs';
import "dayjs/locale/es";

import { pavements } from '../../../constants/pavements.js';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const sidewalkOptions = ['Acera accesible', 'Acera no accesible', 'No hay acera'];
const reviewedOptions = [true, false];

export default function DialogEditSelectedAccessibility(props) {

  const { open, handleClose, accessibility, updateSelectedAccessibility } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const [city, setCity] = useState('');
  const [road, setRoad] = useState('');
  const [pavement, setPavement] = useState('');
  const [slope, setSlope] = useState('');
  const [avgWidthSidewalk, setAvgWidthSidewalk] = useState('');
  const [sidewalk, setSidewalk] = useState('');
  const [accessibilityIssues, setAccessibilityIssues] = useState({
    width: {
      widthChecked: false,
      fenceChecked: false,
      barrierChecked: false,
      vegetationChecked: false,
      billboardChecked: false,
      hydrantChecked: false,
      junctionBoxChecked: false,
      phoneChecked: false,
      lightChecked: false,
      poleChecked: false,
      utilityPoleChecked: false,
    },
    height: {
      signChecked: false,
      trafficLightChecked: false,
    },
    crosswalk: {
      loweredChecked: false,
      duropanotChecked: false,
    }
  });
  const [reviewed, setReviewed] = useState('');
  const [date, setDate] = useState('');
  const [notes, setNotes] = useState('');

  const [pavementDisabled, setPavementDisabled] = useState(false);
  const [widthDisabled, setWidthDisabled] = useState(false);
  const [accessibilityIssuesDisabled, setAccessibilityIssuesDisabled] = useState(false);

  const [cityModified, setCityModified] = useState(false);
  const [roadModified, setRoadModified] = useState(false);
  const [pavementModified, setPavementModified] = useState(false);
  const [slopeModified, setSlopeModified] = useState(false);
  const [widthModified, setWidthModified] = useState(false);
  const [sidewalkModified, setSidewalkModified] = useState(false);
  const [reviewedModified, setReviewedModified] = useState(false);
  const [dateModified, setDateModified] = useState(false);
  const [notesModified, setNotesModified] = useState(false);

  const [cityLocked, setCityLocked] = useState(false);
  const [roadLocked, setRoadLocked] = useState(false);
  const [pavementLocked, setPavementLocked] = useState(false);
  const [slopeLocked, setSlopeLocked] = useState(false);
  const [widthLocked, setWidthLocked] = useState(false);
  const [sidewalkLocked, setSidewalkLocked] = useState(false);
  const [reviewedLocked, setReviewedLocked] = useState(false);
  const [dateLocked, setDateLocked] = useState(false);
  const [notesLocked, setNotesLocked] = useState(false);

  const [errorCity, setErrorCity] = useState(false);
  const [errorRoad, setErrorRoad] = useState(false);
  const [errorPavement, setErrorPavement] = useState(false);
  const [errorSlope, setErrorSlope] = useState(false);
  const [errorWidth, setErrorWidth] = useState(false);
  const [errorIssues, setErrorIssues] = useState(false);

  useEffect(() => {
    if (open && accessibility.length > 0) {

      setCityModified(false);
      setRoadModified(false);
      setPavementModified(false);
      setSlopeModified(false);
      setWidthModified(false);
      setSidewalkModified(false);
      setReviewedModified(false);
      setDateModified(false);
      setNotesModified(false);

      let uniqueCity = accessibility[0].city;
      let uniqueRoad = accessibility[0].road;
      let uniquePavement = accessibility[0].pavement;
      let uniqueSlope = accessibility[0].slope;
      let uniqueWidth = accessibility[0].avg_width_sidewalk;
      let uniqueHasSidewalk = accessibility[0].has_sidewalk;
      let uniqueIsAccessible = accessibility[0].accessible;
      let uniqueNotAccessibleReason = accessibility[0].not_accessible_reason;
      let uniqueReviewed = accessibility[0].reviewed;
      let uniqueDate = accessibility[0].date;
      let uniqueNotes = accessibility[0].notes;

      accessibility.forEach(item => {
        if (item.city !== uniqueCity) uniqueCity = null;
        if (item.road !== uniqueRoad) uniqueRoad = null;
        if (item.pavement !== uniquePavement) uniquePavement = null;
        if (item.slope !== uniqueSlope) uniqueSlope = null;
        if (item.avg_width_sidewalk !== uniqueWidth) uniqueWidth = null;
        if (item.has_sidewalk !== uniqueHasSidewalk) uniqueHasSidewalk = null;
        if (item.accessible !== uniqueIsAccessible) uniqueIsAccessible = null;
        if (item.not_accessible_reason !== uniqueNotAccessibleReason) uniqueNotAccessibleReason = null;
        if (item.reviewed !== uniqueReviewed) uniqueReviewed = null;
        if (item.date !== uniqueDate) uniqueDate = null;
        if (item.notes !== uniqueNotes) uniqueNotes = null;
      });

      if (uniqueCity === null) {
        setCity('');
        setCityLocked(true);
      } else {
        setCity(uniqueCity);
        setCityLocked(false);
      }

      if (uniqueRoad === null) {
        setRoad('');
        setRoadLocked(true);
      } else {
        setRoad(uniqueRoad);
        setRoadLocked(false);
      }

      if (uniquePavement === null) {
        setPavement('');
        setPavementLocked(true);
      } else {
        setPavement(uniquePavement);
        setPavementLocked(false);
      }

      if (uniqueSlope === null) {
        setSlope('');
        setSlopeLocked(true);
      } else {
        setSlope(uniqueRoad);
        setSlopeLocked(false);
      }

      if (uniqueWidth === null) {
        setAvgWidthSidewalk('');
        setWidthLocked(true);
      } else {
        setAvgWidthSidewalk(uniqueWidth);
        setWidthLocked(false);
      }

      if (uniqueHasSidewalk === null || uniqueIsAccessible === null || uniqueNotAccessibleReason === null) {
        setSidewalk('');
        setPavementDisabled(false);
        setWidthDisabled(false);
        setAccessibilityIssuesDisabled(true);
        setAccessibilityIssues({
          width: {
            widthChecked: false,
            fenceChecked: false,
            barrierChecked: false,
            vegetationChecked: false,
            billboardChecked: false,
            hydrantChecked: false,
            junctionBoxChecked: false,
            phoneChecked: false,
            lightChecked: false,
            poleChecked: false,
            utilityPoleChecked: false,
          },
          height: {
            signChecked: false,
            trafficLightChecked: false,
          },
          crosswalk: {
            loweredChecked: false,
            duropanotChecked: false,
          }
        });
        setSidewalkLocked(true);
      } else {

        if (uniqueHasSidewalk) {
          setPavementDisabled(false);
          setWidthDisabled(false);
          if (uniqueIsAccessible) {
            setSidewalk('Acera accesible');
            setAccessibilityIssuesDisabled(true);
          } else {
            setSidewalk('Acera no accesible');
            setAccessibilityIssuesDisabled(false);
          }
        } else {
          setSidewalk('No hay acera');
          setPavementDisabled(true);
          setWidthDisabled(true);
          setAccessibilityIssuesDisabled(true);
        }

        setAccessibilityIssues({
          width: {
            widthChecked: uniqueNotAccessibleReason.width.width,
            fenceChecked: uniqueNotAccessibleReason.width.fence,
            barrierChecked: uniqueNotAccessibleReason.width.barrier,
            vegetationChecked: uniqueNotAccessibleReason.width.vegetation,
            billboardChecked: uniqueNotAccessibleReason.width.billboard,
            hydrantChecked: uniqueNotAccessibleReason.width.hydrant,
            junctionBoxChecked: uniqueNotAccessibleReason.width.junction_box,
            phoneChecked: uniqueNotAccessibleReason.width.phone,
            lightChecked: uniqueNotAccessibleReason.width.light,
            poleChecked: uniqueNotAccessibleReason.width.pole,
            utilityPoleChecked: uniqueNotAccessibleReason.width.utility_pole,
          },
          height: {
            signChecked: uniqueNotAccessibleReason.height.sign,
            trafficLightChecked: uniqueNotAccessibleReason.height.traffic_Light,
          },
          crosswalk: {
            loweredChecked: uniqueNotAccessibleReason.crosswalk.lowered,
            duropanotChecked: uniqueNotAccessibleReason.crosswalk.duropanot,
          }
        });

        setSidewalkLocked(false);
      }

      if (uniqueReviewed == null) {
        setReviewed('');
        setReviewedLocked(true);
      } else {
        setReviewed(uniqueReviewed);
        setReviewedLocked(false);
      }

      if (uniqueDate === null) {
        setDate(dayjs().valueOf());
        setDateLocked(true);
      } else {
        setDate(dayjs(uniqueDate).valueOf());
        setDateLocked(false);
      }

      if (uniqueNotes === null) {
        setNotes('');
        setNotesLocked(true);
      } else {
        setNotes(uniqueNotes);
        setNotesLocked(false);
      }

      setErrorCity(false);
      setErrorRoad(false);
      setErrorPavement(false);
      setErrorSlope(false);
      setErrorWidth(false);
      setErrorIssues(false);
      setOpenDialog(true);
    }
  }, [open, accessibility]);

  function closeDialog() {
    setOpenDialog(false);
    handleClose();
  }

  function updateCity(event) {
    setCity(event.target.value);
    setCityModified(true);
  }

  function updateRoad(event) {
    setRoad(event.target.value);
    setRoadModified(true);
  }

  function updatePavement(event) {
    setPavement(event.target.value);
    setPavementModified(true);
  }

  function updateSlope(event) {
    let text = event.target.value.trim().replace(",", ".");
    setSlope(text);
    setSlopeModified(true);
  }

  function updateAvgWidthSidewalk(event) {
    let text = event.target.value.trim().replace(",", ".");
    setAvgWidthSidewalk(text);
    setWidthModified(true);
  }

  function updateSidewalk(event) {
    let newValue = event.target.value;
    setSidewalk(newValue);
    setSidewalkModified(true);

    switch (newValue) {
      case 'Acera accesible':
        setAccessibilityIssues({
          width: {
            widthChecked: false,
            fenceChecked: false,
            barrierChecked: false,
            vegetationChecked: false,
            billboardChecked: false,
            hydrantChecked: false,
            junctionBoxChecked: false,
            phoneChecked: false,
            lightChecked: false,
            poleChecked: false,
            utilityPoleChecked: false,
          },
          height: {
            signChecked: false,
            trafficLightChecked: false,
          },
          crosswalk: {
            loweredChecked: false,
            duropanotChecked: false,
          }
        });
        setPavementDisabled(false);
        setWidthDisabled(false);
        setPavementLocked(false);
        setWidthLocked(false);
        setAccessibilityIssuesDisabled(true);
        break;
      case 'Acera no accesible':
        setPavementDisabled(false);
        setWidthDisabled(false);
        setPavementLocked(false);
        setWidthLocked(false);
        setAccessibilityIssuesDisabled(false);
        break;
      case 'No hay acera':
        setPavement('');
        setPavementLocked(false);
        setPavementModified(true);
        setAvgWidthSidewalk('');
        setWidthLocked(false);
        setWidthModified(true);
        setAccessibilityIssues({
          width: {
            widthChecked: false,
            fenceChecked: false,
            barrierChecked: false,
            vegetationChecked: false,
            billboardChecked: false,
            hydrantChecked: false,
            junctionBoxChecked: false,
            phoneChecked: false,
            lightChecked: false,
            poleChecked: false,
            utilityPoleChecked: false,
          },
          height: {
            signChecked: false,
            trafficLightChecked: false,
          },
          crosswalk: {
            loweredChecked: false,
            duropanotChecked: false,
          }
        });
        setPavementDisabled(true);
        setWidthDisabled(true);
        setAccessibilityIssuesDisabled(true);
        break;
      default:
        setPavement('');
        setPavementLocked(false);
        setPavementModified(true);
        setAvgWidthSidewalk('');
        setWidthLocked(false);
        setWidthModified(true);
        setAccessibilityIssues({
          width: {
            widthChecked: false,
            fenceChecked: false,
            barrierChecked: false,
            vegetationChecked: false,
            billboardChecked: false,
            hydrantChecked: false,
            junctionBoxChecked: false,
            phoneChecked: false,
            lightChecked: false,
            poleChecked: false,
            utilityPoleChecked: false,
          },
          height: {
            signChecked: false,
            trafficLightChecked: false,
          },
          crosswalk: {
            loweredChecked: false,
            duropanotChecked: false,
          }
        });
        setPavementDisabled(true);
        setWidthDisabled(true);
        setAccessibilityIssuesDisabled(true);
        break;
    }
  }

  function handleIssuesChange(event) {
    setAccessibilityIssues({
      ...accessibilityIssues,
      [event.target.name]: event.target.checked,
    });
  };

  function updateReviewed(event) {
    setReviewed(event.target.value);
    setReviewedModified(true);
  }

  function updateNotes(event) {
    setNotes(event.target.value);
    setNotesModified(true);
  }

  function handleCityLockClicked() {
    setCityLocked(!cityLocked);
  }

  function handleRoadLockClicked() {
    setRoadLocked(!roadLocked);
  }

  function handlePavementLockClicked() {
    setPavementLocked(!pavementLocked);
  }

  function handleSlopeLockClicked() {
    setSlopeLocked(!slopeLocked);
  }

  function handleWidthLockClicked() {
    setWidthLocked(!widthLocked);
  }

  function handleSidewalkLockClicked() {
    setSidewalkLocked(!sidewalkLocked);
  }

  function handleReviewedLockClicked() {
    setReviewedLocked(!reviewedLocked);
  }

  function handleDateLockClicked() {
    setDateLocked(!dateLocked);
  }

  function handleNotesLockClicked() {
    setNotesLocked(!notesLocked);
  }

  function handleSaveEditClicked() {
    let allValuesFilled = true;

    if (city === '' && !cityLocked) {
      setErrorCity(true);
      allValuesFilled = false;
    } else {
      setErrorCity(false);
    }

    if (road === '' && !roadLocked) {
      setErrorRoad(true);
      allValuesFilled = false;
    } else {
      setErrorRoad(false);
    }

    if (!pavementDisabled && pavement === '' && !pavementLocked) {
      setErrorPavement(true);
      allValuesFilled = false;
    } else {
      setErrorPavement(false);
    }

    if (!/^(?:\d+|\d+\.\d+)$/.test(slope) && !slopeLocked) {
      setErrorSlope(true);
      allValuesFilled = false;
    } else {
      setErrorSlope(false);
    }

    if (!widthDisabled && !/^(?:\d+|\d+\.\d+)$/.test(avgWidthSidewalk) && !widthLocked) {
      setErrorWidth(true);
      allValuesFilled = false;
    } else {
      setErrorWidth(false);
    }

    if (!accessibilityIssuesDisabled &&
      [
        accessibilityIssues.width.widthChecked,
        accessibilityIssues.width.fenceChecked,
        accessibilityIssues.width.barrierChecked,
        accessibilityIssues.width.vegetationChecked,
        accessibilityIssues.width.billboardChecked,
        accessibilityIssues.width.hydrantChecked,
        accessibilityIssues.width.junctionBoxChecked,
        accessibilityIssues.width.phoneChecked,
        accessibilityIssues.width.lightChecked,
        accessibilityIssues.width.poleChecked,
        accessibilityIssues.width.utilityPoleChecked,
        accessibilityIssues.height.signChecked,
        accessibilityIssues.height.trafficLightChecked,
        accessibilityIssues.crosswalk.loweredChecked,
        accessibilityIssues.crosswalk.duropanotChecked,
      ].filter((v) => v).length === 0) {

        setErrorIssues(true);
        allValuesFilled = false;
    } else {
      setErrorIssues(false);
    }

    if (allValuesFilled) {

      let hasSidewalk = false;
      let isAccessible = false;

      if (sidewalk !== "No hay acera") {
        hasSidewalk = true;
        if (sidewalk !== "Acera no accesible") {
          isAccessible = true;
        }
      }

      let not_accessible_reason = {
        width: {
          width: accessibilityIssues.width.widthChecked,
          fence: accessibilityIssues.width.fenceChecked,
          barrier: accessibilityIssues.width.barrierChecked,
          vegetation: accessibilityIssues.width.vegetationChecked,
          billboard: accessibilityIssues.width.billboardChecked,
          hydrant: accessibilityIssues.width.hydrantChecked,
          junction_box: accessibilityIssues.width.junctionBoxChecked,
          phone: accessibilityIssues.width.phoneChecked,
          light: accessibilityIssues.width.lightChecked,
          pole: accessibilityIssues.width.poleChecked,
          utility_pole: accessibilityIssues.width.utilityPoleChecked,
        },
        height: {
          sign: accessibilityIssues.height.signChecked,
          traffic_light: accessibilityIssues.height.trafficLightChecked,
        },
        crosswalk: {
          lowered: accessibilityIssues.crosswalk.loweredChecked,
          duropanot: accessibilityIssues.crosswalk.duropanotChecked,
        }
      }

      let values = {
        city: { modified: cityModified && !cityLocked, value: city },
        road: { modified: roadModified && !roadLocked, value: road },
        pavement: { modified: pavementModified && !pavementLocked, value: pavement },
        slope: { modified: slopeModified && !slopeLocked, value: slope },
        avg_width_sidewalk: { modified: widthModified && !widthLocked, value: avgWidthSidewalk },
        has_sidewalk: { modified: sidewalkModified && !sidewalkLocked, value: hasSidewalk },
        accessible: { modified: sidewalkModified && !sidewalkLocked, value: isAccessible },
        not_accessible_reason: { modified: sidewalkModified && !sidewalkLocked, value: not_accessible_reason },
        date: { modified: dateModified && !dateLocked, value: date },
        notes: { modified: notesModified && !notesLocked, value: notes },
        reviewed: { modified: reviewedModified && !reviewedLocked, value: reviewed },
      }
      closeDialog();
      updateSelectedAccessibility(values);
    }
  }

  return (
    <Dialog open={openDialog} onClose={closeDialog} TransitionComponent={Transition}>
      <DialogTitle>Editar {accessibility.length} objetos</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Tooltip title={cityLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handleCityLockClicked}
            >
              {cityLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            multiline
            margin="dense"
            id="input-text-city"
            label="Ciudad"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={city}
            disabled={cityLocked}
            error={errorCity}
            onChange={updateCity}
            sx={{ ml: '8px' }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={roadLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handleRoadLockClicked}
            >
              {roadLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            multiline
            margin="dense"
            id="input-text-road"
            label="Calle"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={road}
            disabled={roadLocked}
            error={errorRoad}
            onChange={updateRoad}
            sx={{ ml: '8px' }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={pavementLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handlePavementLockClicked}
            >
              {pavementLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ minWidth: '400px', mt: '12px', ml: '8px' }}>
            <FormControl fullWidth>
              <InputLabel id="select-pavement-label">Pavimento</InputLabel>
              <Select
                labelId="select-pavement-label"
                id="select-pavement"
                value={pavement}
                label="Pavimento"
                disabled={pavementLocked || pavementDisabled}
                error={errorPavement}
                onChange={updatePavement}
              >
                {pavements.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={slopeLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handleSlopeLockClicked}
            >
              {slopeLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            multiline
            margin="dense"
            id="input-text-slope"
            label="Pendiente (%)"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={slope}
            disabled={slopeLocked}
            error={errorSlope}
            onChange={updateSlope}
            sx={{ ml: '8px' }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={widthLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handleWidthLockClicked}
            >
              {widthLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            multiline
            margin="dense"
            id="input-text-width"
            label="Anchura acera (m)"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={avgWidthSidewalk}
            disabled={widthLocked || widthDisabled}
            error={errorWidth}
            onChange={updateAvgWidthSidewalk}
            sx={{ ml: '8px' }}
          />
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: '12px' }}>
          <Tooltip title={sidewalkLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px' }}
              onClick={handleSidewalkLockClicked}
            >
              {sidewalkLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ minWidth: '400px', mt: '12px', ml: '8px' }}>
            <FormControl fullWidth>
              <InputLabel id="select-type-label">Accesibilidad</InputLabel>
              <Select
                labelId="select-accessibility-label"
                id="select-accessibility"
                value={sidewalk}
                label="Accesible"
                disabled={sidewalkLocked}
                onChange={updateSidewalk}
              >
                {sidewalkOptions.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              disabled={sidewalkLocked || accessibilityIssuesDisabled}
              error={errorIssues}
              component="fieldset"
              sx={{ mt: '12px' }}
              variant="standard"
            >
              {errorIssues && (
                <FormLabel component="legend">Selecciona mínimo una</FormLabel>
              )}
              <FormGroup>
                <Typography sx={{ mt: '10px', mb: '6px' }}>Anchura de acera insuficiente (&lt; 1.8 m)</Typography>
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.widthChecked} onChange={handleIssuesChange} name="width-widthChecked" />
                  }
                  label="Anchura general insuficiente"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.fenceChecked} onChange={handleIssuesChange} name="width-fenceChecked" />
                  }
                  label="Por valla"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.barrierChecked} onChange={handleIssuesChange} name="width-barrierChecked" />
                  }
                  label="Por barrera"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.vegetationChecked} onChange={handleIssuesChange} name="width-vegetationChecked" />
                  }
                  label="Por vegetación"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.billboardChecked} onChange={handleIssuesChange} name="width-billboardChecked" />
                  }
                  label="Por cartel"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.hydrantChecked} onChange={handleIssuesChange} name="width-hydrantChecked" />
                  }
                  label="Por hidrante"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.junctionBoxChecked} onChange={handleIssuesChange} name="width-junctionBoxChecked" />
                  }
                  label="Por caja eléctrica"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.phoneChecked} onChange={handleIssuesChange} name="width-phoneChecked" />
                  }
                  label="Por cabina telefónica"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.lightChecked} onChange={handleIssuesChange} name="width-lightChecked" />
                  }
                  label="Anchura insuficiente por farola"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.poleChecked} onChange={handleIssuesChange} name="width-poleChecked" />
                  }
                  label="Por poste"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.width.utilityPoleChecked} onChange={handleIssuesChange} name="width-utilityPoleChecked" />
                  }
                  label="Por poste de servicios"
                />
                <Typography sx={{ mt: '10px', mb: '6px' }}>Altura de obstáculos insuficiente (&lt; 2.2 m)</Typography>
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.height.signChecked} onChange={handleIssuesChange} name="height-signChecked" />
                  }
                  label="Por señal"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.height.trafficLightChecked} onChange={handleIssuesChange} name="height-trafficLightChecked" />
                  }
                  label="Por semáforo"
                />
                <Typography sx={{ mt: '10px', mb: '6px' }}>Paso de cebra no adaptado</Typography>
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.crosswalk.loweredChecked} onChange={handleIssuesChange} name="crosswalk-loweredChecked" />
                  }
                  label="Sin rebaje"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={accessibilityIssues.crosswalk.duropanotChecked} onChange={handleIssuesChange} name="crosswalk-duropanotChecked" />
                  }
                  label="Sin duropanot"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: '14px' }}>
          <Tooltip title={reviewedLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px' }}
              onClick={handleReviewedLockClicked}
            >
              {reviewedLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ minWidth: '400px', ml: '8px' }}>
            <FormControl fullWidth>
              <InputLabel id="select-type-label">Revisado</InputLabel>
              <Select
                labelId="select-reviewed-label"
                id="select-reviewed"
                value={reviewed}
                label="Revisado"
                disabled={reviewedLocked}
                onChange={updateReviewed}
              >
                {reviewedOptions.map((option, index) => {
                  let textReviewed;
                  if (option) textReviewed = "Sí"
                  else textReviewed = "No"
                  return (
                    <MenuItem key={index} value={option}>{textReviewed}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: '14px' }}>
          <Tooltip title={dateLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px' }}
              onClick={handleDateLockClicked}
            >
              {dateLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <Box sx={{ ml: '8px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
              <DatePicker
                label="Fecha"
                value={date}
                disabled={dateLocked}
                onChange={(newValue) => {
                  setDate(dayjs(newValue).startOf('day').valueOf());
                  setDateModified(true);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={notesLocked ? "Permitir edición" : "Mantener valores originales"} arrow enterDelay={500}>
            <IconButton
              sx={{ ml: '-8px', mt: '10px' }}
              onClick={handleNotesLockClicked}
            >
              {notesLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            multiline
            margin="dense"
            id="input-text-notes"
            label="Notas"
            type="text"
            fullWidth
            variant="standard"
            disabled={notesLocked}
            defaultValue={notes}
            onChange={updateNotes}
            sx={{ ml: '8px' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeDialog}>Cancelar</Button>
        <Button color="success" variant="contained" onClick={handleSaveEditClicked}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}