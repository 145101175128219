import React from 'react';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const StyledBigIconChip = styled(Chip)(({ theme }) => ({
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '130px',
  height: '130px',
  '& .MuiChip-icon': {
    marginLeft: '-5px',
    fontSize: '90px'
  },
}));

export default function BigIconChip({ icon, tooltip, ...props }) {
  return (
    <Tooltip title={tooltip} arrow enterDelay={200}>
      <StyledBigIconChip {...props} icon={icon} />
    </Tooltip>
  );
}