import React from 'react';

import { Popup } from 'react-leaflet/Popup'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import EditLocationAltRoundedIcon from '@mui/icons-material/EditLocationAltRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

export default function ObjectMarkerPopup(props) {

  const { editModeOn, object, draggable, toggleDraggable, moved,
    cancelMovePosition, handleEditObjectClicked, handleDeleteObjectClicked } = props;

  var textReviewed = object.reviewed ? "Sí" : "No";

  return (
    <Popup closeButton={false}>
      {draggable ?
        moved ?
          <Box>
            <Button
              color="error"
              size="small"
              aria-label="Cancelar"
              onClick={(e) => {
                e.view.L.DomEvent.stopPropagation(e); // do this because of bug on leaflet clicks
                cancelMovePosition();
              }}
              sx={{ marginRight: '6px' }}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              size="small"
              variant="contained"
              aria-label="Fijar posición"
              onClick={(e) => {
                e.view.L.DomEvent.stopPropagation(e); // do this because of bug on leaflet clicks
                toggleDraggable();
              }}
            >
              Fijar posición
            </Button>
          </Box>
          :
          <Typography variant='string' sx={{ fontSize: '14px' }}>
            Arrastra el objeto a la posición deseada
          </Typography>
        :
        <Box sx={{ width: { xs: '200px', sm: '300px' } }}>
          <Box sx={{ ml: '2px', mb: '8px' }}>
            <Box sx={{ mb: '6px' }}>
              <Typography variant='string' gutterBottom fontWeight='medium'>Calle: </Typography>
              <Typography variant='string' gutterBottom >{object.road}</Typography>
            </Box>
            <Box sx={{ mb: '6px' }}>
              <Typography variant='string' gutterBottom fontWeight='medium'>Subcategoria: </Typography>
              <Typography variant='string' gutterBottom >{object.subtype}</Typography>
            </Box>
            <Box sx={{ mb: '6px' }}>
              <Typography variant='string' gutterBottom fontWeight='medium'>Elementos: </Typography>
              <Typography variant='string' gutterBottom >{object.items}</Typography>
            </Box>
            <Box sx={{ mb: '6px' }}>
              <Typography variant='string' gutterBottom fontWeight='medium'>Revisado: </Typography>
              <Typography variant='string' gutterBottom >{textReviewed}</Typography>
            </Box>
            <Box sx={{ mb: '6px' }}>
              <Typography variant='string' gutterBottom fontWeight='medium'>Fecha: </Typography>
              <Typography variant='string' gutterBottom >{new Date(object.date).toLocaleDateString('es-ES')}</Typography>
            </Box>
            {object.notes !== undefined && object.notes !== null && object.notes !== '' && (
              <Box sx={{ mb: '6px' }}>
                <Typography variant='string' gutterBottom fontWeight='medium'>Notas: </Typography>
                <Typography variant='string' gutterBottom >{object.notes}</Typography>
              </Box>
            )}
          </Box>
          {editModeOn && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                color="blue"
                size="small"
                aria-label="Mover objeto"
                sx={{ fontWeight: 'regular' }}
                onClick={(e) => {
                  e.view.L.DomEvent.stopPropagation(e); // do this because of bug on leaflet clicks
                  toggleDraggable();
                }}
                endIcon={<EditLocationAltRoundedIcon />}
              >
                Mover
              </Button>
              <Button
                color="success"
                size="small"
                aria-label="Editar objeto"
                sx={{ fontWeight: 'regular', mx: '10px' }}
                onClick={(e) => {
                  e.view.L.DomEvent.stopPropagation(e); // do this because of bug on leaflet clicks
                  handleEditObjectClicked(object);
                }}
                endIcon={<EditRoundedIcon />}
              >
                Editar
              </Button>
              <Button
                color="error"
                size="small"
                aria-label="Borrar objeto"
                sx={{ fontWeight: 'regular' }}
                onClick={(e) => {
                  e.view.L.DomEvent.stopPropagation(e); // do this because of bug on leaflet clicks
                  handleDeleteObjectClicked(object);
                }}
                endIcon={<DeleteRoundedIcon />}
              >
                Borrar
              </Button>
            </Box>
          )}
        </Box>
      }
    </Popup>
  )
}